(function () {
    'use strict';

    angular
        .module('e74intranet.reservation')
        .factory('ReservationService', ReservationService);

    ReservationService.$inject = ['$http'];

    function ReservationService($http) {
        var root = 'api/reservations';
        var reservationService = {
            getAll: getAll,
            getPagedReservations: getPagedReservations,
            getById: getById,
            create: create,
            update: update,
            remove: remove
        };

        function getAll() {
            return $http.get(root);
        }

        function getPagedReservations(page, pageSize, ascending, orderBy, searchText) {
            return $http.get(root + '/paging?page=' + (page || 1) +
                                    '&pageSize=' + (pageSize || 10) +
                                    '&ascending=' + (ascending == undefined ? true : ascending) +
                                    '&orderBy=' + (orderBy || '') +
                                    '&search=' + (searchText || ''));
        }

        function getById(id) {
            return $http.get(root + '/' + id);
        }

        function create(model) {
            return $http.post(root, model);
        }

        function update(model) {
            return $http.put(root, model);
        }

        function remove(id) {
            return $http.delete(root + '/' + id);
        }

        return reservationService;
    }
}());