(function () {
    'use strict';

    angular
        .module('e74intranet.cafeteria')
        .controller('MenuController', MenuController);

    MenuController.$inject = [
        '$filter',
        '$rootScope',
        '$scope',
        '$timeout',
        'MenuService',
        'NotificationService',
        'ModalService',
        'AccessService',
        'ImageBrowser',
        'moment'
    ];

    function MenuController(
        $filter,
        $rootScope,
        $scope,
        $timeout,
        MenuService,
        NotificationService,
        ModalService,
        AccessService,
        ImageBrowser,
        moment) {
        var vm = this;
        vm.selectedItems = [];
        vm.selectedIds = "";
        vm.create = false;
        vm.update = false;
        vm.destroy = false;
        vm.openDescriptionModal = openDescriptionModal;
        vm.descriptionLabel = "Add description";
        vm.isInvalidDescription = false;

        activate();

        vm.multiDropdownSettings = {
            idProp: 'mealCategoryId',
            externalIdProp: 'mealCategoryId',
            displayProp: 'name',
            buttonClasses: 'btn btn-default btn-multi-dropdown'
        };

        vm.translation = {
            buttonDefaultText: 'Filter by Category',
            dynamicButtonTextSuffix: 'selected'
        };

        vm.multiDropdownEvents = {
            onSelectedChanged: onSelectionChanged,
        };

        function onSelectionChanged() {
            vm.selectedIds = "";
            angular.forEach(vm.selectedIds, function (item) {
                vm.selectedIds += item.eventCategoryId + ',';
            });

            if (vm.selectedIds.length > 0) vm.selectedIds = vm.selectedIds.substring(0, vm.selectedIds.length - 1);

            vm.dataSource.read();
        }

        function activate() {
            if (AccessService.hasPermissions($rootScope.permission.CREATE)) vm.create = true;
            if (AccessService.hasPermissions($rootScope.permission.UPDATE)) vm.update = true;
            if (AccessService.hasPermissions($rootScope.permission.DELETE)) vm.destroy = true;
        }

        function configureScheduler() {
            vm.dataSource = new kendo.data.SchedulerDataSource({
                transport: {
                    create: create,
                    read: read,
                    update: update,
                    destroy: destroy
                },
                schema: {
                    timezone: "CST6CDT",
                    model: {
                        id: "menuId",
                        fields: {
                            menuId: { from: "menuId", type: "number" },
                            title: { from: "title", defaultValue: "No title", validation: { required: true } },
                            start: { type: "date", from: "startDate" },
                            end: { type: "date", from: "endDate" },
                            description: { from: "description", validation: { required: true } },
                            email: { from: "email", validation: { required: true } },
                            fullName: { from: "fullName", validaiton: { required: true } },
                            mealCategoryId: { from: "mealCategoryId", validaiton: { required: true } },
                            isAllDay: { type: "boolean", from: "isAllDay" },
                            recurrenceId: { from: "recurrenceId" },
                            recurrenceRule: { from: "recurrenceRule" },
                            recurrenceException: { from: "recurrenceException" }
                        }
                    }
                }
            });

            var startDate = moment().startOf('day').toDate();

            vm.schedulerOptions = {
                toolbar: ["pdf"],
                pdf: {
                    fileName: "scheduler.pdf",
                    proxyURL: "https://demos.telerik.com/kendo-ui/service/export"
                },
                date: startDate,
                startTime: startDate,
                views: [
                    "day",
                    "workWeek",
                    "week",
                    "month",
                    { type: "month", selected: true }
                ],
                editable: {
                    create: vm.create,
                    update: true,
                    destroy: vm.destroy,
                    move: vm.update,
                    resize: vm.update,
                    template: kendo.template($("#customEditorTemplate").html())
                },
                timezone: "CST6CDT",
                dataSource: vm.dataSource,
                resources: [
                    {
                        field: "menuCategoryId",
                        dataSource: vm.mealCategoryResources,
                        title: "menuCategoryId"
                    }
                ],
                edit: function (e) {
                    var container = e.container;
                    var saveButton = $('.k-scheduler-update');

                    $scope.$apply(function () {
                        vm.description = "";
                        vm.descriptionLabel = "Add description";
                        vm.menuId = 0;
                        vm.recurrenceId = 0;
                        if (saveButton[0] && !vm.update) {
                            saveButton[0].innerHTML = "Submit";
                            saveButton[0].innerText = "Submit";
                        }
                    });

                    if (e.menu.menuId > 0) {
                        vm.menuId = e.menu.menuId;

                        if (!vm.update) saveButton.remove();
                    }

                    if (e.menu.menuId > 0 || e.menu.recurrenceId > 0) {
                        $scope.$apply(function () {
                            vm.description = e.event.description;
                            vm.descriptionLabel = vm.description ? "Edit description" : vm.descriptionLabel;
                            vm.recurrenceId = e.menu.recurrenceId;
                        });

                        if (!vm.update) saveButton.remove();
                    }
                },
                "messages": {
                    "allDay": "All Day",
                    "recurrenceMessages": {
                        "deleteWindowOccurrence": "Delete occurrence"
                    }
                }
            };
        }

        function create(options) {
            if (vm.validator && !vm.validator.validate()) {
                options.error();
                return;
            }

            if (!vm.description) {
                NotificationService.warning('It is mandatory to add a description.');
                vm.isInvalidDescription = true;
                return;
            }

            options.data.description = vm.description;

            MenuService.create(options, data)
                .then(createSuccess, createFailure);

            function createSuccess(response) {
                vm.dataSource.read();
                NotificationService.success('save');
            }
            function createFailure(error) {
                options.error(error);
            }
        }

        function read(options) {
            
        }

        function update(options) {
            if (options.data.mealCategory && isNaN(parseInt(options.data.mealCategory.mealCategoryId))) {
                NotificationService.warning('The category field is required');
                options.error();
                return;
            }

            if (vm.validator && !vm.validator.validate()) {
                options.error();
                return;
            }

            if (!vm.description) {
                NotificationService.warning('It is mandatory to add a description.');
                vm.isInvalidDescription = true;
                return;
            }

            options.data.description = vm.description;

            MenuService.update(options.data)
				.then(updateSuccess, updateFailure);

            function updateSuccess(response) {
                NotificationService.success('update');
                vm.dataSource.read();
            }
            function updateFailure(error) {
                options.error(error);
            }
        }

        function destroy(options) {
            MenuService.remove(options.data.menuId)
                .then(destroySuccess, destroyFailure);

            function destroySuccess(response) {
                NotificationService.success('delete');
                vm.dataSource.read();
            }
            function destroyFailure(error) {
                options.error(error);
            }
        }

        vm.tooloptions = {
            tools: ImageBrowser.tools,
            ImageBrowser: {
                messages: {
                    dropFilesHere: "Drop files here"
                },
                transport: {
                    read: {
                        url: "api/imageBrowser/images",
                        type: "GET",
                        cache: false
                    },
                    destroy: {
                        url: function (params) {
                            return "api/imageBrowser/?fileName=" + params.name;
                        },
                        type: "DELETE",
                        complete: function () {
                            $timeout(function () {
                                $rootScope.$broadcast('hideLoadMask');
                            });
                            NotificationService.success('update');
                        }
                    },
                    thumbnailUrl: "api/imageBrowser/images/thumbnail",
                    uploadUrl: "api/imageBrowser/upload",
                    imageUrl: "api/imageBrowser/image?path={0}"
                }
            },
            resizable: {
                content: true,
                toolbar: false
            },
            execute: function () {
                $rootScope.showBodyScroll = true;
                $timeout(function () {
                    $rootScope.$broadcast('hideBreadcrumb');
                });
            },
            rules: {
                maxTextLength: function (textarea) {
                    if (textarea.is("[data-maxtextlength-msg]") && textarea.val() != "") {
                        var maxlength = textarea.attr("data-maxtextlength");
                        var value = textarea.data("kendoEditor").value();
                        return value.replace(/<[^>]+>/g, "").length <= maxlength;
                    }

                    return true;
                },
                required: function (textarea) {
                    if (textarea.is("[data-required]") && textarea.val() != "") {
                        var editor = textarea.data("kendoEditor");
                        if (!editor) return true;

                        var value = editor.value();

                        return value.replace(/<[^>]+>/g, "").length > 0;
                    }

                    return false;
                }
            }
        };

        function openDescriptionModal() {
            var modalInstance = ModalService.createModal({
                templateUrl: 'wwwroot/app/cafeteria/menu/menu-description.html',
                controller: 'MenuDescriptionController',
                windowClass: 'xl-modal menu-description-modal',
                resolve: vm.description
            });

            modalInstance.then(modalInstanceResponse, modalInstanceError);

            function modalInstanceResponse(response) {
                if (response !== "cancel") {
                    vm.description = response;
                    vm.descriptionLabel = vm.description ? "Edit description" : vm.descriptionLabel;
                    vm.isInvalidDescription = false;

                    var events = vm.dataSource.data();

                    var filteredEvents = $filter('filter')(vm.dataSource.data(), { eventId: vm.eventId }, true);
                    if (!filteredEvents) filteredEvents = $filter('filter')(vm.dataSource.data(), { recurrenceId: vm.recurrenceId }, true);

                    // First we set dirty false to all the events
                    angular.forEach(events, function (event) {
                        event.dirty = false;
                    });

                    // Then we set dirty true the current event
                    if (filteredEvents) filteredEvents[0].dirty = true;
                }
            }

            function modalInstanceError() { }
        }
    }
}());