(function () {
	'use strict';

	angular
        .module('e74intranet.login')
        .config(Config);

	Config.$inject = ['$stateProvider'];

	function Config($stateProvider) {		            
		var loginState = {
			name: 'login',
			url: '/login',
			controller: 'LoginController',
			controllerAs: 'vm',
			templateUrl: 'wwwroot/app/login/login.html'
		};

		$stateProvider.state(loginState);
	}
}());