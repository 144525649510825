(function () {
	'use strict';

	angular
        .module('e74intranet.employee')
        .filter('tel', function () {
            return function (tel) {
                if (!tel) { return ''; }

                var value = tel.toString().trim().replace(/^\+/, '');

                if (value.match(/[^0-9]/)) {
                    return tel;
                }

                var country, city, number;

                switch (value.length) {
                    case 10: // +1PPP####### -> C (PPP) ###-####
                        country = 1;
                        city = value.slice(0, 3);
                        number = value.slice(3);
                        break;

                    case 11: // +CPPP####### -> CCC (PP) ###-####
                        country = value[0];
                        city = value.slice(1, 4);
                        number = value.slice(4);
                        break;

                    case 12: // +CCCPP####### -> CCC (PP) ###-####
                        country = value.slice(0, 3);
                        city = value.slice(3, 5);
                        number = value.slice(5);
                        break;

                    default:
                        return tel;
                }

                if (country == 1) {
                    country = "";
                }

                number = number.slice(0, 3) + '-' + number.slice(3);

                return (country + " (" + city + ") " + number).trim();
            };
        })
        .controller('ContactDirectoryController', ContactDirectoryController);

	ContactDirectoryController.$inject = ['$rootScope', '$scope', 'ContactService', 'DepartmentService', 'AccessService', 'ModalService', 'NotificationService', 'localStorageService'];

	function ContactDirectoryController($rootScope, $scope, ContactService, DepartmentService, AccessService, ModalService, NotificationService, localStorageService) {
	    var vm = this;
	    vm.selectedItems = [];
	    vm.getDepartmentContactList = getDepartmentContactList;
	    vm.getJustContacts = getJustContacts;
	    vm.getFilteredContacts = getFilteredContacts;
	    vm.openTooltip = openTooltip;
	    vm.openModal = openModal;
	    vm.deleteRow = deleteRow;
	    vm.clearResults = clearResults;
	    vm.swapOrder = swapOrder;

	    activate();

	    $scope.getContacts = function () {
	        vm.selectedAccordionsIds = "";
	        angular.forEach(vm.departmentContacts, function (item) {
	            if (item.isOpen) {
	                vm.selectedAccordionsIds += item.departmentId + ',';
	            }
	        });

	        if (vm.selectedAccordionsIds.length > 0) {
	            vm.selectedAccordionsIds = vm.selectedAccordionsIds.substring(0, vm.selectedAccordionsIds.length - 1);
	            getJustContacts();
	        }
	    };

	    // Componentes setup
	    vm.multiDropdownSettings = {
	        idProp: 'departmentId',
	        externalIdProp: 'departmentId',
	        displayProp: 'name',
	        buttonClasses: 'btn btn-default btn-multi-dropdown'
	    };

	    vm.translation = {
	        buttonDefaultText: 'Filter by Department',
	        dynamicButtonTextSuffix: 'selected'
	    };

	    vm.multiDropdownEvents = {
	        onSelectionChanged: onSelectionChanged,
	    };

	    // Components methods
	    function activate() {
	        setPermissions();
	        getDepartments();
	        getDepartmentContactList();
	    }

	    function setPermissions() {
	        if (AccessService.hasPermissions($rootScope.permission.CREATE)) vm.create = true;
	        if (AccessService.hasPermissions($rootScope.permission.UPDATE)) vm.update = true;
	        if (AccessService.hasPermissions($rootScope.permission.DELETE)) vm.delete = true;
	    }

	    function getDepartments() {
	        DepartmentService.getAll()
				.then(getAllSuccess, getAllFailure);

	        function getAllSuccess(response) {
	            vm.departments = response.data;
	        }

	        function getAllFailure() { }
	    }

	    function getDepartmentContactList() {
	        ContactService.getDepartmentContactList(vm.selectedIds, vm.searchText)
                .then(getDepartmentContactListSuccess, getDepartmentContactListFailure);

	        function getDepartmentContactListSuccess(response) {
	            vm.departmentContacts = response.data;
	        }

	        function getDepartmentContactListFailure() { }
	    }

	    function getJustContacts() {
	        ContactService.getJustContacts(vm.selectedAccordionsIds, vm.searchText)
                .then(getJustContactsSuccess, getJustContactsFailure);

	        function getJustContactsSuccess(response) {
	            var accordionIds = vm.selectedAccordionsIds.split(',');

	            for (var a = 0; a < accordionIds.length; a++) {
	                var index = findWithAttr(vm.departmentContacts, 'departmentId', accordionIds[a]);
	                vm.departmentContacts[index].contacts = response.data;
	            }
	        }

	        function getJustContactsFailure() { }
	    }

	    function findWithAttr(array, attr, value) {
	        for (var i = 0; i < array.length; i += 1) {
	            if (array[i][attr] == value) {
	                return i;
	            }
	        }
	        return -1;
	    }

	    function getFilteredContacts() {
	        ContactService.getFilteredContacts(vm.selectedIds, vm.searchText)
                .then(getFilteredContactsSuccess, getFilteredContactsFailure);

	        function getFilteredContactsSuccess(response) {
	            vm.departmentContacts = response.data;
	        }

	        function getFilteredContactsFailure(error) { }
	    }

	    var authData = localStorageService.get('authorizationData');
	    var token = "";

	    if (authData) {
	        token = 'Bearer ' + authData.token;
	    }

	    vm.autoCompleteOptions = {
	        placeholder: "Search by Name",
	        dataTextField: "fullName",
	        filter: "contains",
	        clearButton: false,
	        dataSource: new kendo.data.DataSource({
	            serverFiltering: true,
	            transport: {
	                read: {
	                    url: "api/contacts",
	                    dataType: "json",
	                    beforeSend: function (req) {
	                        req.setRequestHeader('Authorization', token);
	                    }
	                },
	                parameterMap: function (data, type) {
	                    var queryString = {};

	                    if (vm.searchText) queryString.searchText = vm.searchText;
	                    if (vm.selectedIds) queryString.departmentIds = vm.selectedIds;

	                    return queryString;
	                }
	            }
	        }),
	        select: onSelect
	    };

	    function onSelect(e) {
	        vm.searchText = e.item.text();
	        getDepartmentContactList();
	    }

	    function clearResults() {
	        vm.searchText = '';
	        getDepartmentContactList();
	    }

	    // Components events
	    function onSelectionChanged() {
	        vm.selectedIds = "";
	        angular.forEach(vm.selectedItems, function (item) {
	            vm.selectedIds += item.departmentId + ',';
	        });

	        if (vm.selectedIds.length > 0) vm.selectedIds = vm.selectedIds.substring(0, vm.selectedIds.length - 1);

	        getDepartmentContactList();
	    }

	    function openTooltip(item) {
	        return {
	            cell: item.cellNumber,
	            beeper: item.beeper,
	            email: item.email
	        };
	    }

	    function openModal(action, contact, department) {
	        if (action == 'edit') {
	            contact.department = {
	                departmentId: department.departmentId,
	                name: department.departmentName
	            };
	        }

	        var modalInstance = ModalService.createModal({
	            templateUrl: 'wwwroot/app/employee/contacts/contact-directory/contact-directory-add-edit.html',
	            controller: 'ContactDirectoryAddEditController',
	            resolve: action === 'edit' ? contact : null
	        });

	        modalInstance.then(modalInstanceResponse, modalInstanceError);

	        function modalInstanceResponse(response) {
	            if (response !== "cancel") {
	                activate();
	            }
	        }

	        function modalInstanceError() { }
	    }

	    function deleteRow(contact) {
	        var modalPromptInstance = ModalService.createModalPrompt({
	            title: 'Delete Contact',
	            content: 'The selected contact will be deleted. Do you want to continue?'
	        });

	        modalPromptInstance.then(modalPromptResponse, modalPromptInstanceError);

	        function modalPromptResponse(response) {
	            if (response === 'cancel') return;

	            ContactService.remove(contact.contactId)
					.then(removeSuccess, removeFailure);

	            function removeSuccess() {
	                NotificationService.success('delete');
	                getFilteredContacts();
	            }

	            function removeFailure() { }
	        }

	        function modalPromptInstanceError() { }
	    }

	    function swapOrder(index, contacts, orderPosition) {
	        var oldIndex = orderPosition == 1 ? index - 1 : index + 1;
	        var newContact = contacts[index];
	        var oldContact = contacts[oldIndex];
	        var contactRequest = {};

	        if (oldContact) {
	            var currentSortOrder = newContact.sortOrder;
	            newContact.sortOrder = oldContact.sortOrder;
	            oldContact.sortOrder = currentSortOrder;
	            contacts[index] = oldContact;
	            contacts[oldIndex] = newContact;

	            contactRequest = {
	                newContactId: newContact.contactId,
	                newContactSortOrder: newContact.sortOrder,
	                oldContactId: oldContact.contactId,
	                oldContactSortOrder: oldContact.sortOrder
	            };

	            ContactService
					.swapOrder(contactRequest)
					.then(swapOrderSuccess, swapOrderFailure);
	        }

	        function swapOrderSuccess(response) {
	            NotificationService.success('update');
	        }

	        function swapOrderFailure() { }
	    }
	}
}());