(function () {
	'use strict';

	angular
        .module('e74intranet.catalogs')
        .controller('UserController', UserController);

	UserController.$inject = ['UserService', 'NotificationService', 'ModalService', 'CONSTANTS'];

	function UserController(UserService, NotificationService, ModalService, CONSTANTS) {
		var vm = this;	
		vm.user = {};
		vm.pageSize = CONSTANTS.PAGINATION.PAGE_SIZE;
		vm.loadGrid = loadGrid;
		vm.openModal = openModal;
		vm.deleteRow = deleteRow;
		vm.gridOptions = {};

		activate();
		
		function activate() {			
			vm.disabled = true;
			loadGrid();
		}

		vm.gridOptions = {
			columnDefs: [
                { field: 'email', name: 'Email', displayName: 'Email' },
				{ field: 'profile.name', name: 'ProfileId', displayName: 'Profile' },
                { field: 'isActive', name: 'IsActive', displayName: 'Status', cellTemplate: '<div class="ui-grid-cell-contents" ng-bind-html="grid.getCellValue(row, col) | status"></div>' }
			],
			onRegisterApi: function (gridApi) {
				gridApi.pagination.on.paginationChanged(null, function (newPage, pageSize) {
					vm.pageNumber = newPage;
					vm.pageSize = pageSize;
					loadGrid();
				});
				gridApi.core.on.sortChanged(null, function (grid, sortColumns) {
					if (sortColumns.length > 0) {
						vm.orderBy = sortColumns[0].name;
						vm.ascending = sortColumns[0].sort.direction === 'asc' ? true : false;
					}
					loadGrid();
				});
				gridApi.selection.on.rowSelectionChanged(null, function (row) {
					vm.user = [];
					vm.disabled = true;
					if (row.isSelected) {
						vm.user = row.entity;
						vm.disabled = false;
					}
				});
			}
		};		

		function loadGrid() {
			UserService.getPagedUsers(vm.pageNumber, vm.pageSize, vm.ascending, vm.orderBy, vm.searchText)
                .then(getPagedUsersSuccess, getPagedUsersFailure);

			function getPagedUsersSuccess(response) {
				vm.gridOptions.data = response.data.results;
				vm.gridOptions.totalItems = response.data.totalItems;
			}
			function getPagedUsersFailure() {}
		}

		function openModal(action) {
			var modalInstance = ModalService.createModal({
				templateUrl: 'wwwroot/app/catalogs/users/user-add-edit.html',
				controller: 'UserAddEditController',
				size: 'md',
				resolve: action === 'edit' ? vm.user : null
			});

			modalInstance.then(modalInstanceResponse, modalInstanceError);

			function modalInstanceResponse(response) {
				if (response !== "cancel") {
					activate();
				}
			}

			function modalInstanceError() { }
		}

		function deleteRow() {
			var modalPromptInstance = ModalService.createModalPrompt({
				title: 'Delete user',
				content: 'The selected user will be deleted. Do you want to continue?'
			});

			modalPromptInstance.then(modalPromptResponse, modalPromptInstanceError);

			function modalPromptResponse(response) {
				if (response === 'cancel') return;

				UserService.remove(vm.user.id)
					.then(removeSuccess, removeFailure);

				function removeSuccess() {
					NotificationService.success('delete');
					activate();
				}

				function removeFailure(){}
			}

			function modalPromptInstanceError() { }
		}
	}
}());