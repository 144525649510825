(function () {
    'use strict';

    angular
        .module('e74intranet.reservation')
        .config(Config);

    Config.$inject = ['$stateProvider'];

    function Config($stateProvider) {
        var reservationResidentState = {
            name: 'main.reservationResident',
            url: '/reservation-resident',
            controller: 'ReservationResidentController',
            controllerAs: 'vm',
            templateUrl: 'wwwroot/app/reservations/reservation/reservation-resident.html',
            params: { mid: null },
            ncyBreadcrumb: {
                label: '{{ getMenuItemLabel("main.reservationResident") }}',
                parent: 'main.dashboard'
            }
        };

        var reservationState = {
            name: 'main.reservation',
            url: '/reservation',
            controller: 'ReservationController',
            controllerAs: 'vm',
            templateUrl: 'wwwroot/app/reservations/reservation/reservation.html',
            params: { mid: null },
            ncyBreadcrumb: {
                label: '{{ getMenuItemLabel("main.reservation") }}',
                parent: 'main.dashboard'
            }
        };

        var reservationLocationState = {
            name: 'main.reservationLocation',
            url: '/reservationLocation',
            controller: 'ReservationLocationController',
            controllerAs: 'vm',
            templateUrl: 'wwwroot/app/reservations/reservation-location/reservation-location.html',
            params: { mid: null },
            ncyBreadcrumb: {
                label: '{{ getMenuItemLabel("main.reservationLocation") }}',
                parent: 'main.dashboard'
            }
        };

        var reservationTimeframeState = {
            name: 'main.reservationTimeframe',
            url: '/reservationTimeframe',
            controller: 'ReservationTimeframeController',
            controllerAs: 'vm',
            templateUrl: 'wwwroot/app/reservations/reservation-timeframe/reservation-timeframe.html',
            params: { mid: null },
            ncyBreadcrumb: {
                label: '{{ getMenuItemLabel("main.reservationTimeframe") }}',
                parent: 'main.dashboard'
            }
        };

        var reservationFormState = {
            name: 'webapp.reservationForm',
            url: '/reservationForm',
            controller: 'ReservationFormController',
            controllerAs: 'vm',
            templateUrl: 'wwwroot/app/reservations/reservation/reservation-form.html',
            params: { mid: null },
            ncyBreadcrumb: {
                label: '{{ getMenuItemLabel("webapp.reservationForm") }}',
                parent: 'main.dashboard'
            }
        };

        $stateProvider.state(reservationState);
        $stateProvider.state(reservationResidentState);
        $stateProvider.state(reservationLocationState);
        $stateProvider.state(reservationTimeframeState);
        $stateProvider.state(reservationFormState);
    }
}());