(function () {
	'use strict';

	angular
		.module('e74intranet.catalogs')
		.factory('UserService', UserService);

	UserService.$inject = ['$http'];

	function UserService($http) {	
		var root = 'api/users';
		var userService = {			
			getAll: getAll,
			getPagedUsers: getPagedUsers,			
			getById: getById,
			getCurrentUser: getCurrentUser,
			create: create,
			register: register,
			update: update,
            updateUserProfile: updateUserProfile,
			remove: remove,
			sendPassword: sendPassword,
			resetPassword: resetPassword,
			confirmEmail: confirmEmail
		};		

		function getAll() {
			return $http.get(root);
		}

		function getPagedUsers(page, pageSize, ascending, orderBy, searchText) {
			return $http.get(root + '/paging?page=' + (page || 1) +
									'&pageSize=' + (pageSize || 10) +
									'&ascending=' + (ascending == undefined ? true : ascending) +
									'&orderBy=' + (orderBy || '') +									
									'&search=' + (searchText || ''));
		}

		function getById(id) {
			return $http.get(root + '/' + id);
		}

		function getCurrentUser() {
			return $http.get(root + '/current');
		}

		function create(model) {
			return $http.post(root, model);
		}

		function register(model) {
			return $http.post(root + '/register', model);
		}

		function update(model) {
			return $http.put(root, model);
		}

		function updateUserProfile(model) {
		    return $http.put(root + '/updateUserProfile', model);
		}

		function remove(id) {
			return $http.delete(root + '/' + id);
		}

		function sendPassword(email) {
			return $http.get(root + '/forgotPassword?email=' + email);
		}

		function resetPassword(model) {
			return $http.post(root + '/resetPassword', model);
		}

		function confirmEmail(model) {
			return $http.post(root + '/confirmEmail', model);
		}

		return userService;
	}
}());


