(function () {
	'use strict';

	angular
       .module('e74intranet.employee')
       .controller('AnnouncementAddEditController', AnnouncementAddEditController);

    AnnouncementAddEditController.$inject = ['$uibModalInstance', 'modelResolved', 'AnnouncementService', 'AnnouncementCategoryService', 'NotificationService'];

    function AnnouncementAddEditController($uibModalInstance, modelResolved, AnnouncementService, AnnouncementCategoryService, NotificationService) {
        var vm = this;
        vm.selectedItems = [];
        vm.announcement = angular.copy(modelResolved);
		vm.isNew = !vm.announcement;
		vm.title = (!vm.isNew ? 'Edit' : 'New') + ' Announcement';
		vm.clearDates = clearDates;
		vm.fromDateChanged = fromDateChanged;
		vm.toDateChanged = toDateChanged;
		vm.save = save;
		vm.cancel = cancel;						
		vm.maxDate = new Date(8640000000000000);
		vm.minDate = new Date();

        activate();

        // Componentes setup
        vm.multiDropdownSettings = {
            idProp: 'announcementCategoryId',
            externalIdProp: 'announcementCategoryId',
            displayProp: 'name',
            buttonClasses: 'btn btn-default btn-multi-dropdown'
        };

        vm.translation = {
            buttonDefaultText: 'Select Category(ies)',
            dynamicButtonTextSuffix: 'selected'
        };

        vm.multiDropdownEvents = {
            onSelectionChanged: onSelectionChanged,
        };

        // Components events
        function onSelectionChanged() {
            vm.selectedIds = "";
            angular.forEach(vm.selectedItems, function (item) {
                vm.selectedIds += item.announcementCategoryId + ',';
            });

            if (vm.selectedIds.length > 0) vm.selectedIds = vm.selectedIds.substring(0, vm.selectedIds.length - 1);

            AnnouncementCategoryService.getSelectedAnnouncementCategories(vm.selectedIds)
                .then(getAllSelectedCategoriesSuccess, getAllSelectedCategoriesFailure);

            function getAllSelectedCategoriesSuccess(response) {
                vm.announcement.announcementCategories = response.data;
            }

            function getAllSelectedCategoriesFailure() {}
        }

        function activate() {
            AnnouncementCategoryService.getAll()
                .then(getAllSuccess, getAllFailure);

            function getAllSuccess(response) {
                vm.announcementCategories = response.data;
                angular.forEach(vm.announcement.announcementCategories, function (item) {
                    vm.selectedItems.push(item);
                });
                onSelectionChanged();
            }

            function getAllFailure(){}

			if (vm.announcement && vm.announcement.buttonText && vm.announcement.url) {			
				vm.announcement.isCustomButton = true;
			}
		}		

		function fromDateChanged() {			
			vm.minDate = new Date(vm.announcement.startDate);			
		}
		function toDateChanged() {			
			vm.maxDate = new Date(vm.announcement.endDate);
		}

		function clearDates() {
			if (vm.announcement) {				
				vm.announcement.startDate = '';
				vm.announcement.endDate = '';
            }
			
			vm.maxDate = new Date(8640000000000000);
			vm.minDate = new Date();
		}

		function save() {
			if (vm.announcementForm.$valid) {								
				if (!vm.announcement.isCustomButton) {
					vm.announcement.buttonText = "";
					vm.announcement.url = "";
                }

				if (vm.announcement.announcementId) {
					AnnouncementService.update(vm.announcement)
                        .then(updateSuccess)
						.catch(failure);
				} else {				
					AnnouncementService.create(vm.announcement)
                        .then(saveSuccess)
						.catch(failure);
				}
			}

			function saveSuccess() {
				NotificationService.success('save');
				$uibModalInstance.close();
			}
			function updateSuccess() {
				NotificationService.success('update');
				$uibModalInstance.close();
			}
			function failure() { }
		}

		function cancel() {
			$uibModalInstance.close('cancel');
		}
	}
}());