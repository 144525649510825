(function () {
	'use strict';

	angular
        .module('e74intranet.employee')
        .controller('ArticleController', ArticleController);

	ArticleController.$inject = ['$rootScope', 'ArticleService', 'ArticleCategoryService', 'NotificationService', 'ModalService', 'AccessService', 'CONSTANTS'];

	function ArticleController($rootScope, ArticleService, ArticleCategoryService, NotificationService, ModalService, AccessService, CONSTANTS) {
		var vm = this;
		vm.pageNumber = CONSTANTS.PAGINATION.DEFAULT_PAGE_NUMBER;
		vm.pageSize = 15;
		vm.maxSize = 5;
		vm.openModal = openModal;
		vm.deleteRow = deleteRow;
		vm.onPaginationChange = getPagedArticles;				
		vm.selectedItems = [];
		
		activate();

		vm.multiDropdownSettings = {
			idProp: 'articleCategoryId',
			externalIdProp: 'articleCategoryId',
			displayProp: 'name',
			buttonClasses: 'btn btn-default btn-multi-dropdown'			
		};

		vm.translation = {
			buttonDefaultText: 'Filter by Category',
			dynamicButtonTextSuffix: 'selected'
		};

		vm.multiDropdownEvents = {
			onSelectionChanged: onSelectionChanged,			
		};		
		
		function onSelectionChanged() {
			vm.selectedIds = "";
			angular.forEach(vm.selectedItems, function (item) {
				vm.selectedIds += item.articleCategoryId + ',';
			});

			if (vm.selectedIds.length > 0) vm.selectedIds = vm.selectedIds.substring(0, vm.selectedIds.length - 1);

			getPagedArticles();
		}

		function activate() {
			setPermissions();
			getArticleCategories();
			getPagedArticles();
		}

		function setPermissions() {			
			if (AccessService.hasPermissions($rootScope.permission.CREATE)) vm.create = true;
			if (AccessService.hasPermissions($rootScope.permission.UPDATE)) vm.update = true;
			if (AccessService.hasPermissions($rootScope.permission.DELETE)) vm.delete = true;			
		}

		function getArticleCategories() {
			ArticleCategoryService.getAll()
				.then(getAllArticleCategoriesSuccess, getAllArticleCategoriesFailure);

			function getAllArticleCategoriesSuccess(response) {				
				vm.articleCategories = response.data;
			}

			function getAllArticleCategoriesFailure() { }
		}

		function getPagedArticles() {			
			ArticleService.getPagedArticles(vm.pageNumber, vm.pageSize, false, "", vm.selectedIds)
                .then(getPagedArticlesSuccess, getPagedArticlesFailure);
			
			function getPagedArticlesSuccess(response) {				
				vm.articles = response.data.results;
				vm.totalItems = response.data.totalItems;
			}

			function getPagedArticlesFailure(error) { }
		}

		function openModal(action, article) {
			var modalInstance = ModalService.createModal({
				templateUrl: 'wwwroot/app/employee/article/article/article-add-edit.html',
				controller: 'ArticleAddEditController',
				windowClass: 'xl-modal',
				resolve: action === 'edit' ? article : null
			});

			modalInstance.then(modalInstanceResponse, modalInstanceError);

			function modalInstanceResponse(response) {
				if (response !== "cancel") {
					getPagedArticles();
				}
			}

			function modalInstanceError() { }
		}

		function deleteRow(article) {
			var modalPromptInstance = ModalService.createModalPrompt({
				title: 'Delete Article',
				content: 'The selected article will be deleted. Do you want to continue?'
			});

			modalPromptInstance.then(modalPromptResponse, modalPromptInstanceError);

			function modalPromptResponse(response) {
				if (response === 'cancel') return;

				ArticleService.remove(article.articleId)
                    .then(removeSuccess);

				function removeSuccess() {
					NotificationService.success('delete');
					getPagedArticles();
				}
			}

			function modalPromptInstanceError() { }
		}
	}
}());