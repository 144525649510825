(function () {
	'use strict';

	angular
       .module('e74intranet.employee')
       .controller('TestimonyAddEditController', TestimonyAddEditController);

	TestimonyAddEditController.$inject = ['$uibModalInstance', 'modelResolved', 'TestimonyService', 'NotificationService', 'ModalService'];

	function TestimonyAddEditController($uibModalInstance, modelResolved, TestimonyService, NotificationService, ModalService) {
		var vm = this;
		vm.testimony = angular.copy(modelResolved);
		vm.isNew = !vm.testimony;
		vm.title = (!vm.isNew ? 'Edit' : 'New') + ' Testimony';
		vm.save = save;
		vm.cancel = cancel;						

		function save() {
			if (vm.testimonyForm.$valid) {								
				if (vm.testimony.testimonyId) {
					TestimonyService.update(vm.testimony)
						.then(updateSuccess)
						.catch(failure);
				} else {
					TestimonyService.create(vm.testimony)
						.then(saveSuccess)
						.catch(failure);
				}				
			}

			function saveSuccess() {
				NotificationService.success('save');
				$uibModalInstance.close();
			}
			function updateSuccess() {
				NotificationService.success('update');
				$uibModalInstance.close();
			}
			function failure() {				
			}
		}
	
		function cancel() {
			$uibModalInstance.close('cancel');
		}
	}
}());