(function () {
	'use strict';

	angular
		.module('e74intranet.dashboard')
		.component('backTalk', {
			templateUrl: 'wwwroot/app/dashboard/back-talk/back-talk.html',
			controller: BackTalkController,
			controllerAs: 'vm'
		});

	BackTalkController.$inject = ['TestimonyService'];

	function BackTalkController(TestimonyService) {
		var vm = this;		
		
		activate();

		function activate() {
			vm.slickConfig = {
				autoplay: true,
				autoplaySpeed: 10000,
				arrows: false,
				dots: true
			};
			
			TestimonyService.getLandingPageTestimonies()
                .then(getLandingPageTestimoniesSuccess, getLandingPageTestimoniesError);

			function getLandingPageTestimoniesSuccess(response) {
				vm.testimonies = response.data;

				if(vm.testimonies && vm.testimonies.length > 0) vm.isVisible = true;
			}

			function getLandingPageTestimoniesError(){}
		}		
	}
}());