(function () {
	'use strict';

	angular
		.module('e74intranet.dashboard')
		.component('article', {
			templateUrl: 'wwwroot/app/dashboard/articles/article.html',
			controller: ArticleController,
			controllerAs: 'vm'
		});

	ArticleController.$inject = ['ArticleService'];

	function ArticleController(ArticleService) {
		var vm = this;

		activate();	
		
		function activate() {						
			ArticleService.getLandingPageArticles()
                .then(getAllSuccess, getAllError);

			function getAllSuccess(response) {
				vm.articles = response.data;
			}

			function getAllError(){}
		}
	}
}());