(function () {
	'use strict';

	angular
       .module('e74intranet.employee')
       .controller('CatalogResourceAddEditController', CatalogResourceAddEditController);

	CatalogResourceAddEditController.$inject = [
        '$scope',
        '$uibModalInstance',
        'modelResolved',
        'CatalogResourceService',
        'CONSTANTS',
        'DepartmentService',
        'NotificationService',
        'ResourceService'
	];

	function CatalogResourceAddEditController(
        $scope,
        $uibModalInstance,
        modelResolved,
        CatalogResourceService,
        CONSTANTS,
        DepartmentService,
        NotificationService,
        ResourceService)
    {
	    var vm = this;	    
		vm.catalogResource = angular.copy(modelResolved);
		vm.isNew = !vm.catalogResource;
		vm.title = (!vm.isNew ? 'Edit' : 'New') + ' Resource';
		vm.save = save;
		vm.cancel = cancel;				
		var saveUrl = "api/resources/uploadCatalogResource";
		activate();

		function activate() {
		    var resourceId;

		    if (vm.catalogResource && vm.catalogResource.resource) {
		        vm.selectedFile = vm.catalogResource.resource.fileName;
		        resourceId = vm.catalogResource.resource.resourceId;
		        vm.resourceOption = 1;
		    } else if (vm.catalogResource && vm.catalogResource.url) {
		        vm.resourceOption = 2;
		    }

		    configureKendoUploader(resourceId);
			getDepartments();
		}
					
		function getDepartments() {
			DepartmentService.getAll()
				.then(getAllSuccess, getAllFailure);

			function getAllSuccess(response) {					
				vm.departments = response.data;					
			}

			function getAllFailure() { }
		}

		function configureKendoUploader(resourceId) {
		    if (resourceId) saveUrl = saveUrl + "?resourceId=" + resourceId;

		    vm.uploadOptions = {
		        multiple: false,
		        showFileList: false,
		        async: {
		            autoUpload: true,
		            saveUrl: saveUrl,
		            removeUrl: ""
		        },
		        localization: {
		            headerStatusUploaded: "The file was saved successfully",
		            select: "Select File"
		        },		        
		        upload: onUpload,
		        error: onError,
		        success: onSuccess,
		        select: onSelect,
		        clear: onClear
		    };
		}

		function onUpload() {
		    vm.isUploading = true;
		    $scope.$apply();
		}

		function onError() {
		    vm.isUploading = false;
		    NotificationService.error('An error has occurred while uploading the file. Please contact your system administrator.');
		    $scope.$apply();
		}

		function onSuccess(e) {
		    if (!vm.catalogResource) {
		        vm.catalogResource = { resource: { resourceId: e.response, fileName: vm.selectedFile } };
		    } else {
		        vm.catalogResource.resource = { resourceId: e.response, fileName: vm.selectedFile };
		    }
		    vm.isNewFile = true;
		    vm.isUploading = false;
		    vm.uploader.options.async.saveUrl = "api/resources/uploadCatalogResource?resourceId=" + e.response;
		    $scope.$apply();
		}

		function onSelect(e) {
		    var file = e.files[0];
		    var extensions = ['.pdf', '.avi', '.mp4', '.mov', '.wmv'];		    

		    if (!ResourceService.isExtensionValid(file.name, extensions)) {
		        NotificationService.warning('The selected file is not valid. Supported types are .pdf, .avi, .mp4, .mov, .wmv');
		        e.preventDefault();
		        return;
		    }

		    if (!ResourceService.isSizeValid(file.size)) {
		        NotificationService.warning('The selected file exceeds the maximum size (' + ((CONSTANTS.RESOURCE.MAX_FILE_SIZE) / 1024) / 1024 + 'MB).');
		        e.preventDefault();
		        return;
		    }		    

		    $scope.$apply(function () {
		        vm.resource = file;
		        vm.selectedFile = vm.resource.name;
		    });
		}

		function onClear() {
		    vm.isNewFile = false;

		    $scope.$apply(function () {
		        vm.menuFile = vm.catalogResource ? vm.catalogResource.resource : null;
		        vm.selectedFile = vm.resource ? vm.resource.fileName : "";
		    });
		}

		function save() {
		    if (vm.catalogResourceForm.$valid) {		        
		        if (vm.resourceOption === 1) {
		            if (!vm.catalogResource.resource) {
		                NotificationService.warning('It is mandatory to select a file.');
		                return;
		            }

		            vm.catalogResource.url = null;
		        }		        
		        else if (vm.resourceOption === 2) { 
		            if (!vm.catalogResource.url) {
		                NotificationService.warning('It is mandatory to specify a URL.');
		                return;
		            }

		            vm.catalogResource.resource = null;
		        } else {
		            NotificationService.warning('You must select an option to proceed.');
		            return;
		        }

		        saveCatalogResource();
		    }
		}

		function saveCatalogResource() {
		    if (vm.catalogResource.catalogResourceId) {
		        CatalogResourceService.update(vm.catalogResource)
					.then(updateSuccess)
					.catch(failure);
		    } else {
		        CatalogResourceService.create(vm.catalogResource)
					.then(saveSuccess)
					.catch(failure);					
		    }

		    function saveSuccess() {
		        NotificationService.success('save');
		        $uibModalInstance.close();
		    }

		    function updateSuccess() {
		        NotificationService.success('update');
		        $uibModalInstance.close();
		    }

		    function failure() { }		   
		}

		function cancel() {
		    $uibModalInstance.close('cancel');
		}
	}
}());