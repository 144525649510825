(function () {
	'use strict';

	angular
		.module('e74intranet.common')
		.factory('ErrorService', ErrorService);

	function ErrorService() {
		var errorService = {
			generateErrorMessageFromArray: generateErrorMessageFromArray
		};

		function generateErrorMessageFromArray(errors) {
			var message = '';
			angular.forEach(errors, function (err) { message += err + ' '; });
			return message;
		}

		return errorService;
	}
}());