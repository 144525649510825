(function () {
	'use strict';

	angular
        .module('e74intranet.event')
        .controller('EventController', EventController);

	EventController.$inject = [
		'$filter',
		'$rootScope',
		'$scope',
		'$timeout',
        '$stateParams',
		'EventService',
		'EventCategoryService',
		'NotificationService',
		'ModalService',
		'AccessService',
		'ImageBrowser',
		'moment'
	];

	function EventController(
		$filter,
		$rootScope,
		$scope,
		$timeout,
        $stateParams,
		EventService,
		EventCategoryService,
		NotificationService,
		ModalService,
		AccessService,
		ImageBrowser,
		moment) {
		var vm = this;
		vm.selectedItems = [];
		vm.selectedIds = "";
		vm.create = false;
		vm.update = false;
		vm.destroy = false;		
		vm.openDescriptionModal = openDescriptionModal;
		vm.descriptionLabel = "Add description";
		vm.isInvalidDescription = false;

		activate();

		vm.multiDropdownSettings = {
			idProp: 'eventCategoryId',
			externalIdProp: 'eventCategoryId',
			displayProp: 'name',
			buttonClasses: 'btn btn-default btn-multi-dropdown'
		};

		vm.translation = {
			buttonDefaultText: 'Filter by Category',
			dynamicButtonTextSuffix: 'selected'
		};

		vm.multiDropdownEvents = {
			onSelectionChanged: onSelectionChanged,
		};

		function onSelectionChanged() {
			vm.selectedIds = "";
			angular.forEach(vm.selectedItems, function (item) {
				vm.selectedIds += item.eventCategoryId + ',';
			});

			if (vm.selectedIds.length > 0) vm.selectedIds = vm.selectedIds.substring(0, vm.selectedIds.length - 1);

			vm.dataSource.read();
		}

		function activate() {
			if (AccessService.hasPermissions($rootScope.permission.CREATE)) vm.create = true;
			if (AccessService.hasPermissions($rootScope.permission.UPDATE)) vm.update = true;
			if (AccessService.hasPermissions($rootScope.permission.DELETE)) vm.destroy = true;

			EventCategoryService.getAll()
				.then(getAllEventCategoriesSuccess, getAllEventCategoriesFailure);

			function getAllEventCategoriesSuccess(response) {
				vm.eventCategories = response.data;
				vm.eventCategoryResources = [];
				mapToEventCategoryResource();
				configureScheduler();

				for (var i = 0; i < vm.eventCategories.length; i++) {
				    if (vm.eventCategories[i].eventCategoryId == $stateParams.eventCategoryId) {
				        vm.selectedItems.push(vm.eventCategories[i]);
				    }
				}
				onSelectionChanged();
			}

			function getAllEventCategoriesFailure(){}

			function mapToEventCategoryResource() {
				angular.forEach(vm.eventCategories, function (value) {
					var resource = {
						text: value.name,
						value: value.eventCategoryId,
						name: value.name,
						eventCategoryId: value.eventCategoryId,
						color: value.color
					};

					vm.eventCategoryResources.push(resource);
				});
			}
		}

		function checkEventCategoryIds(eventCategories) {
		    var matchingEventCategoryId = "0";
		    var firstEventCategoryId = "";
		    var eventsCategories = eventCategories;

		    for (var j = 0; j < eventsCategories.length; j++) {
		        for (var k = 0; k < vm.selectedItems.length; k++) {
		            if (matchingEventCategoryId == "0" && (eventsCategories[j].eventCategoryId == vm.selectedItems[k].eventCategoryId || eventsCategories[j].parentId == vm.selectedItems[k].eventCategoryId)) {
		                if (eventsCategories[j].eventCategoryId == vm.selectedItems[k].eventCategoryId)
		                    matchingEventCategoryId = vm.selectedItems[k].eventCategoryId;
		                else if (eventsCategories[j].parentId == vm.selectedItems[k].eventCategoryId)
		                    matchingEventCategoryId = eventsCategories[j].eventCategoryId;
		            }
		        }

		        if (firstEventCategoryId == "") {
		            firstEventCategoryId = eventsCategories[j].eventCategoryId;
		        }
		    }

            if (vm.selectedItems.length > 0)
                return matchingEventCategoryId;
            else {
                return firstEventCategoryId;
            }
		}

		function configureScheduler() {
			vm.dataSource = new kendo.data.SchedulerDataSource({
				transport: {
					create: create,
					read: read,
					update: update,
					destroy: destroy
				},
				schema: {
					timezone: "CST6CDT",
					model: {
						id: "eventId",
						fields: {
							eventId: { from: "eventId", type: "number" },
							title: { from: "title", defaultValue: "No title", validation: { required: true } },
							start: { type: "date", from: "startDate" },
							end: { type: "date", from: "endDate" },
							description: { from: "description", validation: { required: true } },
							email: { from: "email", validation: { required: true } },
							fullName: { from: "fullName", validation: { required: true } },
							eventCategoryId: { type: "number" },
							isAllDay: { type: "boolean", from: "isAllDay" },
							recurrenceId: { from: "recurrenceId" },
							recurrenceRule: { from: "recurrenceRule" },
							recurrenceException: { from: "recurrenceException" }
						}
					},
					parse: function (data) {
					    $.each(data, function (i, val) {
					        val.eventCategoryId = checkEventCategoryIds(val.eventCategories);
					    });
					    return data;
					}
				}
			});

            var startDate = moment().startOf('day').toDate();
            var sTime = moment().startOf('day').set('hour', 6).toDate();
            var eTime = moment().startOf('day').set('hour', 22).toDate();

            vm.schedulerOptions = {
                toolbar: ["pdf"],
                pdf: {
                    fileName: "scheduler.pdf",
                    proxyURL: "https://demos.telerik.com/kendo-ui/service/export"
                },
				date: startDate,
                startTime: sTime,
                endTime: eTime,
                navigate: scheduler_navigate,
				views: [
					"day",
					"workWeek",
					"week",
					"month",
					{ type: "week", selected: true },
				],
				editable: {
					create: vm.create,
					update: true,
					destroy: vm.destroy,
					move: vm.update,
					resize: vm.update,
					template: kendo.template($("#customEditorTemplate").html()),
				},
				timezone: "CST6CDT",
				dataSource: vm.dataSource,
				resources: [
					{
						field: "eventCategoryId",
						dataSource: vm.eventCategoryResources,
						title: "eventCategoryId"
					}
				],
				edit: function(e) {					
					var container = e.container;
					var saveButton = $('.k-scheduler-update');					

					$scope.$apply(function () {
						vm.description = "";
						vm.descriptionLabel = "Add description";
						vm.eventId = 0;
						vm.recurrenceId = 0;
						vm.isInvalidDescription = false;
						if (saveButton[0] && !vm.update) {
							saveButton[0].innerHTML = "Submit";
							saveButton[0].innerText = "Submit";
						}
					});
															
					if (e.event.eventId > 0) {
						vm.eventId = e.event.eventId;

						var newButton = $('<a class="k-button k-primary pull-left" href="#!/main/eventDetail/' + e.event.eventId + '" tabindex="12">View detail</a>');
						var buttonsContainer = container.find(".k-edit-buttons");
						buttonsContainer.append(newButton);
						
						// We remove the save button just in case the user is not allowed to update events											
						if (!vm.update) saveButton.remove();												
					}					
					
					if (e.event.eventId > 0 || e.event.recurrenceId > 0) {						
						$scope.$apply(function () {
							vm.description = e.event.description;
							vm.descriptionLabel = vm.description ? "Edit description" : vm.descriptionLabel;
							vm.recurrenceId = e.event.recurrenceId;
						});

						// We remove the save button just in case the user is not allowed to update events											
						if (!vm.update) saveButton.remove();
					}
				},				
				"messages": {
					"allDay": "All Day",
					"recurrenceMessages": {
						"deleteWindowOccurrence": "Delete occurrence"
					}
                }
			};
        }

        function scheduler_navigate(e) {
            console.log(kendo.format("navigate:: action:{0}; view:{1}; date:{2:d};", e.action, e.view, e.date));
        }

		function create(options) {			
			if (vm.validator && !vm.validator.validate()) {
				options.error();
				return;
			}

			if (!vm.description) {
				NotificationService.warning('It is mandatory to add a description.');
				vm.isInvalidDescription = true;
				return;
			}

			var selectedIds = "";
			angular.forEach(options.data.eventCategories, function (item) {
			    selectedIds += item.eventCategoryId + ',';
			});
			if (selectedIds.length > 0) selectedIds = selectedIds.substring(0, selectedIds.length - 1);

		    EventCategoryService.getSelectedEventCategories(selectedIds)
                .then(getSelectedEventCategoriesSuccess, getSelectedEventCategoriesFailure);

		    function getSelectedEventCategoriesSuccess(response) {
		        var selectedEventCategories = response.data;
		        options.data.eventCategories = selectedEventCategories;

		        options.data.description = vm.description;

		        EventService.create(options.data)
                    .then(createSuccess, createFailure);
		    }

            function getSelectedEventCategoriesFailure(){}

			function createSuccess(response) {
				vm.dataSource.read();
				NotificationService.success('save');
			}
			function createFailure(error) {
				options.error(error);
			}
		}

		function read(options) {
			EventService.getFullCalendarEvents(vm.selectedIds)
				.then(getFullCalendarEventsSuccess, getFullCalendarEventsFailure);

			function getFullCalendarEventsSuccess(response) {
			    $.each(response.data, function (i, val) {
			        val.eventCategoryId = checkEventCategoryIds(val.eventCategories);
			    });
				options.success(response.data);
			}
			function getFullCalendarEventsFailure(error) {
				options.error(error);
			}
		}

		function update(options) {			
			if (vm.validator && !vm.validator.validate()) {			
				options.error();
				return;
			}

			if (!vm.description) {
				NotificationService.warning('It is mandatory to add a description.');
				vm.isInvalidDescription = true;
				return;
			}

			var selectedIds = "";
			angular.forEach(options.data.eventCategories, function (item) {
			    selectedIds += item.eventCategoryId + ',';
			});
			if (selectedIds.length > 0) selectedIds = selectedIds.substring(0, selectedIds.length - 1);

			EventCategoryService.getSelectedEventCategories(selectedIds)
                .then(getSelectedEventCategoriesSuccess, getSelectedEventCategoriesFailure);

			function getSelectedEventCategoriesSuccess(response) {
			    var selectedEventCategories = response.data;
			    options.data.eventCategories = selectedEventCategories;

			    options.data.description = vm.description;

			    EventService.update(options.data)
                    .then(updateSuccess, updateFailure);
			}

			function getSelectedEventCategoriesFailure() { }

			function updateSuccess(response) {
				NotificationService.success('update');
				vm.dataSource.read();
			}
			function updateFailure(error) {
				options.error(error);
			}
		}

		function destroy(options) {
			EventService.remove(options.data.eventId)
				.then(destroySuccess, destroyFailure);

			function destroySuccess(response) {
				NotificationService.success('delete');
				vm.dataSource.read();
			}
			function destroyFailure(error) {				
				options.error(error);
			}
		}		

		vm.tooloptions = {
			tools: ImageBrowser.tools,
			imageBrowser: {
				messages: {
					dropFilesHere: "Drop files here"
				},
				transport: {
					read: {
						url: "api/imageBrowser/images",
						type: "GET",
						cache: false
					},
					destroy: {
						url: function (params) {
							return "api/imageBrowser/?fileName=" + params.name;
						},
						type: "DELETE",
						complete: function () {
							$timeout(function () {
								$rootScope.$broadcast('hideLoadMask');
							});
							NotificationService.success('update');
						}
					},
					thumbnailUrl: "api/imageBrowser/images/thumbnail",
					uploadUrl: "api/imageBrowser/upload",
					imageUrl: "api/imageBrowser/image?path={0}"
				}
			},
			resizable: {
				content: true,
				toolbar: false
			},
			execute: function () {
				$rootScope.showBodyScroll = true;
				$timeout(function () {
					$rootScope.$broadcast('hideBreadcrumb');
				});
			},
			rules: {
				maxTextLength: function (textarea) {										
					if (textarea.is("[data-maxtextlength-msg]") && textarea.val() != "") {
						var maxlength = textarea.attr("data-maxtextlength");
						var value = textarea.data("kendoEditor").value();
						return value.replace(/<[^>]+>/g, "").length <= maxlength;
					}

					return true;
				},
				required: function (textarea) {					
					if (textarea.is("[data-required]") && textarea.val() != "") {						
						var editor = textarea.data("kendoEditor");
						if (!editor) return true;

						var value = editor.value();

						return value.replace(/<[^>]+>/g, "").length > 0;						
					}
					
					return false;
				}
			}
		};		
		
		function openDescriptionModal() {			
			var modalInstance = ModalService.createModal({
				templateUrl: 'wwwroot/app/events/event/event-description.html',
				controller: 'EventDescriptionController',
				windowClass: 'xl-modal event-description-modal',
				resolve: vm.description
			});

			modalInstance.then(modalInstanceResponse, modalInstanceError);

			function modalInstanceResponse(response) {
				if (response !== "cancel") {
					vm.description = response;
					vm.descriptionLabel = vm.description ? "Edit description" : vm.descriptionLabel;															
					vm.isInvalidDescription = false;
					
					var events = vm.dataSource.data();

					var filteredEvents = $filter('filter')(vm.dataSource.data(), { eventId: vm.eventId }, true);
					if (!filteredEvents) filteredEvents = $filter('filter')(vm.dataSource.data(), { recurrenceId: vm.recurrenceId }, true);

                    angular.forEach(events, function (event) {
                        if (event.dirty) {
                            console.log(event);
                        }
					});

					// First we set dirty false to all the events
					//angular.forEach(events, function (event) {
					//	event.dirty = false;
					//});

					// Then we set dirty true the current event
					if (filteredEvents) filteredEvents[0].dirty = true;
				}
			}

			function modalInstanceError() { }
		}
	}
}());