(function () {
	'use strict';

	angular
		.module('e74intranet.dashboard')
		.component('suggestionBox', {
			templateUrl: 'wwwroot/app/dashboard/suggestion-box/suggestion-box.html',
			controller: SuggestionBoxController,
			controllerAs: 'vm'
		});
	
	SuggestionBoxController.$inject = ['SuggestionService'];

	function SuggestionBoxController(SuggestionService) {
		var vm = this;

		activate();

		function activate() {
			SuggestionService.getLandingPageSuggestions()
                .then(getLandingPageSuggestionsSuccess, getLandingPageSuggestionsError);

			function getLandingPageSuggestionsSuccess(response) {
				vm.suggestions = response.data;				
			}

			function getLandingPageSuggestionsError(){}
		}
	}
}());