(function () {
	'use strict';

	angular
       .module('e74intranet.employee')
       .controller('ArticleAddEditController', ArticleAddEditController);

	ArticleAddEditController.$inject = [
		'$scope',
		'$rootScope',
		'$timeout',		
		'$uibModalInstance',
		'modelResolved',
		'AccessService',
		'ArticleService',
		'ArticleCategoryService',
		'NotificationService',	
		'ResourceService',
		'ImageBrowser',
		'CONSTANTS'
	];

	function ArticleAddEditController(
		$scope, 
		$rootScope, 
		$timeout, 		
		$uibModalInstance, 
		modelResolved,
		AccessService,
		ArticleService, 
		ArticleCategoryService, 
		NotificationService,
		ResourceService, 
		ImageBrowser, 
		CONSTANTS) 
	{
		var vm = this;
		vm.article = angular.copy(modelResolved);
		vm.isNew = !vm.article;
		vm.title = (!vm.isNew ? 'Edit' : 'New') + ' Article';
		vm.save = save;
		vm.cancel = cancel;		
		vm.isNewImage = false;		
		var saveUrl = "api/resources/uploadImage";

		activate();		

		function activate() {
			var resourceId;

			if (vm.article && vm.article.resource) {
				vm.selectedFile = vm.article.resource.fileName;
				resourceId = vm.article.resource.resourceId;
			} 

			configureKendoUploader(resourceId);

			ArticleCategoryService.getAll()
				.then(getAllSuccess, getAllFailure);

			function getAllSuccess(response) {
				vm.articleCategories = response.data;
			}

			function getAllFailure(){}
		}		

		function configureKendoUploader(resourceId) {
			if (resourceId) saveUrl = saveUrl + "?resourceId=" + resourceId;

			vm.uploadOptions = {
				multiple: false,
				showFileList: false,
				async: {
					autoUpload: true,
					saveUrl: saveUrl,
					removeUrl: ""
				},
				localization: {
					headerStatusUploaded: "The file was saved successfully",
					select: "Select File"
				},
				upload: onUpload,
				error: onError,
				success: onSuccess,
				select: onSelect,
				clear: onClear
			};
		}

		function onUpload() {
			vm.isUploading = true;
			$scope.$apply();
		}

		function onError() {
			vm.isUploading = false;
			NotificationService.error('An error has occurred while uploading the file. Please contact your system administrator.');
			$scope.$apply();
		}

		function onSuccess(e) {
			if (!vm.article) {
				vm.article = { resource: { resourceId: e.response, fileName: vm.selectedFile } };
			} else {
				vm.article.resource = { resourceId: e.response, fileName: vm.selectedFile };
			}

			vm.isNewImage = true;
			vm.isUploading = false;			
			vm.uploader.options.async.saveUrl = "api/resources/uploadImage?resourceId=" + e.response;
			$scope.$apply();
		}

		function onSelect(e) {
			var file = e.files[0];
			var extensions = ['.jpg', '.gif', '.png', '.jpeg'];

			if (!ResourceService.isExtensionValid(file.name, extensions)) {
				NotificationService.warning('The selected file is not valid. Supported types are .jpg, .gif, .png, .jpeg');
				e.preventDefault();
				return;
			}

			$scope.$apply(function () {				
				vm.resource = file;
				vm.selectedFile = vm.resource.name;
			});
		}

		function onClear() {
			vm.isNewImage = false;			

			$scope.$apply(function () {
				vm.resource = vm.article ? vm.article.resource : null;
				vm.selectedFile = vm.resource ? vm.resource.fileName : "";
			});
		}		

		function save() {
			if (vm.articleForm.$valid) {
				if (!vm.validator.validate()) return;

				saveArticle();
			}			
		}

		function saveArticle() {		
			if (vm.article.articleId) {
				ArticleService.update(vm.article)
                    .then(updateSuccess)
					.catch(failure);
			} else {				
				ArticleService.create(vm.article)
                    .then(saveSuccess)
					.catch(failure);
			}			

			function saveSuccess() {
				NotificationService.success('save');
				$uibModalInstance.close();
			}
			function updateSuccess() {
				NotificationService.success('update');
				$uibModalInstance.close();
			}
			function failure() { }

			$rootScope.showBodyScroll = false;
		}

		function cancel() {
			$rootScope.showBodyScroll = false;
			$uibModalInstance.close('cancel');
		}		

		vm.tooloptions = {
			tools: ImageBrowser.tools,
			imageBrowser: {
				messages: {
					dropFilesHere: "Drop files here"
				},
				transport: {
					read: {
						url: "api/imageBrowser/images",
						type: "GET",
						cache: false
					},
					destroy: {
						url: function (params) {
							return "api/imageBrowser/?fileName=" + params.name;
						},
						type: "DELETE",					
						complete: function () {
							$timeout(function () {
								$rootScope.$broadcast('hideLoadMask');
							});
							NotificationService.success('update');
						}
					},
					thumbnailUrl: "api/imageBrowser/images/thumbnail",
					uploadUrl: "api/imageBrowser/upload",
					imageUrl: "api/imageBrowser/image?path={0}"
				}
			},
			resizable: {
				content: true,
				toolbar: false
			},
			execute: function () {
				$rootScope.showBodyScroll = true;
				$timeout(function () {
					$rootScope.$broadcast('hideBreadcrumb');
				});
			},
			rules: {
				maxTextLength: function (textarea) {					
					if (textarea.is("[data-maxtextlength-msg]") && textarea.val() != "") {
						var maxlength = textarea.attr("data-maxtextlength");
						var value = textarea.data("kendoEditor").value();
						return value.replace(/<[^>]+>/g, "").length <= maxlength;
					}

					return true;
				},
				required: function (textarea) {
					if (textarea.is("[data-required]") && textarea.val() != "") {
						var value = textarea.data("kendoEditor").value();
						return value.replace(/<[^>]+>/g, "").length > 0;
					}

					return false;
				}
			}
		};	
	}
}());