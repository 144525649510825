(function () {
	'use strict';

	angular
		.module('e74intranet.common')
		.provider("ImageBrowser", ImageBrowser);

	function ImageBrowser() {
		var _tools;
		return {
			setTools: function (tools) {
				_tools = tools;
			},
			$get: function () {
				return { tools: _tools };
			}
		};
	}

}());