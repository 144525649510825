(function () {
	'use strict';

	angular
        .module('e74intranet.dashboard')
        .config(Config);

	Config.$inject = ['$stateProvider'];

	function Config($stateProvider) {
		var mainDashboardState = {
			name: 'main.dashboard',
			url: '/dashboard',
			controller: 'DashboardController',
			controllerAs: 'vm',
			templateUrl: 'wwwroot/app/dashboard/dashboard.html',
			params: { mid: null },
			ncyBreadcrumb: {
				label: '{{ getMenuItemLabel("main.dashboard") }}',
			}
        };

        var mainDashboardResidentState = {
            name: 'main.dashboardResident',
            url: '/dashboardResident',
            controller: 'DashboardResidentController',
            controllerAs: 'vm',
            templateUrl: 'wwwroot/app/dashboard/dashboardResident.html',
            params: { mid: null },
            ncyBreadcrumb: {
                label: '{{ getMenuItemLabel("main.dashboard") }}',
            }
        };

        $stateProvider.state(mainDashboardResidentState);
		$stateProvider.state(mainDashboardState);
	}
}());