(function () {
    'use strict';

    angular
        .module('e74intranet.employee')
        .controller('AnnouncementCategoryController', AnnouncementCategoryController);

    AnnouncementCategoryController.$inject = ['AnnouncementCategoryService', 'NotificationService', 'ModalService', 'CONSTANTS'];

    function AnnouncementCategoryController(AnnouncementCategoryService, NotificationService, ModalService, CONSTANTS) {
        var vm = this;
        vm.pageNumber = CONSTANTS.PAGINATION.DEFAULT_PAGE_NUMBER;
        vm.pageSize = CONSTANTS.PAGINATION.DEFAULT_PAGE_SIZE;
        vm.announcementCategory = {};
        vm.loadGrid = loadGrid;
        vm.openModal = openModal;
        vm.deleteRow = deleteRow;
        vm.gridOptions = {};


        activate();

        vm.gridOptions = {
            columnDefs: [
                { field: 'name', name: 'Name', displayName: 'Name' },
            ],
            onRegisterApi: function (gridApi) {
                gridApi.pagination.on.paginationChanged(null, function (newPage, pageSize) {
                    vm.pageNumber = newPage;
                    vm.pageSize = pageSize;
                    loadGrid();
                });
                gridApi.core.on.sortChanged(null, function (grid, sortColumns) {
                    if (sortColumns.length > 0) {
                        vm.orderBy = sortColumns[0].name;
                        vm.ascending = sortColumns[0].sort.direction === 'asc' ? true : false;
                    }
                    loadGrid();
                });
                gridApi.selection.on.rowSelectionChanged(null, function (row) {
                    vm.announcementCategory = [];
                    vm.disabled = true;
                    if (row.isSelected) {
                        vm.announcementCategory = row.entity;
                        vm.disabled = false;
                    }
                });
            }
        };

        function activate() {
            vm.disabled = true;
            loadGrid();
        }

        function loadGrid() {
            AnnouncementCategoryService.getPagedAnnouncementCategories(vm.pageNumber, vm.pageSize, vm.ascending, vm.orderBy, vm.searchText)
                .then(getPagedAnnouncementCategoriesSuccess, getPagedAnnouncementCategoriesFailure);

            function getPagedAnnouncementCategoriesSuccess(response) {
                vm.gridOptions.data = response.data.results;
                vm.gridOptions.totalItems = response.data.totalItems;
            }

            function getPagedAnnouncementCategoriesFailure() { }
        }

        function openModal(action) {
            var modalInstance = ModalService.createModal({
                templateUrl: 'wwwroot/app/employee/announcement-categories/announcement-category-add-edit.html',
                controller: 'AnnouncementCategoryAddEditController',
                resolve: action === 'edit' ? vm.announcementCategory : null
            });

            modalInstance.then(modalInstanceResponse, modalInstanceError);

            function modalInstanceResponse(response) {
                if (response !== "cancel") {
                    activate();
                }
            }

            function modalInstanceError() { }
        }

        function deleteRow() {
            var modalPromptInstance = ModalService.createModalPrompt({
                title: 'Delete category',
                content: 'The selected category will be deleted. Do you want to continue?'
            });

            modalPromptInstance.then(modalPromptResponse, modalPromptInstanceError);

            function modalPromptResponse(response) {
                if (response === 'cancel') return;

                AnnouncementCategoryService.remove(vm.announcementCategory.announcementCategoryId)
                    .then(removeSuccess, removeFailure);

                function removeSuccess() {
                    NotificationService.success('delete');
                    activate();
                }

                function removeFailure() { }
            }

            function modalPromptInstanceError() { }
        }
    }
}());