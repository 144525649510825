(function () {
    'use strict';

    angular
        .module('e74intranet.cafeteria')
        .factory('MealService', MealService);

    MealService.$inject = ['$http'];

    function MealService($http) {
        var root = 'api/meals';
        var mealService = {
            getById: getById,
            getLandingPageMeals: getLandingPageMeals,
            getMealsByEvents: getMealsByEvents,
            getPagedMeals: getPagedMeals,
            create: create,
            update: update,
            remove: remove
        };

        function getById(id) {
            return $http.get(root + '/' + id);
        }

        function getLandingPageMeals() {
            return $http.get(root + '/landingPage/meals');
        }

        function getMealsByEvents(eventId) {
            return $http.get(root + '/getMealsByEvent/' + eventId);
        }

        function create(model) {
            return $http.post(root, model);
        }

        function update(model) {
            return $http.put(root, model);
        }

        function remove(id) {
            return $http.delete(root + '/' + id);
        }

        return mealService;
    }
}());