(function () {
	'use strict';

	angular
        .module('e74intranet.employee')
        .controller('PolicyOverviewController', PolicyOverviewController);

	PolicyOverviewController.$inject = ['$rootScope', '$scope', 'PolicyService', 'DepartmentService', 'UserService', 'AccessService', 'ModalService', 'NotificationService', 'localStorageService'];

	function PolicyOverviewController($rootScope, $scope, PolicyService, DepartmentService, UserService, AccessService, ModalService, NotificationService, localStorageService) {
	    var vm = this;
	    vm.selectedItems = [];
	    vm.getDepartmentPolicyList = getDepartmentPolicyList;
	    vm.getJustPolicies = getJustPolicies;
	    vm.getFilteredPolicies = getFilteredPolicies;
	    vm.getFilteredPoliciesOnEnter = getFilteredPoliciesOnEnter;
	    vm.openModal = openModal;
	    vm.deleteRow = deleteRow;
	    vm.clearResults = clearResults;

	    activate();

	    $scope.getPolicies = function () {
	        vm.selectedAccordionsIds = "";
	        angular.forEach(vm.departmentPolicies, function (item) {
	            if (item.isOpen) {
	                vm.selectedAccordionsIds += item.departmentId + ',';
	            }
	        });

	        if (vm.selectedAccordionsIds.length > 0) {
	            vm.selectedAccordionsIds = vm.selectedAccordionsIds.substring(0, vm.selectedAccordionsIds.length - 1);
	            getJustPolicies();
	        }
	    };

	    // Componentes setup
	    vm.multiDropdownSettings = {
	        idProp: 'departmentId',
	        externalIdProp: 'departmentId',
	        displayProp: 'name',
	        buttonClasses: 'btn btn-default btn-multi-dropdown'
	    };

	    vm.translation = {
	        buttonDefaultText: 'Filter by Department',
	        dynamicButtonTextSuffix: 'selected'
	    };

	    vm.multiDropdownEvents = {
	        onSelectionChanged: onSelectionChanged,
	    };

	    // Components methods
	    function activate() {
	        setPermissions();
	        getDepartments();
	        getDepartmentPolicyList();
	        getUserInfo();
	    }

	    function setPermissions() {
	        if (AccessService.hasPermissions($rootScope.permission.CREATE)) vm.create = true;
	        if (AccessService.hasPermissions($rootScope.permission.UPDATE)) vm.update = true;
	        if (AccessService.hasPermissions($rootScope.permission.DELETE)) vm.delete = true;
	        if (AccessService.hasPermissions($rootScope.permission.DEPARTMENT_UPDATE)) vm.departmentUpdate = true;
	    }

	    function getDepartments() {
	        DepartmentService.getAll()
				.then(getAllSuccess, getAllFailure);

	        function getAllSuccess(response) {
	            vm.departments = response.data;
	        }

	        function getAllFailure() { }
	    }

	    function getUserInfo() {
	        UserService.getCurrentUser()
				.then(getCurrentUserSuccess, getCurrentUserFailure);

	        function getCurrentUserSuccess(response) {
	            vm.user = response.data;
	        }

	        function getCurrentUserFailure() { }
	    }

	    function getDepartmentPolicyList() {
	        PolicyService.getDepartmentPolicyList(vm.selectedIds, vm.searchText)
                .then(getDepartmentPolicyListSuccess, getDepartmentPolicyListFailure);

	        function getDepartmentPolicyListSuccess(response) {
	            vm.departmentPolicies = response.data;
	        }

	        function getDepartmentPolicyListFailure() { }
	    }

	    function getJustPolicies() {
	        PolicyService.getJustPolicies(vm.selectedAccordionsIds, vm.searchText)
                .then(getJustPoliciesSuccess, getJustPoliciesFailure);

	        function getJustPoliciesSuccess(response) {
	            var accordionIds = vm.selectedAccordionsIds.split(',');

	            for (var a = 0; a < accordionIds.length; a++) {
	                var index = findWithAttr(vm.departmentPolicies, 'departmentId', accordionIds[a]);
	                vm.departmentPolicies[index].policies = response.data;
	            }
	        }

	        function getJustPoliciesFailure() { }
	    }

	    function findWithAttr(array, attr, value) {
	        for (var i = 0; i < array.length; i += 1) {
	            if (array[i][attr] == value) {
	                return i;
	            }
	        }
	        return -1;
	    }

	    function getFilteredPolicies() {
	        PolicyService.getFilteredPolicies(vm.selectedIds, vm.searchText)
                .then(getFilteredPoliciesSuccess, getFilteredPoliciesFailure);

	        function getFilteredPoliciesSuccess(response) {
	            vm.departmentPolicies = response.data;
	        }

	        function getFilteredPoliciesFailure() { }
	    }

	    function getFilteredPoliciesOnEnter() {
	        PolicyService.getFilteredPolicies(vm.selectedIds, vm.searchText)
                .then(getFilteredPoliciesSuccess, getFilteredPoliciesFailure);

	        function getFilteredPoliciesSuccess(response) {
	            vm.departmentPolicies = response.data;

	            angular.forEach(vm.departmentPolicies, function (item) {
	                item.isOpen = true;
	            });
	        }

	        function getFilteredPoliciesFailure() { }
	    }

	    var authData = localStorageService.get('authorizationData');
	    var token = "";

	    if (authData) {
	        token = 'Bearer ' + authData.token;
	    }

	    vm.autoCompleteOptions = {
	        placeholder: "Search by Name",
	        dataTextField: "policyNumberName",
	        filter: "contains",
	        clearButton: false,
	        dataSource: new kendo.data.DataSource({
	            serverFiltering: true,
	            transport: {
	                read: {
	                    url: "api/policies",
	                    dataType: "json",
	                    beforeSend: function (req) {
	                        req.setRequestHeader('Authorization', token);
	                    }
	                },
	                parameterMap: function (data, type) {
	                    var queryString = {};

	                    if (vm.searchText) queryString.searchText = vm.searchText;
	                    if (vm.selectedIds) queryString.departmentIds = vm.selectedIds;

	                    return queryString;
	                }
	            }
	        }),
	        select: onSelect
	    };

	    function onSelect(e) {
	        vm.searchText = e.item.text();
	        getDepartmentPolicyList();
	    }

	    function clearResults() {
	        vm.searchText = '';
	        getDepartmentPolicyList();
	    }

	    // Components events
	    function onSelectionChanged() {
	        vm.selectedIds = "";
	        angular.forEach(vm.selectedItems, function (item) {
	            vm.selectedIds += item.departmentId + ',';
	        });

	        if (vm.selectedIds.length > 0) vm.selectedIds = vm.selectedIds.substring(0, vm.selectedIds.length - 1);

	        getDepartmentPolicyList();
	    }

	    function openModal(action, policy, department) {
	        if (action == 'edit') {
	            policy.department = {
	                departmentId: department.departmentId,
	                name: department.departmentName
	            };
	        }

	        var modalInstance = ModalService.createModal({
	            templateUrl: 'wwwroot/app/employee/policies/policy-overview/policy-overview-add-edit.html',
	            controller: 'PolicyOverviewAddEditController',
	            resolve: action === 'edit' ? policy : null
	        });

	        modalInstance.then(modalInstanceResponse, modalInstanceError);

	        function modalInstanceResponse(response) {
	            if (response !== "cancel") {
	                getFilteredPolicies();
	            }
	        }

	        function modalInstanceError() { }
	    }

	    function deleteRow(policy) {
	        var modalPromptInstance = ModalService.createModalPrompt({
	            title: 'Delete Policy',
	            content: 'The selected policy will be deleted. Do you want to continue?'
	        });

	        modalPromptInstance.then(modalPromptResponse, modalPromptInstanceError);

	        function modalPromptResponse(response) {
	            if (response === 'cancel') return;

	            PolicyService.remove(policy.policyId)
					.then(removeSuccess, removeFailure);

	            function removeSuccess() {
	                NotificationService.success('delete');
	                getFilteredPolicies();
	            }

	            function removeFailure() { }
	        }

	        function modalPromptInstanceError() { }
	    }
	}
}());