// This is a filter to return a filtered value to be shown in the grids.
// The required param is the 'filter' param, which is the 
// name of the action or view to be filtered.
// For example: 'user'.
// Example of expression using it: {{ value | status: { filter: user }}}
(function () {
	'use strict';

	angular
        .module('e74intranet.common')
        .filter('status', StatusFilter);

	StatusFilter.$inject = ['CONSTANTS'];

	function StatusFilter(CONSTANTS) {
		return function (value, params) {			
			switch (value) {
				case true:
					return '<span class="label label-success">' + CONSTANTS.STATUS.ACTIVE + '</span>';
				case false:
					return '<span class="label label-danger">' + CONSTANTS.STATUS.INACTIVE + '</span>';
				default:
					return '<span class="label label-danger">' + CONSTANTS.STATUS.INACTIVE + '</span>';
			}			
		};
	}
}());