(function () {
	'use strict';

	angular
        .module('e74intranet.common')
        .controller('ErrorController', ErrorController);

	ErrorController.$inject = ['$state', 'AccessService'];

	function ErrorController($state, AccessService) {
		var vm = this;
		vm.goHome = goHome;
		vm.goLogin = goLogin;
		vm.goHomeFrom403 = goHomeFrom403;

		function goHome() {
			$state.go('main.dashboard', {}, { reload: true });
		}

		function goLogin() {			
			$state.go('init', {}, { reload: true });
		}

		function goHomeFrom403() {
			if (!AccessService.isAuthenticated()) goLogin();
			else goHome();
		}
	}
}());