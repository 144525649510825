(function () {
	'use strict';

	angular
		.module('e74intranet.common', [			
			'angular-growl',
			'angularMoment',
			'angularjs-dropdown-multiselect',
			'blockUI',
			'bootstrap.angular.validation',
			'colorpicker.module',
			'daterangepicker',
			'http-auth-interceptor',
			'ivh.treeview',
			'kendo.directives',
			'LocalStorageModule',
			'mgcrea.ngStrap.popover',
			'ncy-angular-breadcrumb',			
			'ngAnimate',
			'ngCookies',						
			'ngSanitize',
			'ng.shims.placeholder',			
			'slickCarousel',
			'ui.bootstrap',
			'ui.grid',
            'ui.grid.autoResize',			
            'ui.grid.pagination',
            'ui.grid.selection',
			'ui.router'
		]);
}());