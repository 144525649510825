(function () {
	'use strict';

	angular
		.module('e74intranet.catalogs')
		.factory('ArticleCategoryService', ArticleCategoryService);

	ArticleCategoryService.$inject = ['$http'];

	function ArticleCategoryService($http) {
		var root = 'api/articleCategories';
		var articleCategoryService = {
			getAll: getAll,
			getPagedArticleCategories: getPagedArticleCategories,
			getById: getById,
			create: create,
			update: update,
			remove: remove
		};

		function getAll() {
			return $http.get(root);
		}

		function getPagedArticleCategories(page, pageSize, ascending, orderBy, searchText) {
			return $http.get(root + '/paging?page=' + (page || 1) +
									'&pageSize=' + (pageSize || 10) +
									'&ascending=' + (ascending == undefined ? true : ascending) +
									'&orderBy=' + (orderBy || '') +
									'&search=' + (searchText || ''));
		}		

		function getById(id) {
			return $http.get(root + '/' + id);
		}

		function create(model) {
			return $http.post(root, model);
		}

		function update(model) {
			return $http.put(root, model);
		}

		function remove(id) {
			return $http.delete(root + '/' + id);
		}

		return articleCategoryService;
	}
}());


