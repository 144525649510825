(function () {
    'use strict';

    angular
        .module('e74intranet.reservation')
        .controller('ReservationResidentController', ReservationResidentController);

    ReservationResidentController.$inject = ['$rootScope', 'ReservationResidentService', 'ReservationLocationService', 'NotificationService', 'ModalService', 'CONSTANTS'];

    function ReservationResidentController($rootScope, ReservationResidentService, ReservationLocationService, NotificationService, ModalService, CONSTANTS) {
        var vm = this;
        vm.reservation = {};        
        vm.openModal = openModal;

        activate();

        function activate() {
            vm.disabled = true;            
        }
        
        function openModal(action) {
            var modalInstance = ModalService.createModal({
                templateUrl: 'wwwroot/app/reservations/reservation/reservation-add-edit.html',
                controller: 'ReservationResidentAddEditController',
                size: 'lg',
                resolve: action === 'edit' ? vm.reservation : null
            });

            modalInstance.then(modalInstanceResponse, modalInstanceError);

            function modalInstanceResponse(response) {
                if (response !== "cancel") {
                    activate();
                }
            }

            function modalInstanceError() { }
        }       
    }
}());