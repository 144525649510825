(function () {
	'use strict';

	angular
		.module('e74intranet.employee')
		.factory('SuggestionService', SuggestionService);

	SuggestionService.$inject = ['$http'];

	function SuggestionService($http) {
		var root = 'api/suggestions';
		var suggestionService = {
			getById: getById,
			getPagedSuggestions: getPagedSuggestions,
			getLandingPageSuggestions: getLandingPageSuggestions,						
			getSuggestionStatus: getSuggestionStatus,
			submit: submit,
			create: create,
			update: update,
			remove: remove,
			updateSuggestionStatus: updateSuggestionStatus
		};

		function getLandingPageSuggestions() {
			return $http.get(root + '/landingPage/suggestions');
		}		

		function getPagedSuggestions(page, pageSize, ascending, orderBy, searchText, startDate, endDate) {
			var requestUrl = root + '/paging?page=' + (page || 1) +
									'&pageSize=' + (pageSize || 10) +
									'&ascending=' + (ascending == undefined ? true : ascending) +
									'&orderBy=' + (orderBy || '') +
									'&search=' + (searchText || '');

			if (startDate) requestUrl += '&startDate=' + startDate;
			if (endDate) requestUrl += '&endDate=' + endDate;
			
			return $http.get(requestUrl);
		}

		function getById(id) {
			return $http.get(root + '/' + id);
		}		

		function getSuggestionStatus() {
			return $http.get(root + '/statusList');
		}

		function create(model) {
			return $http.post(root, model);
		}

		function submit(model) {
			return $http.post(root + '/submitSuggestion', model);
		}

		function update(model) {
			return $http.put(root, model);
		}

		function remove(id) {
			return $http.delete(root + '/' + id);
		}

		function updateSuggestionStatus(model) {
			return $http.put(root + '/suggestionStatus', model);
		}

		return suggestionService;
	}
}());


