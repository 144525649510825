(function () {
	'use strict';

	angular
        .module('e74intranet.catalogs')
        .controller('DepartmentController', DepartmentController);

	DepartmentController.$inject = ['DepartmentService', 'NotificationService', 'ModalService', 'CONSTANTS'];

	function DepartmentController(DepartmentService, NotificationService, ModalService, CONSTANTS) {
		var vm = this;
		vm.pageNumber = CONSTANTS.PAGINATION.DEFAULT_PAGE_NUMBER;
		vm.pageSize = CONSTANTS.PAGINATION.DEFAULT_PAGE_SIZE;
		vm.department = {};
		vm.loadGrid = loadGrid;
		vm.openModal = openModal;
		vm.deleteRow = deleteRow;
		vm.gridOptions = {};

		activate();

		vm.gridOptions = {
			columnDefs: [
                { field: 'name', name: 'Name', displayName: 'Name' },
                { field: 'phone', name: 'Phone', displayName: 'Phone' }
			],
			onRegisterApi: function (gridApi) {
				gridApi.pagination.on.paginationChanged(null, function (newPage, pageSize) {
					vm.pageNumber = newPage;
					vm.pageSize = pageSize;
					loadGrid();
				});
				gridApi.core.on.sortChanged(null, function (grid, sortColumns) {
					if (sortColumns.length > 0) {
						vm.orderBy = sortColumns[0].name;
						vm.ascending = sortColumns[0].sort.direction === 'asc' ? true : false;
					}
					loadGrid();
				});
				gridApi.selection.on.rowSelectionChanged(null, function (row) {
					vm.department = [];
					vm.disabled = true;
					if (row.isSelected) {
						vm.department = row.entity;
						vm.disabled = false;
					}
				});
			}
		};

		function activate() {
			vm.disabled = true;
			loadGrid();
		}

		function loadGrid() {
			DepartmentService.getPagedDepartments(vm.pageNumber, vm.pageSize, vm.ascending, vm.orderBy, vm.searchText)
                .then(getPagedDepartmentsSuccess, getPagedDepartmentsFailure);

			function getPagedDepartmentsSuccess(response) {
				vm.gridOptions.data = response.data.results;
				vm.gridOptions.totalItems = response.data.totalItems;
			}

			function getPagedDepartmentsFailure(){}
		}

		function openModal(action) {
			var modalInstance = ModalService.createModal({
				templateUrl: 'wwwroot/app/catalogs/departments/department-add-edit.html',
				controller: 'DepartmentAddEditController',
				resolve: action === 'edit' ? vm.department : null
			});

			modalInstance.then(modalInstanceResponse, modalInstanceError);

			function modalInstanceResponse(response) {
				if (response !== "cancel") {
					activate();
				}
			}

			function modalInstanceError() { }
		}

		function deleteRow() {
			var modalPromptInstance = ModalService.createModalPrompt({
				title: 'Delete department',
				content: 'The selected department will be deleted. Do you want to continue?'
			});

			modalPromptInstance.then(modalPromptResponse, modalPromptInstanceError);

			function modalPromptResponse(response) {
				if (response === 'cancel') return;

				DepartmentService.remove(vm.department.departmentId)
                    .then(removeSuccess, removeFailure);

				function removeSuccess() {
					NotificationService.success('delete');
					activate();
				}

				function removeFailure(){}
			}

			function modalPromptInstanceError() { }
		}
	}
}());