(function () {
	'use strict';

	angular
        .module('e74intranet.employee')
        .controller('SuggestionStatusController', SuggestionStatusController);

	SuggestionStatusController.$inject = ['$rootScope', 'SuggestionService', 'ModalService', 'AccessService'];

	function SuggestionStatusController($rootScope, SuggestionService, ModalService, AccessService) {
		var vm = this;				
		vm.openModal = openModal;

		activate();

		function activate() {
			setPermissions();			
			getSuggestionStatus();
		}

		function setPermissions() {			
			if (AccessService.hasPermissions($rootScope.permission.UPDATE)) vm.update = true;			
		}	

		function getSuggestionStatus() {
			SuggestionService.getSuggestionStatus()
				.then(getSuggestionStatusSuccess, getSuggestionStatusFailure);

			function getSuggestionStatusSuccess(response) {
				vm.suggestionStatus = response.data;
			}

			function getSuggestionStatusFailure() { }
		}

		function openModal(action, suggestion) {
			var modalInstance = ModalService.createModal({
				templateUrl: 'wwwroot/app/catalogs/suggestion-status/suggestion-status-add-edit.html',
				controller: 'SuggestionStatusAddEditController',
				resolve: suggestion
			});

			modalInstance.then(modalInstanceResponse, modalInstanceError);

			function modalInstanceResponse(response) {
				if (response !== "cancel") {
					getSuggestionStatus();
				}
			}

			function modalInstanceError() { }
		}		
	}
}());