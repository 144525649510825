(function () {
	'use strict';

	angular
       .module('e74intranet.employee')
       .controller('FindPhysicianAddEditController', FindPhysicianAddEditController);

	FindPhysicianAddEditController.$inject = [		
		'$scope',		
		'$uibModalInstance',				
		'CONSTANTS',		
		'modelResolved',		
		'NotificationService',
		'PositionService',
		'PhysicianService',
		'SpecialityService'
	];

	function FindPhysicianAddEditController(		
		$scope,		
		$uibModalInstance,				
		CONSTANTS,		
		modelResolved,		
		NotificationService,
		PositionService,
		PhysicianService,
		SpecialityService
		)
	{
		var vm = this;
        vm.physician = angular.copy(modelResolved);
        vm.clearDateJoined = clearDateJoined;
        vm.clearDatePassed = clearDatePassed;
		vm.isNew = !vm.physician;
		vm.title = (!vm.isNew ? 'Edit' : 'Add') + ' a Resident';
		vm.save = save;
		vm.cancel = cancel;
		vm.isNewFile = false;
		var saveUrl = "api/resources/uploadImage";

		activate();

		function activate() {
			var resourceId;

			if (vm.physician && vm.physician.resource) {
				vm.selectedFile = vm.physician.resource.fileName;
				resourceId = vm.physician.resource.resourceId;
			}

			configureKendoUploader(resourceId);	
			getSpecialities();
			getPositions();			
		}				

		function getSpecialities() {
			SpecialityService.getAll()
				.then(getAllSpecialitiesSuccess, getAllSpecialitiesFailure);

			function getAllSpecialitiesSuccess(response) {
				vm.specialities = response.data;
			}

			function getAllSpecialitiesFailure() { }
		}

		function getPositions() {
			PositionService.getAll()
				.then(getAllPositionsSuccess, getAllPositionsFailure);

			function getAllPositionsSuccess(response) {
				vm.positions = response.data;
			}

			function getAllPositionsFailure() { }
		}	

		function configureKendoUploader(resourceId) {
			if (resourceId) saveUrl = saveUrl + "?resourceId=" + resourceId;

			vm.uploadOptions = {
				multiple: false,
				showFileList: false,
				async: {
					autoUpload: true,
					saveUrl: saveUrl,
					removeUrl: ""
				},
				localization: {
					headerStatusUploaded: "The image was saved successfully",
					select: "Select File"
				},
				upload: onUpload,
				error: onError,
				success: onSuccess,
				select: onSelect,
				clear: onClear
			};
		}

		function onUpload() {
			vm.isUploading = true;
			$scope.$apply();
		}

		function onError() {
			vm.isUploading = false;
			NotificationService.error('An error has occurred while uploading the image. Please contact your system administrator.');
			$scope.$apply();
		}

		function onSuccess(e) {
			if (!vm.physician) {
				vm.physician = { resource: { resourceId: e.response, fileName: vm.selectedFile } };
			} else {
				vm.physician.resource = { resourceId : e.response, fileName: vm.selectedFile };
			}
			vm.isNewFile = true;
			vm.isUploading = false;
			vm.uploader.options.async.saveUrl = "api/resources/uploadImage?resourceId=" + e.response;
			$scope.$apply();
		}

		function onSelect(e) {
			var file = e.files[0];

			if (file.extension.toLowerCase() != ".png" && file.extension.toLowerCase() != ".gif" && file.extension.toLowerCase() != ".jpg" && file.extension.toLowerCase() != ".jpeg") {
			    NotificationService.warning('The selected image is not valid. Supported image are *.png, *.gif, *.jpg, *.jpeg');
				e.preventDefault();
				return;
			}

			$scope.$apply(function () {
				vm.resource = file;
				vm.selectedFile = vm.resource.name;
			});
		}

		function onClear() {
			vm.isNewFile = false;

			$scope.$apply(function () {
				vm.resource = vm.physician ? vm.physician.resource : null;
				vm.selectedFile = vm.resource ? vm.resource.fileName : "";
			});
        }

        function clearDateJoined() {
            if (vm.physician) {
                vm.dateJoined = '';
            }
        }

        function clearDatePassed() {
            if (vm.physician) {
                vm.datePassed = '';
            }
        }

		function save() {
			if (vm.physicianForm.$valid) {
				if (!vm.physician.resource) {
					NotificationService.warning('It is mandatory to add a profile image.');
					return;
				}

                if (vm.dateJoined == '') {
                    vm.physician.dateJoined = null;
                }

                if (vm.datePassed == '') {
                    vm.physician.datePassed = null;
                }

				savePhysician();
			}
		}

		function savePhysician() {
			if (vm.physician.physicianId) {
				PhysicianService.update(vm.physician)
					.then(updateSuccess)
					.catch(failure);
			} else {
				PhysicianService.create(vm.physician)
					.then(saveSuccess)
					.catch(failure);
			}

			function saveSuccess() {
			    NotificationService.success('save');
			    $(".widget-img").each(function () {
			        var src = $(this).prop("src");
			        $(this).prop("src", "");
			    });
				$uibModalInstance.close();
			}
			function updateSuccess() {
			    NotificationService.success('update');
			    $(".widget-img").each(function () {
			        var src = $(this).prop("src");
			        $(this).prop("src", "");
			    });
				$uibModalInstance.close();
			}
			function failure() { }
		}

		function cancel() {			
			$uibModalInstance.close('cancel');
		}				
	}
}());