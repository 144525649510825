(function () {
	'use strict';

	angular
        .module('e74intranet.common')
        .constant('CONSTANTS', {
        	CONFIG: {        		
        	    LOGIN_URL: 'http://localhost/e74intranet/token',
        	    //LOGIN_URL: 'http://18.216.27.103/e74intranet/token',
				CLIENT_ID: 'e74intranetApp'
        	},
        	ANNOUNCEMENTS: {
        		HIDE_MINUTES: 30
        	},
        	FILE_TYPES : {
        	    IMAGE: 1,
        	    FILE: 2,
        	    SHARED_IMAGE: 3,
        	    CATALOG_RESOURCE_VIDEO: 4,
                CATALOG_RESOURCE_FILE: 5        	   
        	},
        	MESSAGES: {
        		SUCCESS: {
        			SAVE: 'The item has been saved successfully.',
        			UPDATE: 'The item has been updated successfully.',
        			DELETE: 'The item has been deleted successfully.'
        		},
        		ERROR: {
        			SAVE: 'An error occurred while saving the item.',
        			UPDATE: 'An error occurred while updating the item.',
        			DELETE: 'An error occurred while deleting the item.',
        			FATAL: 'An error occurred while processing the request.'
        		}
        	},        	
        	PAGINATION: {        		
        		PAGE_SIZE: 10
        	},
        	PERMISSIONTYPE: {
        		CREATE: 1,
        		READ: 2,
        		UPDATE: 3,
        		DELETE: 4,
				ANNOUNCEMENTS: 5,
        		FEATURED_SECTION: 6,
        		ARTICLE: 7,
        		BACK_TALK: 8,
        		EVENTS: 9,
				SUGGESTION_BOX: 10,
				APPROVE_REJECT: 11,
				SUBMIT_SUGGESTION: 12						
        	},
        	RESOURCE: {
        	    MAX_FILE_SIZE: 104857600 // Bytes
            },
        	SUGGESTION_STATUS: {
        		GOING_THROUGH_WITH: 1,
        		STILL_IN_DISCUSSION: 2,
				NOT_PURSUING: 3
        	},
        	STATUS: {        		        			
        		ACTIVE: "Active",
        		INACTIVE: "Inactive"        		
        	}
        });
}());