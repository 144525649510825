(function () {
	'use strict';

	angular
        .module('e74intranet.employee')
        .controller('CatalogResourceController', CatalogResourceController);

	CatalogResourceController.$inject = [
        '$rootScope',
        'CatalogResourceService',
        'CONSTANTS',
        'DepartmentService',
        'AccessService',
        'ModalService',
        'NotificationService',
        'localStorageService'
	];

	function CatalogResourceController(
        $rootScope,
        CatalogResourceService,
        CONSTANTS,
        DepartmentService,
        AccessService,
        ModalService,
        NotificationService,
        localStorageService)
    {
		var vm = this;		
		vm.selectedItems = [];	
		vm.getFilteredCatalogResources = getFilteredCatalogResources;		
		vm.openModal = openModal;
		vm.deleteRow = deleteRow;
		vm.clearResults = clearResults;
		vm.catalogResourceVideo = CONSTANTS.FILE_TYPES.CATALOG_RESOURCE_VIDEO;
		vm.catalogResourceFile = CONSTANTS.FILE_TYPES.CATALOG_RESOURCE_FILE;

		activate();	

		// Componentes setup
		vm.multiDropdownSettings = {
			idProp: 'departmentId',
			externalIdProp: 'departmentId',
			displayProp: 'name',
			buttonClasses: 'btn btn-default btn-multi-dropdown'
		};

		vm.translation = {
			buttonDefaultText: 'Filter by Department',
			dynamicButtonTextSuffix: 'selected'
		};

		vm.multiDropdownEvents = {
			onSelectionChanged: onSelectionChanged,
		};

		// Components methods
		function activate() {
			setPermissions();
			getDepartments();
			getFilteredCatalogResources();
		}		

		function setPermissions() {
			if (AccessService.hasPermissions($rootScope.permission.CREATE)) vm.create = true;
			if (AccessService.hasPermissions($rootScope.permission.UPDATE)) vm.update = true;
			if (AccessService.hasPermissions($rootScope.permission.DELETE)) vm.delete = true;			
		}

		function getDepartments() {
			DepartmentService.getAll()
				.then(getAllSuccess, getAllFailure);

			function getAllSuccess(response) {
				vm.departments = response.data;
			}

			function getAllFailure(){}
		}		

		function getFilteredCatalogResources() {
		    CatalogResourceService.getFilteredCatalogResources(vm.selectedIds, vm.searchText)
                .then(getFilteredCatalogResourcesSuccess, getFilteredCatalogResourcesFailure);

		    function getFilteredCatalogResourcesSuccess(response) {
				vm.departmentCatalogResources = response.data;				
			}

		    function getFilteredCatalogResourcesFailure(error) { }
		}								
		
		var authData = localStorageService.get('authorizationData');
		var token = "";

		if (authData) {
			token = 'Bearer ' + authData.token;
		}

		vm.autoCompleteOptions = {
			placeholder: "Search by Name",
			dataTextField: "name",
			filter: "contains",
			clearButton: false,
			dataSource: new kendo.data.DataSource({
				serverFiltering: true,
				transport: {					
					read: {
						url: "api/catalogResources",
						dataType: "json",
						beforeSend: function (req) {
							req.setRequestHeader('Authorization', token);
						}
					},
					parameterMap: function (data, type) {
						var queryString = {};

						if (vm.searchText) queryString.searchText = vm.searchText;
						if (vm.selectedIds) queryString.departmentIds = vm.selectedIds;						

						return queryString;
					}
				}
			}),
			select: onSelect
		};

		function onSelect(e) {
			vm.searchText = e.item.text();
			getFilteredCatalogResources();
		}

		function clearResults() {
			vm.searchText = '';
			getFilteredCatalogResources();
		}

		// Components events
		function onSelectionChanged() {
			vm.selectedIds = "";
			angular.forEach(vm.selectedItems, function (item) {
				vm.selectedIds += item.departmentId + ',';
			});

			if (vm.selectedIds.length > 0) vm.selectedIds = vm.selectedIds.substring(0, vm.selectedIds.length - 1);

			getFilteredCatalogResources();
		}
		
		function openModal(action, catalogResource, department) {			
			if (action == 'edit') {
			    catalogResource.department = {
					departmentId: department.departmentId,
					name: department.departmentName
				};
			}

			var modalInstance = ModalService.createModal({
				templateUrl: 'wwwroot/app/employee/catalog-resources/catalog-resource-add-edit.html',
				controller: 'CatalogResourceAddEditController',
				resolve: action === 'edit' ? catalogResource : null
			});

			modalInstance.then(modalInstanceResponse, modalInstanceError);

			function modalInstanceResponse(response) {
				if (response !== "cancel") {
					activate();
				}
			}

			function modalInstanceError() { }
		}

		function deleteRow(catalogResource) {
			var modalPromptInstance = ModalService.createModalPrompt({
				title: 'Delete Resource',
				content: 'The selected resource will be deleted. Do you want to continue?'
			});

			modalPromptInstance.then(modalPromptResponse, modalPromptInstanceError);

			function modalPromptResponse(response) {
				if (response === 'cancel') return;

			    CatalogResourceService.remove(catalogResource.catalogResourceId)
					.then(removeSuccess, removeFailure);

				function removeSuccess() {
					NotificationService.success('delete');
					getFilteredCatalogResources();
				}

				function removeFailure(){}
			}

			function modalPromptInstanceError() { }
		}		
	}	
}());