(function () {
	'use strict';

	angular
        .module('e74intranet.common')
        .factory('NotificationService', NotificationService);

	NotificationService.$inject = ['growl', 'CONSTANTS'];

	function NotificationService(growl, CONSTANTS) {
		var notificationService = {
			success: success,
			warning: warning,
			info: info,
			error: error
		};

		function success(msg) {
			var message = getSuccessMessage(msg);
			growl.success(message);
		}

		function warning(msg) {
			var message = msg || 'Generic message: Warning';
			growl.warning(message);
		}

		function info(msg) {
			var message = msg || 'Generic message: Informative';
			growl.info(message);
		}

		function error(msg) {
			var message = getErrorMessage(msg);
			growl.error(message);
		}

		function getSuccessMessage(msg) {
			var message = '';
			switch (msg) {
				case 'save':
					message = CONSTANTS.MESSAGES.SUCCESS.SAVE;
					break;
				case 'update':
					message = CONSTANTS.MESSAGES.SUCCESS.UPDATE;
					break;
				case 'delete':
					message = CONSTANTS.MESSAGES.SUCCESS.DELETE;
					break;
				default:
					message = msg;
					break;
			}

			return message;
		}

		function getErrorMessage(msg) {
			var message = '';
			switch (msg) {
				case 'save':
					message = CONSTANTS.MESSAGES.ERROR.SAVE;
					break;
				case 'update':
					message = CONSTANTS.MESSAGES.ERROR.UPDATE;
					break;
				case 'delete':
					message = CONSTANTS.MESSAGES.ERROR.DELETE;
					break;
				case 'fatal':
					message = CONSTANTS.MESSAGES.ERROR.FATAL;
					break;
				default:
					message = msg;
					break;
			}

			return message;
		}

		return notificationService;
	}
}());