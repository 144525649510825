(function () {
	'use strict';

	angular
		.module('e74intranet.employee')
		.factory('EmailConfigurationService', EmailConfigurationService);

	EmailConfigurationService.$inject = ['$http'];

	function EmailConfigurationService($http) {
		var root = 'api/emailConfigurations';
		var emailConfigurationService = {
			getEmailConfiguration: getEmailConfiguration,
			create: create,
			update: update
		};

		function getEmailConfiguration() {
			return $http.get(root);
		}

		function create(model) {
			return $http.post(root, model);
		}

		function update(model) {
			return $http.put(root, model);
		}

		return emailConfigurationService;
	}
}());


