(function () {
	'use strict';

	angular
        .module('e74intranet.security')
        .factory('MenuItemService', MenuItemService);

	MenuItemService.$inject = ['$filter', '$http', 'localStorageService'];

	function MenuItemService($filter, $http, localStorageService) {
		var root = 'api/menuItems';
		var menuItemService = {
			getAll: getAll,
			getAllByEmail: getAllByEmail,
			getMenuItems: getMenuItems,
			getMenuItemLabel: getMenuItemLabel,
			update: update,
            swapOrder: swapOrder
		};
				
		function getAll() {
			return $http.get(root);
		}

		function getAllByEmail(email) {
			return $http.get(root + '/' + email + '/permissions');			
		}

		function getMenuItems() {
			return $http.get(root + '/menuItems');
		}

		function getMenuItemLabel(url) {
			var permissionStr = localStorageService.get('permissions');

			if (!permissionStr) return null;
			
			var permissions = JSON.parse(permissionStr);
			var menuItems = [];
							
			menuItems = $filter('filter')(permissions, { menuItemRouteName: url }, true);

			if (menuItems && menuItems.length > 0) return menuItems[0].menuItemLabel;

			return null;
		}

		function update(model) {
			return $http.put(root, model);
		}

		function swapOrder(menuItemRequest) {
		    return $http.put(root + '/swapOrder', menuItemRequest);
		}

		return menuItemService;
	}
}());