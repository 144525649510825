(function () {
	'use strict';

	angular
        .module('e74intranet.myAccount')
        .config(Config);

	Config.$inject = ['$stateProvider'];

	function Config($stateProvider) {
		var resetPasswordState = {
			name: 'resetPassword',
			url: '/resetPassword?userId&token',
			controller: 'ResetPasswordController',
			controllerAs: 'vm',
			templateUrl: 'wwwroot/app/my-account/reset-password/reset-password.html'
		};

		var forgotPasswordState = {
			name: 'forgotPassword',
			url: '/forgotPassword',
			controller: 'ForgotPasswordController',
			controllerAs: 'vm',
			templateUrl: 'wwwroot/app/my-account/forgot-password/forgot-password.html'
		};

		var newAccountState = {
			name: 'newAccount',
			url: '/newAccount',
			controller: 'NewAccountController',
			controllerAs: 'vm',
			templateUrl: 'wwwroot/app/my-account/new-account/new-account.html'
		};

		var confirmEmailState = {
			name: 'confirmEmail',
			url: '/confirmEmail?userId&token',
			controller: 'ConfirmEmailController',
			controllerAs: 'vm',
			templateUrl: 'wwwroot/app/my-account/confirm-email/confirm-email.html'
		};

		$stateProvider.state(forgotPasswordState);
		$stateProvider.state(resetPasswordState);
		$stateProvider.state(newAccountState);
		$stateProvider.state(confirmEmailState);
	}
}());