(function () {
	'use strict';

	angular
       .module('e74intranet.common')
       .controller('ModalController', ModalController);

	ModalController.$inject = ['$uibModalInstance', 'modelResolved'];
	function ModalController($uibModalInstance, modelResolved) {
		var vm = this;
		vm.title = modelResolved.title;
		vm.content = modelResolved.content;
		vm.okLabel = modelResolved.okLabel;
		vm.cancelLabel = modelResolved.cancelLabel;
		vm.ok = ok;
		vm.cancel = cancel;

		function ok() {			
			$uibModalInstance.close({});
		}

		function cancel() {			
			$uibModalInstance.close('cancel');
		}
	}
}());