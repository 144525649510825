(function () {
	'use strict';

	angular
        .module('e74intranet.common')
        .directive('initNavbar', InitNavbar);	

	function InitNavbar() {
		return {
			restrict: 'E',
			templateUrl: 'wwwroot/app/login/init-navbar.html'
		};
	}
}());