(function () {
	'use strict';

	angular
        .module('e74intranet.event')
        .config(Config);

	Config.$inject = ['$stateProvider'];

	function Config($stateProvider) {
		var callSheetState = {
			name: 'main.callSheet',
			url: '/callSheet',
			controller: 'CallSheetController',
			controllerAs: 'vm',
			templateUrl: 'wwwroot/app/events/call-sheet/call-sheet.html',
			params: { mid: null },
			ncyBreadcrumb: {
				label: '{{ getMenuItemLabel("main.callSheet") }}',
				parent: 'main.dashboard'
			}
		};

		var eventState = {
			name: 'main.event',
			url: '/event',
			controller: 'EventController',
			controllerAs: 'vm',
			templateUrl: 'wwwroot/app/events/event/event.html',
			params: { mid: null, eventCategoryId: 1 },
			ncyBreadcrumb: {
				label: '{{ getMenuItemLabel("main.event") }}',
				parent: 'main.dashboard'
			}
		};

		var eventDetailState = {
			name: 'main.eventDetail',
			url: '/eventDetail/:eventId',
			controller: 'EventDetailController',
			controllerAs: 'vm',
			templateUrl: 'wwwroot/app/events/event-detail/event-detail.html',
			params: { mid: null },
			ncyBreadcrumb: {
				label: 'Event Detail',
				parent: 'main.event'
			}
		};

		var activityState = {
		    name: 'main.activity',
		    url: '/activity',
		    controller: 'EventController',
		    controllerAs: 'vm',
		    templateUrl: 'wwwroot/app/events/event/event.html',
		    params: { mid: null, eventCategoryId: 2 },
		    ncyBreadcrumb: {
		        label: '{{ getMenuItemLabel("main.activity") }}',
		        parent: 'main.dashboard'
		    }
        };

        var activityResidentState = {
            name: 'main.activityResident',
            url: '/activity-resident',
            controller: 'EventResidentController',
            controllerAs: 'vm',
            templateUrl: 'wwwroot/app/events/event/eventResident.html?CategoryID=2',
            params: { mid: null, eventCategoryId: 2 },
            ncyBreadcrumb: {
                label: '{{ getMenuItemLabel("main.activityResident") }}',
                parent: 'main.dashboard'
            }
        };


        var eventResidentState = {
            name: 'main.eventResident',
            url: '/event-resident',
            controller: 'EventResidentController',
            controllerAs: 'vm',
            templateUrl: 'wwwroot/app/events/event/eventResident.html?CategoryID=1',
            params: { mid: null, eventCategoryId: 1 },
            ncyBreadcrumb: {
                label: '{{ getMenuItemLabel("main.eventResident") }}',
                parent: 'main.dashboard'
            }
        };
				
		$stateProvider.state(callSheetState);
        $stateProvider.state(eventState);
        $stateProvider.state(eventResidentState);
		$stateProvider.state(eventDetailState);
        $stateProvider.state(activityState);
        $stateProvider.state(activityResidentState);
	}
}());