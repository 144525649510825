(function () {
	'use strict';

	angular
		.module('e74intranet.employee')
		.factory('AnnouncementService', AnnouncementService);

	AnnouncementService.$inject = ['$http'];

	function AnnouncementService($http) {
		var root = 'api/announcements';
		var announcementService = {
			getAll: getAll,
			getLandingPageAnnouncements: getLandingPageAnnouncements,			
			create: create,
			update: update,
			remove: remove
		};
		
		function getAll(status) {
			var requestUrl = root;

			if (status) requestUrl += '?status=' + status;

			return $http.get(requestUrl);
		}

		function getLandingPageAnnouncements() {
			return $http.get(root + '/landingPage/announcements');
		}		

		function create(model) {
			return $http.post(root, model);
		}

		function update(model) {
			return $http.put(root, model);
		}

		function remove(id) {
			return $http.delete(root + '/' + id);
		}

		return announcementService;
	}
}());


