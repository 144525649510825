(function () {
	'use strict';

	angular
		.module('e74intranet.dashboard')
		.component('announcement', {
			templateUrl: 'wwwroot/app/dashboard/announcement/announcement.html',
			controller: AnnouncementController,
			controllerAs: 'vm'		
		});

	AnnouncementController.$inject = ['$cookies', 'AnnouncementService', 'moment', 'CONSTANTS'];

	function AnnouncementController($cookies, AnnouncementService, moment, CONSTANTS) {
		var vm = this;
		vm.hideSlick = hideSlick;
		
		activate();

		function activate() {
			vm.slickConfig = {
				autoplay: true,
				autoplaySpeed: 10000,
				arrows: false,
				dots: true
			};

			AnnouncementService.getLandingPageAnnouncements()
                .then(getAllSuccess, getAllError);

			function getAllSuccess(response) {
				vm.announcements = response.data;
				vm.isVisible = hasHidingDateExpired();
			}

			function getAllError(){}
		}		
	
		function hideSlick() {													
			$cookies.put('hidingDate', moment().add(CONSTANTS.ANNOUNCEMENTS.HIDE_MINUTES, 'minute').format("YYYY-M-D HH:mm:ss"));
			vm.isVisible = false;			
		}

		function hasHidingDateExpired() {
			vm.today = moment();			
			vm.hidingDate = $cookies.get('hidingDate');
						
			if (!vm.hidingDate) return true;
			else {
				vm.diff = vm.today.diff(vm.hidingDate);				
				if (vm.diff > 0) return true;

				return false;
			}						
		}
	}
}());