(function () {
	'use strict';

	angular
        .module('e74intranet.common')
        .filter('unsafe', UnsafeFilter);

	UnsafeFilter.$inject = ['$sce'];

	function UnsafeFilter($sce) {
		return $sce.trustAsHtml;
	}
}());