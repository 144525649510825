(function () {
	'use strict';

	angular
		.module('e74intranet.myAccount')
		.controller('ResetPasswordController', ResetPasswordController);

	ResetPasswordController.$inject = ['$state', 'UserService', 'ErrorService'];

	function ResetPasswordController($state, UserService, ErrorService) {
		var vm = this;
		vm.message = '';
		vm.resetPassword = resetPassword;
		vm.showResetButton = true;

		function resetPassword() {
			vm.user.userId = $state.params.userId;
			vm.user.token = $state.params.token;

			UserService.resetPassword(vm.user)
				.then(resetPasswordSuccess, resetPasswordFailure);

			function resetPasswordSuccess(response) {
				vm.showResetButton = false;
				vm.message = 'Your password has been reset. You can now log into your account using your new password <a href="#!/login">here.</a>';
				vm.class = 'text-success';
			}
			function resetPasswordFailure(error) {
				vm.message = ErrorService.generateErrorMessageFromArray(error.data.errors);
				vm.class = 'text-danger';
			}
		}		
	}
}());