(function () {
	'use strict';

	angular
        .module('e74intranet.common')
        .filter('color', ColorFilter);

	ColorFilter.$inject = ['$sce'];

	function ColorFilter($sce) {
		return function (value) {
			return $sce.trustAsHtml('<span class="label" style="background-color:' + value + '; width:10px;">' + value + '</span>');
		};
	}
}());