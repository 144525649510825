(function () {
	'use strict';

	angular
        .module('e74intranet.common')
        .controller('LoginController', LoginController);

	LoginController.$inject = ['$state', 'AccessService'];

	function LoginController($state, AccessService) {
		var vm = this;
		vm.login = login;		
		vm.message = '';

		function login() {			
			AccessService.login(vm.email, vm.password)
                .then(loginSuccess, loginFailure);

			function loginSuccess() {				                
                //if (vm.user.profile.name.toLowerCase() == 'resident') {
                    //$state.go('main.dashboardResident', {}, { reload: true });
                //}
                //else {
                    $state.go('main.dashboard', {}, { reload: true });
                //}                
			}

			function loginFailure(response) {
				var res = response.data;
				vm.message = res && res.error_description ? res.error_description : "Internal server error.";
			}
		}		
	}
}());