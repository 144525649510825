(function () {
	'use strict';

	angular
        .module('e74intranet.dashboard')
        .controller('DashboardController', DashboardController);

	DashboardController.$inject = ['$state','$rootScope', 'AccessService'];

	function DashboardController($state,$rootScope, AccessService) {
		var vm = this;
		vm.$onInit = onInit;

		function onInit() {
			$rootScope.isDashboard = true;

            if (!AccessService.hasPermissions($rootScope.permission.BACK_TALK)) {
                $state.go('main.dashboardResident', {}, { reload: true });
            }

			if (AccessService.hasPermissions($rootScope.permission.ANNOUNCEMENTS)) vm.announcement = true;
			if (AccessService.hasPermissions($rootScope.permission.FEATURED_SECTION)) vm.featured = true;
			if (AccessService.hasPermissions($rootScope.permission.ARTICLE)) vm.article = true;
			if (AccessService.hasPermissions($rootScope.permission.BACK_TALK)) vm.backTalk = true;
			if (AccessService.hasPermissions($rootScope.permission.EVENTS)) vm.events = true;
			if (AccessService.hasPermissions($rootScope.permission.SUGGESTION_BOX)) vm.suggestionBox = true;
		}		
	}
}());