(function () {
	'use strict';

	angular
        .module('e74intranet.employee')
        .controller('AnnouncementController', AnnouncementController);

	AnnouncementController.$inject = ['$rootScope', 'AnnouncementService', 'AnnouncementCategoryService', 'ModalService', 'NotificationService', 'AccessService'];

	function AnnouncementController($rootScope, AnnouncementService, AnnouncementCategoryService, ModalService, NotificationService, AccessService) {
        var vm = this;
        vm.selectedItems = [];
        vm.selectedIds = "";
		vm.getAnnouncements = getAnnouncements;
		vm.openModal = openModal;
		vm.deleteRow = deleteRow;		
		
        activate();

        vm.multiDropdownSettings = {
            idProp: 'announcementCategoryId',
            externalIdProp: 'announcementCategoryId',
            displayProp: 'name',
            buttonClasses: 'btn btn-default btn-multi-dropdown'
        };

        vm.multiDropdownEvents = {
            onSelectionChanged: onSelectionChanged,
        };

		function activate() {
			setPermissions();
            getAnnouncements();
        }

        function onSelectionChanged() {
            vm.selectedIds = "";
            angular.forEach(vm.selectedItems, function (item) {
                vm.selectedIds += item.announcementCategoryId + ',';
            });

            if (vm.selectedIds.length > 0) vm.selectedIds = vm.selectedIds.substring(0, vm.selectedIds.length - 1);

            vm.dataSource.read();
        }

		function setPermissions() {
			if (AccessService.hasPermissions($rootScope.permission.CREATE)) vm.create = true;
			if (AccessService.hasPermissions($rootScope.permission.UPDATE)) vm.update = true;
			if (AccessService.hasPermissions($rootScope.permission.DELETE)) vm.delete = true;			
		}
	
		function getAnnouncements() {			
			AnnouncementService.getAll(vm.status)
                .then(getAllSuccess, getAllFailure);

			function getAllSuccess(response) {
				vm.announcements = response.data;				
			}

			function getAllFailure() { }
        }

		function openModal(action, announcement) {
			var modalInstance = ModalService.createModal({
				templateUrl: 'wwwroot/app/employee/announcement/announcement-add-edit.html',
				controller: 'AnnouncementAddEditController',
				size: 'lg',
				resolve: action === 'edit' ? announcement : null
			});

			modalInstance.then(modalInstanceResponse, modalInstanceError);

			function modalInstanceResponse(response) {
				if (response !== "cancel") {
					activate();
				}
			}

			function modalInstanceError() { }
		}

		function deleteRow(announcement) {
			var modalPromptInstance = ModalService.createModalPrompt({
				title: 'Delete Announcement',
				content: 'The selected announcement will be deleted. Do you want to continue?'
			});

			modalPromptInstance.then(modalPromptResponse, modalPromptInstanceError);

			function modalPromptResponse(response) {
				if (response === 'cancel') return;

				AnnouncementService.remove(announcement.announcementId)
                    .then(removeSuccess, removeFailure);

				function removeSuccess() {
					NotificationService.success('delete');
					getAnnouncements();
				}

				function removeFailure(){}
			}

			function modalPromptInstanceError() { }
		}
	}
}());