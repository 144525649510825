(function () {
	'use strict';

	angular
        .module('e74intranet.catalogs')
        .directive('multipleEmails', MultipleEmails);	

	function MultipleEmails() {
		return {
			require: 'ngModel',
			link: link
		};

		function link(scope, element, attrs, ctrl) {
			ctrl.$parsers.unshift(function(viewValue) {
  
				var emails = viewValue.split(',');				
				var re = /\S+@\S+\.\S+/; 
              				
				var validityArr = emails.map(function(str){
					return re.test(str.trim());
				}); 
				
				var atLeastOneInvalid = false;
				angular.forEach(validityArr, function(value) {
					if(value === false)
						atLeastOneInvalid = true; 
				}); 
				if(!atLeastOneInvalid) { 
					// ^ all I need is to call the angular email checker here, I think.
					ctrl.$setValidity('multipleEmails', true);
					return viewValue;
				} else {
					ctrl.$setValidity('multipleEmails', false);
					return undefined;
				}				
			});
		}
	}
}());