(function () {
	'use strict';

	angular
        .module('e74intranet.security')
        .config(Config);

	Config.$inject = ['$stateProvider'];

	function Config($stateProvider) {
		var menuItemState = {
			name: 'main.menuItem',
			url: '/menuItem',
			controller: 'MenuItemController',
			controllerAs: 'vm',
			templateUrl: 'wwwroot/app/security/menu-item/menu-item.html',
			params: { mid: null },
			ncyBreadcrumb: {
				label: '{{ getMenuItemLabel("main.menuItem") }}',
				parent: 'main.dashboard'
			}
		};
		
		$stateProvider.state(menuItemState);
	}
}());