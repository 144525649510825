(function () {
	'use strict';

	angular
        .module('e74intranet.main')
        .controller('MainController', MainController);

	MainController.$inject = ['$state', 'AccessService', 'MenuItemService', 'UserService', 'ModalService'];

	function MainController($state, AccessService, MenuItemService, UserService, ModalService) {
	    var vm = this;
	    vm.editProfile = editProfile;
		vm.logout = logout;

		activate();

		function activate() {			
            vm.isPublicAccess = AccessService.isPublicAccess();

            if (location.protocol != 'https:' && location.href.indexOf('localhost') < 0) {
                location.replace('https:' + window.location.href.substring(window.location.protocol.length));
            }

			MenuItemService.getAll()
				.then(getAllSuccess, getAllFailure);

			function getAllSuccess(response) {
				vm.menuItems = response.data;
			}
			function getAllFailure() { }

			UserService.getCurrentUser()
                .then(getCurrentUserSuccess, getCurrentUserFailure);

			function getCurrentUserSuccess(response) {
			    vm.user = response.data;
			}
            function getCurrentUserFailure() { }
		}

		function editProfile(action) {
		    var modalInstance = ModalService.createModal({
		        templateUrl: 'wwwroot/app/main/navbar/user-profile-add-edit.html',
		        controller: 'UserProfileAddEditController',
		        size: 'md',
                resolve: action === 'edit' ? vm.user : null
		    });

		    modalInstance.then(modalInstanceResponse, modalInstanceError);

		    function modalInstanceResponse(response) {
		        if (response !== "cancel") {
                    activate();
		        }
		    }

            function modalInstanceError() { }
		}

		function logout() {
			AccessService.logout();				
			AccessService.login()
				.then(loginSuccess, loginFailure);

			function loginSuccess() {
				$state.go("main.dashboard", {}, { reload: true });
			}

			function loginFailure(response) {
				console.log(response);
			}
		}
	}
}());