(function () {
    'use strict';

    angular
        .module('e74intranet.main')
        .config(Config);

    Config.$inject = ['$stateProvider'];

    function Config($stateProvider) {
        var webappState = {
            abstract: true,
            name: 'webapp',
            url: '/webapp',
            controller: 'WebappController',
            controllerAs: 'vm',
            templateUrl: 'wwwroot/app/webapp/webapp.html'
        };

        $stateProvider.state(webappState);
    }
}());