(function () {
	'use strict';

	angular
		.module('e74intranet.catalogs')
		.factory('ProfileService', ProfileService);

	ProfileService.$inject = ['$http'];

	function ProfileService($http) {
		var root = 'api/profiles';
		var profileService = {
			getAll: getAll,
			getPagedProfiles: getPagedProfiles,
			getPermissions: getPermissions,
			getById: getById,
			create: create,
			update: update,
			remove: remove
		};

		function getAll() {
			return $http.get(root);
		}
		
		function getPagedProfiles(page, pageSize, ascending, orderBy, searchText) {
			return $http.get(root + '/paging?page=' + (page || 1) +
									'&pageSize=' + (pageSize || 10) +
									'&ascending=' + (ascending == undefined ? true : ascending) +
									'&orderBy=' + (orderBy || '') +									
									'&search=' + (searchText || ''));
		}

		function getPermissions(id) {
			return $http.get(root + '/' + id + '/permissions');
		}

		function getById(id) {
			return $http.get(root + '/' + id);
		}

		function create(model) {
			return $http.post(root, model);
		}

		function update(model) {
			return $http.put(root, model);
		}

		function remove(id) {
			return $http.delete(root + '/' + id);
		}

		return profileService;
	}
}());


