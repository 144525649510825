(function () {
	'use strict';

	angular
        .module('e74intranet.common')
        .factory('AccessService', AccessService);

	AccessService.$inject = ['$http', '$q', '$filter', '$rootScope', '$cookies', 'authService', 'MenuItemService', 'localStorageService', 'CONSTANTS'];

	function AccessService($http, $q, $filter, $rootScope, $cookies, authService, MenuItemService, localStorageService, CONSTANTS) {
		var accessService = {
			login: login,
			logout: logout,
			refreshToken: refreshToken,
			isAuthenticated: isAuthenticated,
			isAuthorized: isAuthorized,
			isPublicAccess: isPublicAccess,
			hasPermissions: hasPermissions,						
			getToken: getToken					
		};

		var LOGIN_URL = CONSTANTS.CONFIG.LOGIN_URL;
				
		function login(username, password) {
			var deferred = $q.defer();

			var data = "grant_type=password&client_id=" + CONSTANTS.CONFIG.CLIENT_ID + "&sessionId=" + generateSessionId();

			if (username) data = data + "&username=" + username;
			if (password) data = data + "&password=" + password;
						
			$http.post(LOGIN_URL, data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
				.then(loginSuccess, loginFailure);

			function loginSuccess(response) {				
				setAuthData(response.data)
					.then(setAuthDataSuccess, setAuthDataFailure);

				function setAuthDataSuccess() {
					authService.loginConfirmed();
					deferred.resolve(response);
				}
				function setAuthDataFailure() {
					deferred.reject();
				}
			}

			function loginFailure(response) {
				deferred.reject(response);
			}
			return deferred.promise;
		}
		
		function logout() {
			resetStoredInformation();
			authService.loginCancelled();
		}

		function setAuthData(authData) {			
			var deferred = $q.defer();

			removeAuthData();

			localStorageService.set('authorizationData', {
				token: authData.access_token,
				refreshToken: authData.refresh_token,
				userEmail: authData.userEmail,
				sessionId: authData.sessionId,
				isPublicAccess: authData.isPublicAccess				
			});		

			// We get all the permissions the user has, so then we'll need to store them along with auth data in localStorage
			getPermissionsByEmail(authData.userEmail)
				.then(getPermissionsByEmailSuccess, getPermissionsByEmailFailure);

			function getPermissionsByEmailSuccess(permissions) {												
				localStorageService.set('permissions', JSON.stringify(permissions));
				deferred.resolve(authData);
			}

			function getPermissionsByEmailFailure() {
				deferred.reject();
			}

			return deferred.promise;
		}
		
		function refreshToken() {			
			var deferred = $q.defer();

			var authData = localStorageService.get('authorizationData');

			if (authData) {
				var data = "grant_type=refresh_token&sessionId=" + authData.sessionId + "&refresh_token=" + authData.refreshToken + "&client_id=" + CONSTANTS.CONFIG.CLIENT_ID;

				localStorageService.remove('authorizationData');				

				$http.post(LOGIN_URL, data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
					.then(refreshSuccess, refreshFailure);
			}

			function refreshSuccess(response) {
				setAuthData(response.data)
					.then(setAuthDataSuccess, setAuthDataFailure);

				function setAuthDataSuccess() {
					authService.loginConfirmed();
					deferred.resolve();
				}
				function setAuthDataFailure() {
					deferred.reject();
				}
			}

			function refreshFailure(response) {				
				deferred.reject(response);
			}

			return deferred.promise;
		}

		function fillAuthData() {			
			var authData = localStorageService.get('authorizationData');
			if (authData) {						
			}			
		}

		function getAuthData() {
			return localStorageService.get('authorizationData');
		}

		function getPermissions() {
			var permissions = localStorageService.get('permissions');
			
			if (!permissions) return [];

			return JSON.parse(permissions);
		}	

		function getPermissionsByEmail(email) {
			var deferred = $q.defer();

			if (!email) {			
				email = getEmail();
			}

			MenuItemService.getAllByEmail(email)
                .then(getAllByEmailSuccess);			

			function getAllByEmailSuccess(response) {				
				deferred.resolve(response.data);
			}

			return deferred.promise;
		}

		function isAuthenticated() {
			var authData = getAuthData();
			if (authData) return true;
			return false;
		}

		function isPublicAccess() {
			var authData = getAuthData();

			if (!authData) return true;

			return (authData.isPublicAccess === "true");
		}

		function isAuthorized(url, mid) {						
			var permissions = getPermissions();

			var menuItems = $filter('filter')(permissions, {
				menuItemId: mid
			}, true);

			return (menuItems.length > 0 || mid === null);
		}

		function hasPermissions(permissionId) {			
			var permissions = getPermissions();
			var mid = $rootScope.mid;
			var url = $rootScope.url;
			var menuItems = [];

			// If the menuItem id is given, we validate it, if not, we validate the url value
			if (mid) {
				menuItems = $filter('filter')(permissions, { menuItemId: mid, permissionTypeId: parseInt(permissionId) }, true);
			} else {
				// First off, we format the url value to fit with the route format
				var menuItemRouteName = url.replace('/main/', 'main.');
				menuItems = $filter('filter')(permissions, { menuItemRouteName: menuItemRouteName, permissionTypeId: parseInt(permissionId) }, true);
			}

			return (menuItems.length > 0);		
		}

		// Function that resets the permissions and user information from memory/localStorage
		function resetStoredInformation() {			
			removeAuthData();
			resetCookies();
		}		
		
		function removeAuthData() {
			localStorageService.remove('authorizationData');
			localStorageService.remove('permissions');	
		}

		function resetCookies() {
			$cookies.remove('hidingDate');
		}

		function getToken() {
			var authData = getAuthData();			
			return authData ? authData.token : "";
		}

		function getEmail() {
			var authData = getAuthData();
			return authData ? authData.userEmail : "";
		}

		function generateSessionId() {
			return Math.random().toString(36).substring(2) + (new Date()).getTime().toString(36);
		}				

		return accessService;
	}
}());