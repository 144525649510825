(function () {
	'use strict';

	angular
       .module('e74intranet.catalogs')
       .controller('PositionAddEditController', PositionAddEditController);

	PositionAddEditController.$inject = ['$scope', '$uibModalInstance', 'modelResolved', 'PositionService', 'NotificationService'];

	function PositionAddEditController($scope, $uibModalInstance, modelResolved, PositionService, NotificationService) {
		var vm = this;		
		vm.position = angular.copy(modelResolved);
		vm.isNew = !vm.position;
		vm.title = (!vm.isNew ? 'Edit' : 'New') + ' Position';
		vm.save = save;
		vm.cancel = cancel;				

		function save() {
			if (vm.position.positionId) {
				PositionService.update(vm.position)
					.then(updateSuccess)
					.catch(failure);
			} else {
				PositionService.create(vm.position)
					.then(saveSuccess)
					.catch(failure);
			}

			function saveSuccess() {
				NotificationService.success('save');
				$uibModalInstance.close();
			}
			function updateSuccess() {
				NotificationService.success('update');
				$uibModalInstance.close();
			}
			function failure() {				
			}
		}

		function cancel() {
			$uibModalInstance.close('cancel');
		}
	}
}());