(function () {
    'use strict';

    angular
        .module('e74intranet.webapp')
        .controller('WebappController', WebappController);

    WebappController.$inject = ['$state', 'AccessService', 'MenuItemService', 'UserService', 'ModalService'];

    function WebappController($state, AccessService, MenuItemService, UserService, ModalService) {
    }
}());