(function () {
	'use strict';

	angular
		.module('e74intranet.employee')
		.factory('GeneralConfigurationService', GeneralConfigurationService);

	GeneralConfigurationService.$inject = ['$http'];

	function GeneralConfigurationService($http) {
		var root = 'api/generalConfigurations';
		var generalConfigurationService = {
			getGeneralConfiguration: getGeneralConfiguration,
			getMessages: getMessages,
			create: create,
			update: update
		};

		function getGeneralConfiguration() {
			return $http.get(root);
		}

		function getMessages() {
			return $http.get(root + '/messages');
		}

		function create(model) {
			return $http.post(root, model);
		}

		function update(model) {
			return $http.put(root, model);
		}

		return generalConfigurationService;
	}
}());


