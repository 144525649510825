(function () {
	'use strict';

	angular
		.module('e74intranet.catalogs')
		.factory('EventCategoryService', EventCategoryService);

	EventCategoryService.$inject = ['$http'];

	function EventCategoryService($http) {
		var root = 'api/eventCategories';
		var eventCategoryService = {
		    getAll: getAll,
		    getSelectedEventCategories: getSelectedEventCategories,
			getPagedEventCategories: getPagedEventCategories,
			getAllForParentDropdown: getAllForParentDropdown,
			create: create,
			update: update,
			remove: remove
		};

		function getAll() {
			return $http.get(root);
		}

		function getSelectedEventCategories(ids) {
		    ids = (ids && ids.length > 0) ? ids : "0";
		    return $http.get(root + '/' + ids + '/selected');
		}

		function getPagedEventCategories(page, pageSize, ascending, orderBy, searchText) {
			return $http.get(root + '/paging?page=' + (page || 1) +
									'&pageSize=' + (pageSize || 10) +
									'&ascending=' + (ascending == undefined ? true : ascending) +
									'&orderBy=' + (orderBy || '') +
									'&search=' + (searchText || ''));
		}

		function getAllForParentDropdown(id) {
			return $http.get(root + '/' + id + '/parent');
		}

		function create(model) {
			return $http.post(root, model);
		}

		function update(model) {
			return $http.put(root, model);
		}

		function remove(id) {
			return $http.delete(root + '/' + id);
		}

		return eventCategoryService;
	}
}());


