(function () {
    'use strict';

    angular
        .module('e74intranet.dashboard')
        .controller('DashboardResidentController', DashboardResidentController);

    DashboardResidentController.$inject = ['$rootScope', 'AccessService'];

    function DashboardResidentController($rootScope, AccessService) {
        var vm = this;
        vm.$onInit = onInit;

        function onInit() {
            $rootScope.isDashboard = true;

            //if (AccessService.hasPermissions($rootScope.permission.ANNOUNCEMENTS)) vm.announcement = true;           
        }
    }
}());