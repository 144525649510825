(function () {
	'use strict';

	angular
       .module('e74intranet.catalogs')
       .controller('ProfileAddEditController', ProfileAddEditController);

	ProfileAddEditController.$inject = ['$uibModalInstance', 'modelResolved', 'ProfileService', 'NotificationService', 'ModalService'];

	function ProfileAddEditController($uibModalInstance, modelResolved, ProfileService, NotificationService, ModalService) {
		var vm = this;
		vm.profile = angular.copy(modelResolved);
		vm.isNew = !vm.profile;
		vm.title = (!vm.isNew ? 'Edit' : 'New') + ' Profile';
		vm.save = save;
		vm.cancel = cancel;
		vm.openModal = openModal;

		activate();

		function activate() {
			if (vm.profile && vm.profile.profileId) {
				ProfileService.getPermissions(vm.profile.profileId)
					.then(getPermissionsSuccess, getPermissionsFailure);
			}

			function getPermissionsSuccess(response) {
				vm.profile.menuItems = response.data;
			}

			function getPermissionsFailure(){}
		}
	
		function openModal() {
			var modalInstance = ModalService.createModal({
				templateUrl: 'wwwroot/app/catalogs/profiles/permission.html',
				controller: 'PermissionController',
				resolve: vm.profile
			});
			
			modalInstance.then(modalInstanceResponse, modalInstanceError);
			
			function modalInstanceResponse(response) {
				if (response === 'cancel') return;

				if (!vm.profile) {
					vm.profile = {
						menuItems: []
					};
				}

				vm.profile.menuItems = response;
			}

			function modalInstanceError() { }
		}

		function save() {
			if (vm.profileForm.$valid) {
				if (vm.profile.profileId) {
					ProfileService.update(vm.profile)
                        .then(updateSuccess)
						.catch(failure);
				} else {
					if (!vm.profile.menuItems) {
						NotificationService.warning('It is mandatory to add permissions to the selected profile.');
						return;
					}
					ProfileService.create(vm.profile)
                        .then(saveSuccess)
						.catch(failure);
				}
			}

			function saveSuccess() {
				NotificationService.success('save');
				$uibModalInstance.close();
			}
			function updateSuccess() {
				NotificationService.success('update');
				$uibModalInstance.close();
			}
			function failure(){}		
		}

		function cancel() {
			$uibModalInstance.close('cancel');
		}
	}
}());