(function () {
	'use strict';

	angular
        .module('e74intranet.catalogs')
        .controller('EventCategoryController', EventCategoryController);

	EventCategoryController.$inject = ['EventCategoryService', 'NotificationService', 'ModalService', 'CONSTANTS'];

	function EventCategoryController(EventCategoryService, NotificationService, ModalService, CONSTANTS) {
		var vm = this;
		vm.pageNumber = CONSTANTS.PAGINATION.DEFAULT_PAGE_NUMBER;
		vm.pageSize = CONSTANTS.PAGINATION.DEFAULT_PAGE_SIZE;
		vm.eventCategory = {};
		vm.loadGrid = loadGrid;
		vm.openModal = openModal;
		vm.deleteRow = deleteRow;
		vm.gridOptions = {};

		activate();

		vm.gridOptions = {
		    columnDefs: [
                { field: 'name', name: 'Name', displayName: 'Name' },
				{ field: 'color', name: 'Color', displayName: 'Color', cellTemplate: '<div class="ui-grid-cell-contents" ng-bind-html="grid.getCellValue(row, col) | color"></div>' }
			],
			onRegisterApi: function (gridApi) {
				gridApi.pagination.on.paginationChanged(null, function (newPage, pageSize) {
					vm.pageNumber = newPage;
					vm.pageSize = pageSize;
					loadGrid();
				});
				gridApi.core.on.sortChanged(null, function (grid, sortColumns) {
					if (sortColumns.length > 0) {
						vm.orderBy = sortColumns[0].name;
						vm.ascending = sortColumns[0].sort.direction === 'asc' ? true : false;
					}
					loadGrid();
				});
				gridApi.selection.on.rowSelectionChanged(null, function (row) {
					vm.eventCategory = [];
					vm.disabled = true;
					if (row.isSelected) {
						vm.eventCategory = row.entity;
						vm.disabled = false;
					}
				});
			}
		};

		function activate() {
			vm.disabled = true;
			loadGrid();
		}

		function loadGrid() {
			EventCategoryService.getPagedEventCategories(vm.pageNumber, vm.pageSize, vm.ascending, vm.orderBy, vm.searchText)
                .then(getPagedEventCategoriesSuccess, getPagedEventCategoriesFailure);

			function getPagedEventCategoriesSuccess(response) {
				vm.gridOptions.data = response.data.results;
				vm.gridOptions.totalItems = response.data.totalItems;
			}

			function getPagedEventCategoriesFailure(){}
		}

		function openModal(action) {
			var modalInstance = ModalService.createModal({
				templateUrl: 'wwwroot/app/catalogs/event-categories/event-category-add-edit.html',
				controller: 'EventCategoryAddEditController',
				resolve: action === 'edit' ? vm.eventCategory : null
			});

			modalInstance.then(modalInstanceResponse, modalInstanceError);

			function modalInstanceResponse(response) {
				if (response !== "cancel") {
					activate();
				}
			}

			function modalInstanceError() { }
		}

		function deleteRow() {
			var modalPromptInstance = ModalService.createModalPrompt({
				title: 'Delete category',
				content: 'The selected category will be deleted. Do you want to continue?'
			});

			modalPromptInstance.then(modalPromptResponse, modalPromptInstanceError);

			function modalPromptResponse(response) {
				if (response === 'cancel') return;

				EventCategoryService.remove(vm.eventCategory.eventCategoryId)
                    .then(removeSuccess, removeFailure);

				function removeSuccess() {
					NotificationService.success('delete');
					activate();
				}

				function removeFailure(){}
			}

			function modalPromptInstanceError() { }
		}
	}
}());