(function () {
	'use strict';

	angular
        .module('e74intranet.main')
        .directive('navbar', Navbar);

	function Navbar() {
		return {
			restrict: 'E',
			templateUrl: 'wwwroot/app/main/navbar/navbar.html'
		};
	}
}());