(function () {
	'use strict';

	angular
        .module('e74intranet.catalogs')
        .config(Config);

	Config.$inject = ['$stateProvider'];

	function Config($stateProvider) {
		var articleCategoryState = {
			name: 'main.articleCategory',
			url: '/articleCategory',
			controller: 'ArticleCategoryController',
			controllerAs: 'vm',
			templateUrl: 'wwwroot/app/catalogs/article-categories/article-category.html',
			params: { mid: null },
			ncyBreadcrumb: {
				label: '{{ getMenuItemLabel("main.articleCategory") }}',
				parent: 'main.dashboard'
			}
		};
	
		var departmentState = {
			name: 'main.department',
			url: '/department',
			controller: 'DepartmentController',
			controllerAs: 'vm',
			templateUrl: 'wwwroot/app/catalogs/departments/department.html',
			params: { mid: null },
			ncyBreadcrumb: {
				label: '{{ getMenuItemLabel("main.department") }}',
				parent: 'main.dashboard'
			}
		};

		var emailConfigurationState = {
			name: 'main.emailConfiguration',
			url: '/emailConfiguration',
			controller: 'EmailConfigurationController',
			controllerAs: 'vm',
			templateUrl: 'wwwroot/app/catalogs/email-configuration/email-configuration.html',
			params: { mid: null },
			ncyBreadcrumb: {
				label: '{{ getMenuItemLabel("main.emailConfiguration") }}',
				parent: 'main.dashboard'
			}
		};
		var eventCategoryState = {
			name: 'main.eventCategory',
			url: '/eventCategory',
			controller: 'EventCategoryController',
			controllerAs: 'vm',
			templateUrl: 'wwwroot/app/catalogs/event-categories/event-category.html',
			params: { mid: null },
			ncyBreadcrumb: {
				label: '{{ getMenuItemLabel("main.eventCategory") }}',
				parent: 'main.dashboard'
			}
		};
		var generalConfigurationState = {
			name: 'main.generalConfiguration',
			url: '/generalConfiguration',
			controller: 'GeneralConfigurationController',
			controllerAs: 'vm',
			templateUrl: 'wwwroot/app/catalogs/general-configuration/general-configuration.html',
			params: { mid: null },
			ncyBreadcrumb: {
				label: '{{ getMenuItemLabel("main.generalConfiguration") }}',
				parent: 'main.dashboard'
			}
		};

		var positionState = {
			name: 'main.position',
			url: '/position',
			controller: 'PositionController',
			controllerAs: 'vm',
			templateUrl: 'wwwroot/app/catalogs/positions/position.html',
			params: { mid: null },
			ncyBreadcrumb: {
				label: '{{ getMenuItemLabel("main.position") }}',
				parent: 'main.dashboard'
			}
		};

		var profileState = {
			name: 'main.profile',
			url: '/profile',
			controller: 'ProfileController',
			controllerAs: 'vm',
			templateUrl: 'wwwroot/app/catalogs/profiles/profile.html',
			params: { mid: null },
			ncyBreadcrumb: {
				label: '{{ getMenuItemLabel("main.profile") }}',
				parent: 'main.dashboard'
			}
		};

		var specialityState = {
			name: 'main.speciality',
			url: '/speciality',
			controller: 'SpecialityController',
			controllerAs: 'vm',
			templateUrl: 'wwwroot/app/catalogs/specialities/speciality.html',
			params: { mid: null },
			ncyBreadcrumb: {
				label: '{{ getMenuItemLabel("main.speciality") }}',
				parent: 'main.dashboard'
			}
		};

		var suggestionStatusState = {
			name: 'main.suggestionStatus',
			url: '/suggestionStatus',
			controller: 'SuggestionStatusController',
			controllerAs: 'vm',
			templateUrl: 'wwwroot/app/catalogs/suggestion-status/suggestion-status.html',
			params: { mid: null },
			ncyBreadcrumb: {
				label: '{{ getMenuItemLabel("main.suggestionStatus") }}',
				parent: 'main.dashboard'
			}
		};

		var userState = {
			name: 'main.user',
			url: '/user',
			controller: 'UserController',
			controllerAs: 'vm',
			templateUrl: 'wwwroot/app/catalogs/users/user.html',
			params: { mid: null },
			ncyBreadcrumb: {
				label: '{{ getMenuItemLabel("main.user") }}',
				parent: 'main.dashboard'
			}
		};				

		$stateProvider.state(articleCategoryState);		
		$stateProvider.state(departmentState);
		$stateProvider.state(emailConfigurationState);
		$stateProvider.state(eventCategoryState);
		$stateProvider.state(generalConfigurationState);
		$stateProvider.state(positionState);		
		$stateProvider.state(profileState);
		$stateProvider.state(specialityState);
		$stateProvider.state(suggestionStatusState);
		$stateProvider.state(userState);		
	}
}());