(function () {
	'use strict';

	angular
       .module('e74intranet.catalogs')
       .controller('MenuItemAddEditController', MenuItemAddEditController);

	MenuItemAddEditController.$inject = ['$scope', '$uibModalInstance', 'modelResolved', 'MenuItemService', 'NotificationService'];

	function MenuItemAddEditController($scope, $uibModalInstance, modelResolved, MenuItemService, NotificationService) {
		var vm = this;		
		vm.menuItem = angular.copy(modelResolved);		
		vm.title = 'Edit Menu Item';
		vm.save = save;
		vm.cancel = cancel;		

		function save() {
			if (vm.menuItemForm.$valid) {			
				MenuItemService.update(vm.menuItem)
					.then(updateSuccess)
					.catch(failure);			
			}
		
			function updateSuccess() {
				NotificationService.success('update');
				$uibModalInstance.close();
			}
			function failure() { }					
		}

		function cancel() {
			$uibModalInstance.close('cancel');
		}
	}
}());