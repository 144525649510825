(function () {
	'use strict';

	angular
       .module('e74intranet.catalogs')
       .controller('PermissionController', PermissionController);

	PermissionController.$inject = ['$filter', '$uibModalInstance', 'modelResolved', 'ProfileService', 'NotificationService'];
	function PermissionController($filter, $uibModalInstance, modelResolved, ProfileService, NotificationService) {
		var vm = this;
		vm.profile = angular.copy(modelResolved);
		vm.title = 'Permissions Management';
		vm.save = save;
		vm.cancel = cancel;

		activate();

		function activate() {
			if (vm.profile && vm.profile.menuItems && vm.profile.menuItems.length > 0) {
				vm.permissions = vm.profile.menuItems;
			} else {
				ProfileService.getPermissions(modelResolved ? (modelResolved.profileId ? modelResolved.profileId : 0) : 0)
                    .then(getPermissionsSuccess, getPermissionsFailure);               
			}

			function getPermissionsSuccess(response) {
				vm.permissions = response.data;
			}

			function getPermissionsFailure() { }
		}

		function save() {			
			var menuItemsIndeterminate = $filter('filter')(vm.permissions, { indeterminate: true });
			var menuItemsSelected = $filter('filter')(vm.permissions, { selected: true });
			var isValid = menuItemsSelected.length > 0 || menuItemsIndeterminate.length > 0;

			if (!isValid) {
				NotificationService.warning('It is mandatory to add permissions to the selected profile.');
				return;
			}

			$uibModalInstance.close(vm.permissions);
		}

		function cancel() {
			$uibModalInstance.close('cancel');
		}
	}
}());