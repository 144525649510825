(function () {
	'use strict';

	angular
		.module('e74intranet.employee')
		.factory('TestimonyService', TestimonyService);

	TestimonyService.$inject = ['$http'];

	function TestimonyService($http) {
		var root = 'api/testimonies';
		var testimonyService = {
			getLandingPageTestimonies: getLandingPageTestimonies,
			getPagedTestimonies: getPagedTestimonies,
			create: create,
			update: update,
			remove: remove
		};

		function getLandingPageTestimonies() {
			return $http.get(root + '/landingPage/testimonies');
		}

		function getPagedTestimonies(page, pageSize, ascending, orderBy, searchText) {
			return $http.get(root + '/paging?page=' + (page || 1) +
									'&pageSize=' + (pageSize || 10) +
									'&ascending=' + (ascending == undefined ? true : ascending) +
									'&orderBy=' + (orderBy || '') +
									'&search=' + (searchText || ''));
		}

		function create(model) {
			return $http.post(root, model);
		}

		function update(model) {
			return $http.put(root, model);
		}

		function remove(id) {
			return $http.delete(root + '/' + id);
		}

		return testimonyService;
	}
}());


