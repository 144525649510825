(function () {
	'use strict';

	angular
       .module('e74intranet.employee')
       .controller('ContactDirectoryAddEditController', ContactDirectoryAddEditController);

	ContactDirectoryAddEditController.$inject = ['$scope', '$uibModalInstance', 'CONSTANTS', 'modelResolved', 'ContactService', 'DepartmentService', 'NotificationService'];

	function ContactDirectoryAddEditController($scope, $uibModalInstance, CONSTANTS, modelResolved, ContactService, DepartmentService, NotificationService) {
		var vm = this;
		vm.contact = angular.copy(modelResolved);
		vm.isNew = !vm.contact;
		vm.title = (!vm.isNew ? 'Edit' : 'New') + ' Contact';
		vm.save = save;
		vm.cancel = cancel;
		vm.isNewFile = false;
		var saveUrl = "api/resources/uploadImage";

		activate();

		function activate() {
		    var resourceId;

		    if (vm.contact && vm.contact.resource) {
		        vm.selectedFile = vm.contact.resource.fileName;
		        resourceId = vm.contact.resource.resourceId;
		    }

		    configureKendoUploader(resourceId);
			getDepartments();
		}
					
		function getDepartments() {
			DepartmentService.getAll()
				.then(getAllSuccess, getAllFailure);

			function getAllSuccess(response) {					
				vm.departments = response.data;					
			}

			function getAllFailure() { }
		}

		function configureKendoUploader(resourceId) {
		    if (resourceId) saveUrl = saveUrl + "?resourceId=" + resourceId;

		    vm.uploadOptions = {
		        multiple: false,
		        showFileList: false,
		        async: {
		            autoUpload: true,
		            saveUrl: saveUrl,
		            removeUrl: ""
		        },
		        localization: {
		            headerStatusUploaded: "The image was saved successfully",
		            select: "Select File"
		        },
		        upload: onUpload,
		        error: onError,
		        success: onSuccess,
		        select: onSelect,
		        clear: onClear
		    };
		}

		function onUpload() {
		    vm.isUploading = true;
		    $scope.$apply();
		}

		function onError() {
		    vm.isUploading = false;
		    NotificationService.error('An error has occurred while uploading the image. Please contact your system administrator.');
		    $scope.$apply();
		}

		function onSuccess(e) {
		    if (!vm.contact) {
		        vm.contact = { resource: { resourceId: e.response, fileName: vm.selectedFile } };
		    } else {
		        vm.contact.resource = { resourceId: e.response, fileName: vm.selectedFile };
		    }
		    vm.isNewFile = true;
		    vm.isUploading = false;
		    vm.uploader.options.async.saveUrl = "api/resources/uploadImage?resourceId=" + e.response;
		    $scope.$apply();
		}

		function onSelect(e) {
		    var file = e.files[0];

		    if (file.extension.toLowerCase() != ".png" && file.extension.toLowerCase() != ".gif" && file.extension.toLowerCase() != ".jpg" && file.extension.toLowerCase() != ".jpeg") {
		        NotificationService.warning('The selected image is not valid. Supported image are *.png, *.gif, *.jpg, *.jpeg');
		        e.preventDefault();
		        return;
		    }

		    $scope.$apply(function () {
		        vm.resource = file;
		        vm.selectedFile = vm.resource.name;
		    });
		}

		function onClear() {
		    vm.isNewFile = false;

		    $scope.$apply(function () {
		        vm.resource = vm.physician ? vm.physician.resource : null;
		        vm.selectedFile = vm.resource ? vm.resource.fileName : "";
		    });
		}

		function save() {
		    if (vm.contactForm.$valid) {
		        if (!vm.contact.resource) {
		            NotificationService.warning('It is mandatory to add a profile image.');
		            return;
		        } else {
		            if (vm.contact.contactId) {
		                ContactService.update(vm.contact)
                            .then(updateSuccess)
                            .catch(failure);
		            } else {
		                ContactService.create(vm.contact)
                            .then(saveSuccess)
                            .catch(failure);
		            }
		        }
			}

			function saveSuccess() {
				NotificationService.success('save');
				$uibModalInstance.close();
			}
			function updateSuccess() {
				NotificationService.success('update');
				$uibModalInstance.close();
			}
			function failure() { }
		}

		function cancel() {
			$uibModalInstance.close('cancel');
		}
	}
}());