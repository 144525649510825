(function () {
    'use strict';

    angular
        .module('e74intranet.main')
        .controller('UserProfileAddEditController', UserProfileAddEditController);

    UserProfileAddEditController.$inject = ['$uibModalInstance', 'modelResolved', 'UserService', 'ErrorService', 'NotificationService'];

    function UserProfileAddEditController($uibModalInstance, modelResolved, UserService, ErrorService, NotificationService) {
        var vm = this;
        vm.user = angular.copy(modelResolved);
        vm.isNew = !vm.user;
        vm.title = (!vm.isNew ? 'Edit' : 'New') + ' Profile';
        vm.save = save;
        vm.cancel = cancel;
        vm.resetUserPassword = resetUserPassword;

        activate();

        function activate() {
        }

        function save() {
            if (vm.user && vm.user.id) {
                UserService.updateUserProfile(vm.user)
                    .then(updateSuccess)
                    .catch(failure);
            }

            function saveSuccess() {
                NotificationService.success('save');
                $uibModalInstance.close();
            }
            function updateSuccess() {
                NotificationService.success('update');
                $uibModalInstance.close();
            }
            function failure(error) { }
        }

        function cancel() {
            $uibModalInstance.close('cancel');
        }

        function resetUserPassword() {
            if (vm.user && vm.user.id) {
                UserService.sendPassword(vm.user.email)
                    .then(resetUserPasswordSuccess, resetUserPasswordFailure);
            }

            function resetUserPasswordSuccess(response) {
                vm.message = 'We have sent you an email to reset your password.';
                vm.class = 'text-success';
                vm.emailSent = true;
            }
            function resetUserPasswordFailure(error) {
                vm.message = ErrorService.generateErrorMessageFromArray(error.data.errors);
                vm.class = 'text-danger';
            }
        }
    }
}());