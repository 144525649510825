(function () {
	'use strict';

	angular
		.module('e74intranet.common')
		.directive('hideBreadcrumb', HideBreadCrumb);

	function HideBreadCrumb() {
		return function (scope, element, attrs) {
			function hide() {				
				angular.element(".k-breadcrumbs.k-textbox").css('display', 'none');
			}
			scope.$on('hideBreadcrumb', hide);
		};
	}
}());