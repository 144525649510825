(function () {
	'use strict';

	angular
        .module('e74intranet.common')
        .factory('AuthInterceptor', AuthInterceptor);

	AuthInterceptor.$inject = ['$q', '$injector', '$rootScope', 'blockUI', 'NotificationService', 'ErrorService', 'localStorageService'];

	function AuthInterceptor($q, $injector, $rootScope, blockUI, NotificationService, ErrorService, localStorageService) {
		var bearerService = this;

		bearerService.request = function (config) {			
			blockUI.start();
			config.headers = config.headers || {};
			var authData = localStorageService.get('authorizationData');			

			if (authData) {				
				config.headers.Authorization = 'Bearer ' + authData.token;
			}					
			return config || $q.when(config);
		};

		bearerService.response = function (response) {		
			blockUI.stop();
			return response;
		};

		bearerService.responseError = function (response) {					
			switch (response.status) {			
				case 400:
					var res = response.data;
					if (res && res.error == "invalid_grant") {
						NotificationService.error(response.data.error_description);
					}
					else {
						var message = ErrorService.generateErrorMessageFromArray(res.errors);
						NotificationService.error(message);
					}
					break;
				case 401:
					var accessService = $injector.get('AccessService');
					var authData = localStorageService.get('authorizationData');
					
					if (authData) {						
						accessService.refreshToken()
							.then(refreshTokenSuccess, refreshTokenFailure);						
					} 
					else {
							$rootScope.$emit('forbidden');												
					}
					break;								
				case 404:
					$rootScope.$emit('notFound');
					break;
				case 403:					
					$rootScope.$emit('forbidden');
					break;
				case 500:
					$rootScope.$emit('fatalException');
					break;
				default:
					NotificationService.error('An error has occurred. Please contact your system administrator.');					
					break;
			}

			function refreshTokenSuccess() {			
				return $q.resolve(response);
			}
			function refreshTokenFailure() {				
				$rootScope.$emit('onRevokeToken');
			}
			
			blockUI.stop();
			return $q.reject(response);
		};
		
		return bearerService;
	}
}());