(function () {
	'use strict';

	angular
        .module('e74intranet.employee')
        .controller('SubmitSuggestionController', SubmitSuggestionController);

	SubmitSuggestionController.$inject = ['SuggestionService', 'GeneralConfigurationService'];

	function SubmitSuggestionController(SuggestionService, GeneralConfigurationService) {
		var vm = this;		
		vm.suggestion= {};
		vm.save = save;
		
		activate();

		function activate() {
			getMessages();
		}

		function getMessages() {
			GeneralConfigurationService.getMessages()
				.then(getMessagesSuccess, getMessagesFailure);

			function getMessagesSuccess(response) {
				vm.messages = response.data;
			}

			function getMessagesFailure() { }
		}

		function save() {
			if (vm.suggestionForm.$valid) {				
				SuggestionService.submit(vm.suggestion)
						.then(submitSuccess)
						.catch(failure);				
			}

			function submitSuccess() {
				vm.showMessage = true;
				reset();
			}

			function failure() { }
		}

		function reset() {
			vm.suggestion = {};			
			vm.suggestionForm.$setPristine();			
			vm.suggestionForm.$setUntouched();
		}
	}
}());