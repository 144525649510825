(function () {
	'use strict';

	angular
        .module('e74intranet.event')
        .controller('ArticleDetailController', ArticleDetailController);

	ArticleDetailController.$inject = ['$stateParams', '$state', 'ArticleService', 'moment'];

	function ArticleDetailController($stateParams, $state, ArticleService, moment) {
		var vm = this;
		var articleId = $stateParams.articleId;
		activate();

		function activate() {
			getArticle();
		}

		function getArticle() {
			if (!articleId) $state.go('error404');

			ArticleService.getById(articleId)
				.then(getByIdsSuccess, getByIdFailure);

			function getByIdsSuccess(response) {
				vm.article = response.data;

				if (!vm.article) $state.go('error404');								
			}

			function getByIdFailure() { }
		}
	}
}());