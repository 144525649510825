(function () {
	'use strict';

	angular
       .module('e74intranet.employee')
       .controller('PolicyOverviewAddEditController', PolicyOverviewAddEditController);

	PolicyOverviewAddEditController.$inject = [
		'$scope',
		'$uibModalInstance',
		'modelResolved',
		'PolicyService',
		'DepartmentService',
		'NotificationService'	
	];

	function PolicyOverviewAddEditController(
		$scope,
		$uibModalInstance,
		modelResolved,
		PolicyService,
		DepartmentService,
		NotificationService)
	{
		var vm = this;
		vm.policy = angular.copy(modelResolved);
		vm.isNew = !vm.policy;
		vm.title = (!vm.isNew ? 'Edit' : 'New') + ' Policy';
		vm.save = save;
		vm.cancel = cancel;		
		vm.isNewFile = false;		
		var saveUrl = "api/resources/uploadFile";

		activate();

		function activate() {
			var resourceId;

			getDepartments();

			if (vm.policy && vm.policy.resource) {
				vm.selectedFile = vm.policy.resource.fileName;
				resourceId = vm.policy.resource.resourceId;
			}

			configureKendoUploader(resourceId);			
		}

		function getDepartments() {
			DepartmentService.getAll()
				.then(getAllSuccess, getAllFailure);

			function getAllSuccess(response) {
				vm.departments = response.data;
			}

			function getAllFailure() { }
		}

		function configureKendoUploader(resourceId) {
			if (resourceId) saveUrl = saveUrl + "?resourceId=" + resourceId;

			vm.uploadOptions = {
				multiple: false,
				showFileList: false,
				async: {
					autoUpload: true,
					saveUrl: saveUrl,
					removeUrl: ""
				},
				localization: {
					headerStatusUploaded: "The file was saved successfully"
				},
				upload: onUpload,
				error: onError,
				success: onSuccess,
				select: onSelect,
				clear: onClear
			};
		}

		function onUpload() {
			vm.isUploading = true;
			$scope.$apply();
		}

		function onError() {
			vm.isUploading = false;
			NotificationService.error('An error has occurred while uploading the file. Please contact your system administrator.');
			$scope.$apply();
		}

		function onSuccess(e) {
			if (!vm.policy) {
				vm.policy = { resource: { resourceId: e.response, fileName: vm.selectedFile } };
			} else {
				vm.policy.resource = { resourceId: e.response, fileName: vm.selectedFile };
			}

			vm.isUploading = false;
			vm.isNewFile = true;			
			vm.uploader.options.async.saveUrl = "api/resources/uploadFile?resourceId=" + e.response;
			$scope.$apply();
		}

		function onSelect(e) {
			var file = e.files[0];

			if (file.extension.toLowerCase() != ".pdf") {
				NotificationService.warning('The selected file is not valid. Supported file type is *.pdf');
				e.preventDefault();
				return;
			}

			$scope.$apply(function () {				
				vm.resource = file;
				vm.selectedFile = vm.resource.name;
			});
		}

		function onClear() {
			vm.isNewFile = false;			

			$scope.$apply(function () {
				vm.resource = vm.policy ? vm.policy.resource : null;
				vm.selectedFile = vm.resource ? vm.resource.fileName : "";
			});
		}
		
		function save() {
			if (vm.policyForm.$valid) {
				if (!vm.policy.resource) {
					NotificationService.warning('It is mandatory to upload a policy file.');
					return;
				}

				savePolicy();
			}
		}		

		function savePolicy() {
			if (vm.policy.policyId) {
				PolicyService.update(vm.policy)
					.then(updateSuccess)
					.catch(failure);
			} else {
				PolicyService.create(vm.policy)
					.then(saveSuccess)
					.catch(failure);
			}

			function saveSuccess() {
				NotificationService.success('save');
				$uibModalInstance.close();
			}

			function updateSuccess() {
				NotificationService.success('update');
				$uibModalInstance.close();
			}			

			function failure() {}			
		}

		function cancel() {
			$uibModalInstance.close('cancel');
		}
	}
}());