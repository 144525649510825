(function () {
	'use strict';

	angular
		.module('e74intranet.myAccount')
		.controller('NewAccountController', NewAccountController);

	NewAccountController.$inject = ['UserService', 'ErrorService'];

	function NewAccountController(UserService, ErrorService) {
		var vm = this;
		vm.message = '';
		vm.register = register;

		function register() {
			vm.user.isActive = true;
			vm.user.isFirstTimeLogin = false;

			UserService.register(vm.user)
				.then(registerSuccess, registerFailure);

			function registerSuccess(response) {
				vm.message = 'The user has been created. Please confirm your email.';
				vm.class = 'text-success';
			}
			function registerFailure(error) {
				vm.message = ErrorService.generateErrorMessageFromArray(error.data.errors);
				vm.class = 'text-danger';
			}
		}
	}
}());