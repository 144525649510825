(function () {
	'use strict';

	angular
		.module('e74intranet.myAccount')
		.controller('ConfirmEmailController', ConfirmEmailController);

	ConfirmEmailController.$inject = ['$state', 'UserService', 'ErrorService'];

	function ConfirmEmailController($state, UserService, ErrorService) {
		var vm = this;
		vm.message = '';		
		vm.user = {};

		activate();

		function activate() {
			vm.user.userId = $state.params.userId;
			vm.user.token = $state.params.token;			

			UserService.confirmEmail(vm.user)
				.then(confirmEmailSuccess, confirmEmailFailure);

			function confirmEmailSuccess(response) {
				vm.message = 'Your email has been confirmed. Please <a href="#!/login">click here to login</a>';					
				vm.class = 'text-success';
			}
			function confirmEmailFailure(error) {
				vm.message = ErrorService.generateErrorMessageFromArray(error.data.errors);
				vm.class = 'text-danger';
			}
		}
	}
}());