(function () {
	'use strict';

	angular
        .module('e74intranet.common')		
        .config(Config);	

	Config.$inject = [		        	
        '$httpProvider',
		'$uibModalProvider',
		'bsValidationConfigProvider',		
		'growlProvider',
		'ImageBrowserProvider',
		'ivhTreeviewOptionsProvider'
	];

	function Config(		        
        $httpProvider,
		$uibModalProvider,
		bsValidationConfigProvider,	
		growlProvider,
		ImageBrowserProvider,
		ivhTreeviewOptionsProvider
    ) {		
		// http interceptor
		$httpProvider.interceptors.push('AuthInterceptor');

		//http disable cache
		//initialize get if not there
		if (!$httpProvider.defaults.headers.get) {
			$httpProvider.defaults.headers.get = {};
		}

		//disable IE ajax request caching
		$httpProvider.defaults.headers.get['If-Modified-Since'] = 'Mon, 26 Jul 1997 05:00:00 GMT';
		// extra
		$httpProvider.defaults.headers.get['Cache-Control'] = 'no-cache';
		$httpProvider.defaults.headers.get.Pragma = 'no-cache';

		// Growl
		growlProvider.globalTimeToLive(5000);
		growlProvider.globalDisableIcons(true);
		growlProvider.globalPosition('bottom-right');
		growlProvider.globalDisableCountDown(true);
		growlProvider.globalDisableCloseButton(true);

		// Angular treeview setup
		ivhTreeviewOptionsProvider.set({
			idAttribute: 'id',
			labelAttribute: 'label',
			childrenAttribute: 'children',
			selectedAttribute: 'selected',
			useCheckboxes: true,
			expandToDepth: 0,
			indeterminateAttribute: 'indeterminate',
			expandedAttribute: 'expanded',
			defaultSelectedState: false,
			validate: true,
			twistieCollapsedTpl: '<i class="fa fa-chevron-right"></i>',
			twistieExpandedTpl: '<i class="fa fa-chevron-down"></i>',
			twistieLeafTpl: '&nbsp;&nbsp;&nbsp;'
		});

		// Set default options for ui bootstrap modal
		$uibModalProvider.options = {
			animation: true,
			backdrop: 'static',
			keyboard: false
		};

		// Forms validation config
		bsValidationConfigProvider.global.addSuccessClass = false;
		bsValidationConfigProvider.global.setValidateFieldsOn('submit');

		// Kendo Editor ImageBrowser tools
		var tools = [
			"bold",
			"italic",
			"underline",
			"strikethrough",
			"justifyLeft",
			"justifyCenter",
			"justifyRight",
			"justifyFull",
			"insertUnorderedList",
			"insertOrderedList",
			"indent",
			"outdent",
			"createLink",
			"unlink",
			"insertImage",
			"insertFile",
			"subscript",
			"superscript",
			"tableWizard",
			"createTable",
			"addRowAbove",
			"addRowBelow",
			"addColumnLeft",
			"addColumnRight",
			"deleteRow",
			"deleteColumn",
			"viewHtml",
			"formatting",
			"cleanFormatting",
			"fontName",
			"fontSize",
			"foreColor",
			"backColor",
			"print"
		];
		ImageBrowserProvider.setTools(tools);
	}
}());