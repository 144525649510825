(function () {
    'use strict';

    angular
		.module('e74intranet.employee')
		.factory('CatalogResourceService', CatalogResourceService);

    CatalogResourceService.$inject = ['$http'];

    function CatalogResourceService($http) {
        var root = 'api/catalogResources';
        var catalogResourceService = {
            getFilteredCatalogResources: getFilteredCatalogResources,
            create: create,
            update: update,
            remove: remove
        };

        function getFilteredCatalogResources(departmentIds, searchText, isGrouped) {
            var requestUrl = root + '/departmentCatalogResources';

            if (departmentIds && searchText) {
                requestUrl += '?departmentIds=' + departmentIds;
                requestUrl += '&searchText=' + searchText;
            }

            else if (departmentIds) requestUrl += '?departmentIds=' + departmentIds;
            else if (searchText) requestUrl += '?searchText=' + searchText;

            return $http.get(requestUrl);
        }

        function create(model) {
            return $http.post(root, model);
        }

        function update(model) {
            return $http.put(root, model);
        }

        function remove(id) {
            return $http.delete(root + '/' + id);
        }
       
        return catalogResourceService;
    }
}());


