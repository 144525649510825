(function () {
	'use strict';

	angular
        .module('e74intranet.employee')
        .controller('SuggestionBoxController', SuggestionBoxController);

	SuggestionBoxController.$inject = ['$rootScope', 'SuggestionService', 'ModalService', 'NotificationService', 'AccessService', 'CONSTANTS'];

	function SuggestionBoxController($rootScope, SuggestionService, ModalService, NotificationService, AccessService, CONSTANTS) {
		var vm = this;
		vm.pageNumber = CONSTANTS.PAGINATION.DEFAULT_PAGE_NUMBER;
		vm.pageSize = 15;
		vm.maxSize = 5;						
		vm.onPaginationChange = getPagedSuggestions;
		vm.openModal = openModal;
		vm.deleteRow = deleteRow;
		vm.openApprovalSuggestionModal = openApprovalSuggestionModal;
		vm.selectedItems = [];
		vm.datePicker = { date: { startDate: null, endDate: null } };		

		vm.datePickerOptions = {
			applyClass: 'btn-primary',
			cancelClass: 'btn-danger',			
			locale: {
				format: "YYYY-MM-DD"
			},
			eventHandlers: {
				'apply.daterangepicker': onDateRangeChange,
				'cancel.daterangepicker': onClear
			}
		};

		activate();

		function onDateRangeChange(ev, picker) {
			// This is a hack since the date picker has a bug when selecting today's date at the beginning, thus checking for nulls at selection phase
			if (!vm.datePicker.date.startDate || !vm.datePicker.date.endDate) {
				var today = moment().format('YYYY-MM-DD');
				vm.datePicker = { date: { startDate: today, endDate: today } };
			}

			vm.startDate = moment(vm.datePicker.date.startDate).format('YYYY-MM-DD');
			vm.endDate = moment(vm.datePicker.date.endDate).format('YYYY-MM-DD');
	
			getPagedSuggestions();
		}

		vm.multiDropdownSettings = {
			idProp: 'suggestionStatusId',
			externalIdProp: 'suggestionStatusId',
			displayProp: 'name',
			buttonClasses: 'btn btn-default btn-multi-dropdown'
		};

		vm.translation = {
			buttonDefaultText: 'Filter by status',
			dynamicButtonTextSuffix: 'selected'
		};

		vm.multiDropdownEvents = {
			onSelectionChanged: onSelectionChanged,
		};

		function activate() {
			setPermissions();
			getPagedSuggestions();
			getSuggestionStatus();
		}

		function setPermissions() {
			if (AccessService.hasPermissions($rootScope.permission.CREATE)) vm.create = true;
			if (AccessService.hasPermissions($rootScope.permission.UPDATE)) vm.update = true;
			if (AccessService.hasPermissions($rootScope.permission.DELETE)) vm.delete = true;
			if (AccessService.hasPermissions($rootScope.permission.APPROVE_REJECT)) vm.approve = true;
		}

		function onSelectionChanged() {
			vm.selectedIds = "";
			angular.forEach(vm.selectedItems, function (item) {
				vm.selectedIds += item.suggestionStatusId + ',';
			});

			if (vm.selectedIds.length > 0) vm.selectedIds = vm.selectedIds.substring(0, vm.selectedIds.length - 1);

			getPagedSuggestions();
		}

		function onClear() {
			vm.datePicker = { date: { startDate: null, endDate: null } };
			vm.startDate = null;
			vm.endDate = null;
			getPagedSuggestions();
		}
	
		function getPagedSuggestions() {			
			SuggestionService.getPagedSuggestions(vm.pageNumber, vm.pageSize, false, "", vm.selectedIds, vm.startDate, vm.endDate)
                .then(getPagedSuggestionsSuccess, getPagedSuggestionsFailure);

			function getPagedSuggestionsSuccess(response) {
				vm.suggestions = response.data.results;
				vm.totalItems = response.data.totalItems;
			}

			function getPagedSuggestionsFailure(error) { }
		}

		function getSuggestionStatus() {
			SuggestionService.getSuggestionStatus()
				.then(getSuggestionStatusSuccess, getSuggestionStatusFailure);

			function getSuggestionStatusSuccess(response) {
				vm.suggestionStatus = response.data;
			}

			function getSuggestionStatusFailure() { }
		}

		function openModal(action, suggestion) {
			var modalInstance = ModalService.createModal({
				templateUrl: 'wwwroot/app/employee/suggestion/suggestion-box/suggestion-box-add-edit.html',
				controller: 'SuggestionBoxAddEditController',
				resolve: action === 'edit' ? suggestion : null
			});

			modalInstance.then(modalInstanceResponse, modalInstanceError);

			function modalInstanceResponse(response) {
				if (response !== "cancel") {
					getPagedSuggestions();
				}
			}

			function modalInstanceError() { }
		}

		function deleteRow(suggestion) {
			var modalPromptInstance = ModalService.createModalPrompt({
				title: 'Delete Suggestion',
				content: 'The selected suggestion will be deleted. Do you want to continue?'
			});

			modalPromptInstance.then(modalPromptResponse, modalPromptInstanceError);

			function modalPromptResponse(response) {
				if (response === 'cancel') return;

				SuggestionService.remove(suggestion.suggestionId)
                    .then(removeSuccess, removeFailure);

				function removeSuccess() {
					NotificationService.success('delete');
					getPagedSuggestions();
				}

				function removeFailure(){}
			}

			function modalPromptInstanceError() { }
		}

		function openApprovalSuggestionModal(suggestion) {
			var modalInstance = ModalService.createModal({
				templateUrl: 'wwwroot/app/employee/suggestion/suggestion-box/suggestion-box-approval.html',
				controller: 'SuggestionBoxApprovalController',
				resolve: suggestion
			});

			modalInstance.then(modalInstanceResponse, modalInstanceError);

			function modalInstanceResponse(response) {
				if (response !== "cancel") {
					getPagedSuggestions();
				}
			}

			function modalInstanceError() { }
		}
	}
}());