(function () {
	'use strict';

	angular
        .module('e74intranet.main')
        .config(Config);

	Config.$inject = ['$stateProvider'];

	function Config($stateProvider) {
		var mainState = {
			abstract: true,
			name: 'main',
			url: '/main',
			controller: 'MainController',
			controllerAs: 'vm',
			templateUrl: 'wwwroot/app/main/main.html'
		};

		$stateProvider.state(mainState);
	}
}());