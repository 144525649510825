(function () {
    'use strict';

    angular
        .module('e74intranet.reservation')
        .controller('ReservationController', ReservationController);

    ReservationController.$inject = ['$rootScope', 'ReservationService', 'ReservationLocationService', 'NotificationService', 'ModalService', 'CONSTANTS'];

    function ReservationController($rootScope, ReservationService, ReservationLocationService, NotificationService, ModalService, CONSTANTS) {
        var vm = this;
        vm.pageNumber = CONSTANTS.PAGINATION.DEFAULT_PAGE_NUMBER;
        vm.pageSize = CONSTANTS.PAGINATION.DEFAULT_PAGE_SIZE;
        vm.reservation = {};
        vm.loadGrid = loadGrid;
        vm.openModal = openModal;
        vm.deleteRow = deleteRow;
        vm.gridOptions = {};

        activate();

        vm.gridOptions = {
            columnDefs: [
                { field: 'getFullName()', displayName: 'Resident Name' },
                { field: 'reservationDate', name: 'Date', displayName: 'Reservation Date', type: 'date', cellFilter: 'date:"MMMM d, yyyy"' },
                { field: 'peopleInReservation', name: 'PeopleInReservation', displayName: 'People In Reservation' },
                { field: 'reservationLocation.name', name: 'Name', displayName: 'Reservation Location' },
                { field: 'reservationTimeframe.name', name: 'Name', displayName: 'Reservation Timeframe' }
            ],
            onRegisterApi: function (gridApi) {
                gridApi.pagination.on.paginationChanged(null, function (newPage, pageSize) {
                    vm.pageNumber = newPage;
                    vm.pageSize = pageSize;
                    loadGrid();
                });
                gridApi.core.on.sortChanged(null, function (grid, sortColumns) {
                    if (sortColumns.length > 0) {
                        vm.orderBy = sortColumns[0].lastName;
                        vm.ascending = sortColumns[0].sort.direction === 'asc' ? true : false;
                    }
                    loadGrid();
                });
                gridApi.selection.on.rowSelectionChanged(null, function (row) {
                    vm.reservation = [];
                    vm.disabled = true;
                    if (row.isSelected) {
                        vm.reservation = row.entity;
                        vm.disabled = false;
                    }
                });
            }
        };

        function activate() {
            vm.disabled = true;
            loadGrid();
        }

        function loadGrid() {
            ReservationService.getPagedReservations(vm.pageNumber, vm.pageSize, vm.ascending, vm.orderBy, vm.searchText)
                .then(getPagedReservationsSuccess, getPagedReservationsFailure);

            function getPagedReservationsSuccess(response) {
                angular.forEach(response.data.results, function (row) {
                    row.getFullName = function () {
                        return this.residentFirstName + ' ' + this.residentLastName;
                    };
                });

                vm.gridOptions.data = response.data.results;
                vm.gridOptions.totalItems = response.data.totalItems;
            }

            function getPagedReservationsFailure(){}
        }

        function openModal(action) {
            var modalInstance = ModalService.createModal({
                templateUrl: 'wwwroot/app/reservations/reservation/reservation-add-edit.html',
                controller: 'ReservationAddEditController',
                size: 'lg',
                resolve: action === 'edit' ? vm.reservation : null
            });

            modalInstance.then(modalInstanceResponse, modalInstanceError);

            function modalInstanceResponse(response) {
                if (response !== "cancel") {
                    activate();
                }
            }

            function modalInstanceError() { }
        }

        function deleteRow() {
            var modalPromptInstance = ModalService.createModalPrompt({
                title: 'Delete reservation',
                content: 'The selected reservation will be deleted. Do you want to continue?'
            });

            modalPromptInstance.then(modalPromptInstanceResponse, modalPromptInstanceError);

            function modalPromptInstanceResponse(response) {
                if (response === 'cancel') return;

                ReservationService.remove(vm.reservation.reservationId)
                    .then(removeSuccess, removeFailure);
                
                function removeSuccess() {
                    NotificationService.success('delete');
                    activate();
                }

                function removeFailure(){}
            }

            function modalPromptInstanceError() { }
        }
    }
}());