(function () {
	'use strict';

	angular
		.module('e74intranet.employee')
		.factory('PhysicianService', PhysicianService);

	PhysicianService.$inject = ['$http'];

	function PhysicianService($http) {
		var root = 'api/physicians';
		var physicianService = {
			getAll: getAll,
			getPagedPhysicians: getPagedPhysicians,			
			getById: getById,
			create: create,
			update: update,
			remove: remove
		};

		function getAll() {
			return $http.get(root);
		}

		function getPagedPhysicians(page, pageSize, ascending, orderBy, searchText, specialityIds, positionIds) {
			var requestUrl = root + '/paging?page=' + (page || 1) +
									'&pageSize=' + (pageSize || 10) +
									'&ascending=' + (ascending == undefined ? true : ascending) +
									'&orderBy=' + (orderBy || '') +
									'&search=' + (searchText || '');

			if (specialityIds) requestUrl += '&specialityIds=' + specialityIds;
			if (positionIds) requestUrl += '&positionIds=' + positionIds;

			return $http.get(requestUrl);
		}		

		function getById(id) {
			return $http.get(root + '/' + id);
		}

		function create(model) {
			return $http.post(root, model);
		}

		function update(model) {
			return $http.put(root, model);
		}

		function remove(id) {
			return $http.delete(root + '/' + id);
		}

		return physicianService;
	}
}());


