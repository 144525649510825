(function () {
	'use strict';

	angular
        .module('e74intranet.common')
        .factory('ModalService', ModalService);

	ModalService.$inject = ['$uibModal'];

	function ModalService($uibModal) {
		var modalService = {			
			createModal: createModal,
			createModalMessage: createModalMessage,
			createModalPrompt: createModalPrompt
		};
		
		function createModal(config) {
			var modalInstance = $uibModal.open({
				controller: config.controller,
				controllerAs: config.controllerAs || 'vm',
				templateUrl: config.templateUrl,
				size: config.size || 'md',
				windowClass: config.windowClass || '',
				resolve: {
					modelResolved: function () {									
						return config.resolve;
					}
				}
			});

			return modalInstance.result;
		}

		function createModalMessage(model) {
			var modalMessageInstance = $uibModal.open({
				controller: 'ModalController',
				controllerAs: 'vm',
				templateUrl: 'wwwroot/app/common/modal/templates/modal-message.html',
				resolve: {
					modelResolved: function () {						
						return { title: model.title, content: model.content };
					}
				}
			});

			return modalMessageInstance.result;
		}

		function createModalPrompt(model) {
			var modalPromptInstance = $uibModal.open({
				controller: 'ModalController',
				controllerAs: 'vm',
				templateUrl: 'wwwroot/app/common/modal/templates/modal-prompt.html',
				resolve: {
					modelResolved: function () {						
						return { 
							title: model.title, 
							content: model.content, 
							okLabel: model.okLabel || 'Ok',
							cancelLabel: model.cancelLabel || 'Cancel'
						};
					}
				}
			});

			return modalPromptInstance.result;
		}

		return modalService;
	}
}());