(function () {
	'use strict';

	angular
		.module('e74intranet.employee')
		.factory('PolicyService', PolicyService);

	PolicyService.$inject = ['$http'];

	function PolicyService($http) {
	    var root = 'api/policies';
	    var policyService = {
	        getDepartmentPolicyList: getDepartmentPolicyList,
	        getFilteredPolicies: getFilteredPolicies,
	        getJustPolicies: getJustPolicies,
	        create: create,
	        update: update,
	        remove: remove
	    };

	    function getDepartmentPolicyList(departmentIds, searchText) {
	        var requestUrl = root + '/departmentPolicyList';

	        if (departmentIds && searchText) {
	            requestUrl += '?departmentIds=' + departmentIds;
	            requestUrl += '&searchText=' + searchText;
	        }

	        else if (departmentIds) requestUrl += '?departmentIds=' + departmentIds;
	        else if (searchText) requestUrl += '?searchText=' + searchText;

	        return $http.get(requestUrl);
	    }

	    function getFilteredPolicies(departmentIds, searchText) {
	        var requestUrl = root + '/departmentPolicies';

	        if (departmentIds && searchText) {
	            requestUrl += '?departmentIds=' + departmentIds;
	            requestUrl += '&searchText=' + searchText;
	        }

	        else if (departmentIds) requestUrl += '?departmentIds=' + departmentIds;
	        else if (searchText) requestUrl += '?searchText=' + searchText;

	        return $http.get(requestUrl);
	    }

	    function getJustPolicies(departmentIds, searchText) {
	        var requestUrl = root + '/justPolicies';

	        if (departmentIds && searchText) {
	            requestUrl += '?departmentIds=' + departmentIds;
	            requestUrl += '&searchText=' + searchText;
	        }

	        else if (departmentIds) requestUrl += '?departmentIds=' + departmentIds;
	        else if (searchText) requestUrl += '?searchText=' + searchText;

	        return $http.get(requestUrl);
	    }

	    function create(model) {
	        return $http.post(root, model);
	    }

	    function update(model) {
	        return $http.put(root, model);
	    }

	    function remove(id) {
	        return $http.delete(root + '/' + id);
	    }

	    return policyService;
	}
}());


