(function () {
	'use strict';

	angular
		.module('e74intranet.employee')
		.factory('EmployeeNominationService', EmployeeNominationService);

	EmployeeNominationService.$inject = ['$http'];

	function EmployeeNominationService($http) {
		var root = 'api/employeeNominations';
		var employeeNominationService = {
			create: create
		};

		function create(model) {
			return $http.post(root, model);
		}
	
		return employeeNominationService;
	}
}());


