(function () {
    'use strict';

    angular
        .module('e74intranet.employee')
        .factory('AnnouncementCategoryService', AnnouncementCategoryService);

    AnnouncementCategoryService.$inject = ['$http'];

    function AnnouncementCategoryService($http) {
        var root = 'api/announcementCategories';
        var announcementCategoryService = {
            getAll: getAll,
            getSelectedAnnouncementCategories: getSelectedAnnouncementCategories,
            getPagedAnnouncementCategories: getPagedAnnouncementCategories,
            create: create,
            update: update,
            remove: remove
        };

        function getAll() {
            return $http.get(root);
        }

        function getSelectedAnnouncementCategories(ids) {
            ids = (ids && ids.length > 0) ? ids : "0";
            return $http.get(root + '/' + ids + '/selected');
        }

        function getPagedAnnouncementCategories(page, pageSize, ascending, orderBy, searchText) {
            return $http.get(root + '/paging?page=' + (page || 1) +
                                    '&pageSize=' + (pageSize || 10) +
                                    '&ascending=' + (ascending == undefined ? true : ascending) +
                                    '&orderBy=' + (orderBy || '') +
                                    '&search=' + (searchText || ''));
        }

        function create(model) {
            return $http.post(root, model);
        }

        function update(model) {
            return $http.put(root, model);
        }

        function remove(id) {
            return $http.delete(root + '/' + id);
        }

        return announcementCategoryService;
    }
}());