(function () {
	'use strict';

	angular
        .module('e74intranet.employee')
        .controller('MenuItemController', MenuItemController);

	MenuItemController.$inject = ['$rootScope', 'MenuItemService', 'ModalService', 'AccessService', 'NotificationService'];

	function MenuItemController($rootScope, MenuItemService, ModalService, AccessService, NotificationService) {
		var vm = this;
		vm.openModal = openModal;
		vm.swapOrder = swapOrder;

		activate();

		function activate() {
			setPermissions();
			getMenuItems();
		}

		function setPermissions() {
			if (AccessService.hasPermissions($rootScope.permission.UPDATE)) vm.update = true;
		}

		function getMenuItems() {
			MenuItemService.getMenuItems()
				.then(getMenuItemsSuccess, getMenuItemsFailure);

			function getMenuItemsSuccess(response) {
				vm.menuItems = response.data;
			}

			function getMenuItemsFailure() { }
		}

		function openModal(menuItem) {
			var modalInstance = ModalService.createModal({
				templateUrl: 'wwwroot/app/security/menu-item/menu-item-add-edit.html',
				controller: 'MenuItemAddEditController',
				resolve: menuItem
			});

			modalInstance.then(modalInstanceResponse, modalInstanceError);

			function modalInstanceResponse(response) {
				if (response !== "cancel") {
					getMenuItems();
				}
			}

			function modalInstanceError() { }
		}

		function swapOrder(index, menuItems, orderPosition) {
		    var oldIndex = orderPosition == 1 ? index - 1 : index + 1;
		    var newMenuItem = menuItems[index];
		    var oldMenuItem = menuItems[oldIndex];
		    var menuItemRequest = {};

		    if (oldMenuItem) {
		        var currentSortOrder = newMenuItem.sortOrder;
		        newMenuItem.sortOrder = oldMenuItem.sortOrder;
		        oldMenuItem.sortOrder = currentSortOrder;
		        menuItems[index] = oldMenuItem;
		        menuItems[oldIndex] = newMenuItem;

		        menuItemRequest = {
		            newMenuItemId: newMenuItem.menuItemId,
		            newMenuItemSortOrder: newMenuItem.sortOrder,
		            oldMenuItemId: oldMenuItem.menuItemId,
		            oldMenuItemSortOrder: oldMenuItem.sortOrder
		        };

		        MenuItemService
                    .swapOrder(menuItemRequest)
                    .then(swapOrderSuccess, swapOrderFailure);
		    }

		    function swapOrderSuccess(response) {
		        NotificationService.success('update');
		    }

            function swapOrderFailure() { }
		}
	}
}());