(function () {
    'use strict';

    angular
        .module('e74intranet.reservation')
        .controller('ReservationFormController', ReservationFormController);

    ReservationFormController.$inject = ['$rootScope', 'ReservationService', 'NotificationService', 'ModalService', 'CONSTANTS'];

    function ReservationFormController($rootScope, ReservationService, NotificationService, ModalService, CONSTANTS) {
        var vm = this;
        vm.reservation = {};
        vm.openModal = openModal;

        activate();

        function activate() {
            openModal('new');
        }

        function openModal(action) {
            var modalInstance = ModalService.createModal({
                templateUrl: 'wwwroot/app/reservations/reservation/reservation-add-edit.html',
                controller: 'ReservationAddEditController',
                size: 'lg',
                resolve: action === 'edit' ? vm.reservation : null
            });

            modalInstance.then(modalInstanceResponse, modalInstanceError);

            function modalInstanceResponse(response) {
            }

            function modalInstanceError() { }
        }
    }
}());