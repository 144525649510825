(function () {
	angular
        .module('e74intranet.common')
        .decorator('GridOptions', GridOptions);

	GridOptions.$inject = ['$delegate', 'i18nService', 'CONSTANTS'];

	function GridOptions($delegate, i18nService, CONSTANTS) {
		var gridOptions;
		gridOptions = angular.copy($delegate);
		gridOptions.initialize = function (options) {
			var initOptions;
			initOptions = $delegate.initialize(options);
			initOptions.rowHeight = 40;
			initOptions.paginationPageSizes = [10, 25, 50];
			initOptions.paginationPageSize = CONSTANTS.PAGINATION.PAGE_SIZE;
			initOptions.useExternalPagination = true;
			initOptions.enableColumnMenus = false;
			initOptions.enableSorting = true;
			initOptions.enableRowSelection = true;
			initOptions.enableFullRowSelection = true;
			initOptions.showSelectionCheckbox = false;
			initOptions.multiSelect = false;
			initOptions.noUnselect = false;
			initOptions.enableRowHeaderSelection = false;			
			initOptions.showFilter = false;
			initOptions.enableHorizontalScrollbar = false;
			initOptions.enableVerticalScrollbar = true;

			i18nService.setCurrentLang('en');

			return initOptions;
		};
		return gridOptions;
	}
}());