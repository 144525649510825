(function () {
    'use strict';

    angular
        .module('e74intranet.reservation')
        .controller('ReservationTimeframeController', ReservationTimeframeController);

    ReservationTimeframeController.$inject = ['ReservationTimeframeService', 'NotificationService', 'ModalService', 'CONSTANTS'];

    function ReservationTimeframeController(ReservationTimeframeService, NotificationService, ModalService, CONSTANTS) {
        var vm = this;
        vm.pageNumber = CONSTANTS.PAGINATION.DEFAULT_PAGE_NUMBER;
        vm.pageSize = CONSTANTS.PAGINATION.DEFAULT_PAGE_SIZE;
        vm.reservationTimeframe = {};
        vm.loadGrid = loadGrid;
        vm.openModal = openModal;
        vm.deleteRow = deleteRow;
        vm.gridOptions = {};

        activate();

        vm.gridOptions = {
            columnDefs: [
                { field: 'name', name: 'Name', displayName: 'Name' },
                { field: 'startDate', name: 'StartDate', displayName: 'Start Date', type: 'date', cellFilter: 'date:"h:mm a"' },
                { field: 'endDate', name: 'EndDate', displayName: 'End Date', type: 'date', cellFilter: 'date:"h:mm a"' }
            ],
            onRegisterApi: function (gridApi) {
                gridApi.pagination.on.paginationChanged(null, function (newPage, pageSize) {
                    vm.pageNumber = newPage;
                    vm.pageSize = pageSize;
                    loadGrid();
                });
                gridApi.core.on.sortChanged(null, function (grid, sortColumns) {
                    if (sortColumns.length > 0) {
                        vm.orderBy = sortColumns[0].name;
                        vm.ascending = sortColumns[0].sort.direction === 'asc' ? true : false;
                    }
                    loadGrid();
                });
                gridApi.selection.on.rowSelectionChanged(null, function (row) {
                    vm.reservationTimeframe = [];
                    vm.disabled = true;
                    if (row.isSelected) {
                        vm.reservationTimeframe = row.entity;
                        vm.disabled = false;
                    }
                });
            }
        };

        function activate() {
            vm.disabled = true;
            loadGrid();
        }

        function loadGrid() {
            ReservationTimeframeService.getPagedReservationTimeframes(vm.pageNumber, vm.pageSize, vm.ascending, vm.orderBy, vm.searchText)
                .then(getPagedReservationTimeframesSuccess, getPagedReservationTimeframesFailure);

            function getPagedReservationTimeframesSuccess(response) {
                vm.gridOptions.data = response.data.results;
                vm.gridOptions.totalItems = response.data.totalItems;
            }

            function getPagedReservationTimeframesFailure() { }
        }

        function openModal(action) {
            var modalInstance = ModalService.createModal({
                templateUrl: 'wwwroot/app/reservations/reservation-timeframe/reservation-timeframe-add-edit.html',
                controller: 'ReservationTimeframeAddEditController',
                size: 'lg',
                resolve: action === 'edit' ? vm.reservationTimeframe : null
            });

            modalInstance.then(modalInstanceResponse, modalInstanceError);

            function modalInstanceResponse(response) {
                if (response !== "cancel") {
                    activate();
                }
            }

            function modalInstanceError() { }
        }

        function deleteRow() {
            var modalPromptInstance = ModalService.createModalPrompt({
                title: 'Delete reservation timeframe',
                content: 'The selected reservation timeframe will be deleted. Do you want to continue?'
            });

            modalPromptInstance.then(modalPromptInstanceResponse, modalPromptInstanceError);

            function modalPromptInstanceResponse(response) {
                if (response === 'cancel') return;

                ReservationTimeframeService.remove(vm.reservationTimeframe.reservationTimeframeId)
                    .then(removeSuccess, removeFailure);

                function removeSuccess() {
                    NotificationService.success('delete');
                    activate();
                }

                function removeFailure() { }
            }

            function modalPromptInstanceError() { }
        }
    }
}());