(function () {
    'use strict';

    angular
        .module('e74intranet.reservation')
        .factory('ReservationResidentService', ReservationResidentService);

    ReservationResidentService.$inject = ['$http'];

    function ReservationResidentService($http) {
        var root = 'api/reservationsResident';
        var reservationResidentService = {
            create: create            
        };

        function create(model) {
            return $http.post(root, model);
        }

        return reservationResidentService;
    }
}());