(function () {
	'use strict';

	angular
       .module('e74intranet.employee')
       .controller('EventDescriptionController', EventDescriptionController);

	EventDescriptionController.$inject = [
		'$rootScope',		
		'$timeout',
		'$uibModalInstance',		
		'ImageBrowser',
		'modelResolved',		
		'NotificationService'		
	];

	function EventDescriptionController(
		$rootScope,		
		$timeout,
		$uibModalInstance,		
		ImageBrowser,
		modelResolved,		
		NotificationService		
		) {
		var vm = this;
		vm.description = angular.copy(modelResolved);
		vm.isNew = !vm.description;
		vm.title = (!vm.isNew ? 'Edit' : 'Add') + ' Description';
		vm.save = save;
		vm.cancel = cancel;
				
		activate();

		function activate() {		
		}
	
        function save() {            
			if (vm.eventDescriptionForm.$valid) {
				if (!vm.validator.validate()) return;				
			}
			
			$uibModalInstance.close(vm.description);						
			$rootScope.showBodyScroll = false;
		}

		function cancel() {
			$rootScope.showBodyScroll = false;
			$uibModalInstance.close('cancel');
		}

		vm.tooloptions = {
			tools: ImageBrowser.tools,
			imageBrowser: {
				messages: {
					dropFilesHere: "Drop files here"
				},
				transport: {
					read: {
						url: "api/imageBrowser/images",
						type: "GET",
						cache: false
					},
					destroy: {
						url: function (params) {
							return "api/imageBrowser/?fileName=" + params.name;
						},
						type: "DELETE",
						complete: function () {
							$timeout(function () {
								$rootScope.$broadcast('hideLoadMask');
							});
							NotificationService.success('update');
						}
					},
					thumbnailUrl: "api/imageBrowser/images/thumbnail",
					uploadUrl: "api/imageBrowser/upload",
					imageUrl: "api/imageBrowser/image?path={0}"
				}
			},
			resizable: {
				content: true,
				toolbar: false
			},
			execute: function () {
				$rootScope.showBodyScroll = true;
				$timeout(function () {
					$rootScope.$broadcast('hideBreadcrumb');
				});
			},
			rules: {
				maxTextLength: function (textarea) {
					if (textarea.is("[data-maxtextlength-msg]") && textarea.val() != "") {
						var maxlength = textarea.attr("data-maxtextlength");
						var value = textarea.data("kendoEditor").value();
						return value.replace(/<[^>]+>/g, "").length <= maxlength;
					}

					return true;
				},
				required: function (textarea) {
					if (textarea.is("[data-required]") && textarea.val() != "") {
						var value = textarea.data("kendoEditor").value();
						return value.replace(/<[^>]+>/g, "").length > 0;
					}

					return false;
				}
			}
		};
	}
}());