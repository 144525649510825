(function () {
    'use strict';

    angular
        .module('e74intranet.reservation')
        .controller('ReservationTimeframeAddEditController', ReservationTimeframeAddEditController);

    ReservationTimeframeAddEditController.$inject = ['$scope', '$uibModalInstance', 'modelResolved', 'ReservationTimeframeService', 'NotificationService', 'ModalService'];

    function ReservationTimeframeAddEditController($scope, $uibModalInstance, modelResolved, ReservationTimeframeService, NotificationService, ModalService) {
        var vm = this;
        vm.reservationTimeframe = angular.copy(modelResolved);
        vm.isNew = !vm.reservationTimeframe;
        vm.title = (!vm.isNew ? 'Edit' : 'New') + ' Reservation Timeframe';
        vm.clearDates = clearDates;
        vm.startDateChanged = startDateChanged;
        vm.endDateChanged = endDateChanged;
        vm.save = save;
        vm.cancel = cancel;
        vm.maxDate = new Date(8640000000000000);
        vm.minDate = new Date();
        vm.daysOfWeek = [{ id: 0, name: "Sunday" }, { id: 1, name: "Monday" }, { id: 2, name: "Tuesday" }, { id: 3, name: "Wednesday" }, { id: 4, name: "Thursday" }, { id: 5, name: "Friday" }, { id: 6, name: "Saturday" }];
        vm.selectedItems = [];
        vm.selectedNames = "";

        vm.timeOptions = {
            interval: 15
        };

        vm.multiDropdownSettings = {
            idProp: 'id',
            externalIdProp: 'id',
            displayProp: 'name',
            buttonClasses: 'btn btn-default btn-multi-dropdown'
        };

        vm.translation = {
            buttonDefaultText: 'Select Available Days for Timeframe',
            dynamicButtonTextSuffix: 'selected'
        };

        vm.multiDropdownEvents = {
            onSelectionChanged: onSelectionChanged,
        };

        function onSelectionChanged() {
            vm.selectedNames = "";
            angular.forEach(vm.selectedItems, function (item) {
                vm.selectedNames += item.id + ',';
            });

            if (vm.selectedNames.length > 0) vm.selectedNames = vm.selectedNames.substring(0, vm.selectedNames.length - 1);

            vm.reservationTimeframe.daysOfWeekVisible = vm.selectedNames;
        }

        if (vm.isNew)
            vm.minDate = new Date();
        else
            vm.minDate = new Date(vm.reservationTimeframe.startDate);

        activate();

        function activate() {
            bindDaysOfWeekAvailable();
        }

        function bindDaysOfWeekAvailable() {
            vm.selectedItems = [];
            var selectedDays = [];

            if (!vm.isNew) {
                if (vm.reservationTimeframe.daysOfWeekVisible.length > 0) {
                    selectedDays = vm.reservationTimeframe.daysOfWeekVisible.split(',');
                }

                for (var i = 0; i < vm.daysOfWeek.length; i++) {
                    for (var j = 0; j < selectedDays.length; j++) {
                        if (vm.daysOfWeek[i].id == selectedDays[j]) {
                            vm.selectedItems.push(vm.daysOfWeek[i]);
                            break;
                        }
                    }
                }
            }
        }

        function startDateChanged() {
            vm.minDate = new Date(vm.reservationTimeframe.startDate);
        }
        function endDateChanged() {
            vm.maxDate = new Date(vm.reservationTimeframe.endDate);
        }

        function clearDates() {
            if (vm.reservationTimeframe) {
                vm.reservationTimeframe.startDate = '';
                vm.reservationTimeframe.endDate = '';
            }

            vm.maxDate = new Date(8640000000000000);
            vm.minDate = new Date();
        }

        function save() {
            if (vm.reservationTimeframe.reservationTimeframeId) {
                ReservationTimeframeService.update(vm.reservationTimeframe)
                    .then(updateSuccess)
                    .catch(failure);
            } else {
                ReservationTimeframeService.create(vm.reservationTimeframe)
                    .then(saveSuccess)
                    .catch(failure);
            }

            function saveSuccess() {
                NotificationService.success('save');
                $uibModalInstance.close();
            }
            function updateSuccess() {
                NotificationService.success('update');
                $uibModalInstance.close();
            }
            function failure() {
                ResourceService.delete(response.resourceId);
            }
        }

        function cancel() {
            $uibModalInstance.close('cancel');
        }
    }
}());