(function () {
	'use strict';

	angular
		.module('e74intranet.common')		
		.filter('removeHtml', function () {
			return function (text) {
				return text ? String(text).replace(/<[^>]+>/gm, '') : '';
			};
		});	 
}());