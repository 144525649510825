(function () {
	'use strict';

	angular
        .module('e74intranet.employee')
        .controller('FindPhysicianController', FindPhysicianController);

	FindPhysicianController.$inject = ['$rootScope', 'PhysicianService', 'SpecialityService', 'PositionService', 'AccessService', 'ModalService', 'NotificationService', 'CONSTANTS', 'localStorageService'];

	function FindPhysicianController($rootScope, PhysicianService, SpecialityService, PositionService, AccessService, ModalService, NotificationService, CONSTANTS, localStorageService) {
		var vm = this;
		vm.pageNumber = CONSTANTS.PAGINATION.DEFAULT_PAGE_NUMBER;
		vm.pageSize = 21;
        vm.maxSize = 5;
		vm.onPaginationChange = getPagedPhysicians;
        vm.openModal = openModal;
        vm.viewOpenModal = viewOpenModal;
		vm.deleteRow = deleteRow;		
		vm.getPagedPhysicians = getPagedPhysicians;		
		vm.clearResults = clearResults;

		vm.selectedSpecialities = [];
		vm.selectedPositions = [];

		activate();

		function activate() {
			setPermissions();
			getSpecialities();
			getPositions();
			getPagedPhysicians();
        }

		function setPermissions() {
			if (AccessService.hasPermissions($rootScope.permission.CREATE)) vm.create = true;
			if (AccessService.hasPermissions($rootScope.permission.UPDATE)) vm.update = true;
			if (AccessService.hasPermissions($rootScope.permission.DELETE)) vm.delete = true;			
		}

		// Multidropdown for specialities
		vm.specialitySettings = {
			idProp: 'specialityId',
			externalIdProp: 'specialityId',
			displayProp: 'name',
			buttonClasses: 'btn btn-default btn-multi-dropdown'
		};

		vm.translationSpecialities = {
			buttonDefaultText: 'Filter by Housing Type',
			dynamicButtonTextSuffix: 'selected'
		};

		vm.specialityEvents = {
			onSelectionChanged: onSelectionChanged,
		};

		// Multidropdown for positions
		vm.positionSettings = {
			idProp: 'positionId',
			externalIdProp: 'positionId',
			displayProp: 'name',
			buttonClasses: 'btn btn-default btn-multi-dropdown'
		};

		vm.translationPositions = {
			buttonDefaultText: 'Filter by Position',
			dynamicButtonTextSuffix: 'selected'
		};

		vm.positionEvents = {
			onSelectionChanged: onSelectionChanged,
		};
		
		function onSelectionChanged() {			
			vm.selectedSpecialitiesIds = "";
			vm.selectedPositionsIds = "";

			angular.forEach(vm.selectedPositions, function (item) {				
				vm.selectedPositionsIds += item.positionId + ',';
			});

			angular.forEach(vm.selectedSpecialities, function (item) {
				vm.selectedSpecialitiesIds += item.specialityId + ',';
			});

			if (vm.selectedPositionsIds.length > 0) vm.selectedPositionsIds = vm.selectedPositionsIds.substring(0, vm.selectedPositionsIds.length - 1);
			if (vm.selectedSpecialitiesIds.length > 0) vm.selectedSpecialitiesIds = vm.selectedSpecialitiesIds.substring(0, vm.selectedSpecialitiesIds.length - 1);

			getPagedPhysicians();
		}
		
		function getSpecialities() {
			SpecialityService.getAll()
				.then(getAllSpecialitiesSuccess, getAllSpecialitiesFailure);

			function getAllSpecialitiesSuccess(response) {
				vm.specialities = response.data;
			}

			function getAllSpecialitiesFailure() { }
		}

		function getPositions() {
			PositionService.getAll()
				.then(getAllPositionsSuccess, getAllPositionsFailure);

			function getAllPositionsSuccess(response) {
				vm.positions = response.data;
			}

			function getAllPositionsFailure() { }
		}

		function getPagedPhysicians() {
			PhysicianService.getPagedPhysicians(vm.pageNumber, vm.pageSize, true, "Name", vm.searchText, vm.selectedSpecialitiesIds, vm.selectedPositionsIds)
                .then(getPagedPhysiciansSuccess, getPagedPhysiciansFailure);

			function getPagedPhysiciansSuccess(response) {
				vm.physicians = response.data.results;
				vm.totalItems = response.data.totalItems;
			}

			function getPagedPhysiciansFailure(error) { }
		}

		var authData = localStorageService.get('authorizationData');
		var token = "";

		if (authData) {
			token = 'Bearer ' + authData.token;
		}
		
		vm.autoCompleteOptions = {
			placeholder: "Search by Name",
			dataTextField: "name",
			filter: "contains",
			clearButton: false,
			dataSource: new kendo.data.DataSource({
				serverFiltering: true,
				transport: {					
					read: {
						url: "api/physicians/filtered",
						dataType: "json",
						beforeSend: function (req) {
							req.setRequestHeader('Authorization', token);
						}
					},
					parameterMap: function (data, type) {
						var queryString = {};
																		
						if (vm.searchText) queryString.searchText = vm.searchText;
						if (vm.selectedSpecialitiesIds) queryString.specialityIds = vm.selectedSpecialitiesIds;
						if (vm.selectedPositionsIds) queryString.positionIds = vm.selectedPositionsIds;
						
						return queryString;
					}
				}
			}),
			select: onSelect			
		};	

		function onSelect(e) {
			vm.searchText = e.item.text();
			getPagedPhysicians();
		}

		function clearResults() {
			vm.searchText = '';
			getPagedPhysicians();
        }

        function viewOpenModal(action, physician) {
            var modalInstance = ModalService.createModal({
                templateUrl: 'wwwroot/app/employee/physicians/find-physician/find-physician-view.html',
                controller: 'FindPhysicianViewController',
                windowClass: 'modal',
                resolve: action === 'view' ? physician : null
            });

            modalInstance.then(modalInstanceResponse, modalInstanceError);

            function modalInstanceResponse(response) {
                if (response !== "cancel") {
                    getPagedPhysicians();
                }
            }

            function modalInstanceError() { }
        }

		function openModal(action, physician) {
			var modalInstance = ModalService.createModal({
				templateUrl: 'wwwroot/app/employee/physicians/find-physician/find-physician-add-edit.html',
				controller: 'FindPhysicianAddEditController',
				windowClass: 'xl-modal',
				resolve: action === 'edit' ? physician : null
			});

			modalInstance.then(modalInstanceResponse, modalInstanceError);

			function modalInstanceResponse(response) {
			    if (response !== "cancel") {
			        getPagedPhysicians();
				}
			}

			function modalInstanceError() { }
		}

		function deleteRow(physician) {
			var modalPromptInstance = ModalService.createModalPrompt({
				title: 'Delete Resident',
				content: 'The selected resident will be deleted. Do you want to continue?'
			});

			modalPromptInstance.then(modalPromptResponse, modalPromptInstanceError);

			function modalPromptResponse(response) {
				if (response === 'cancel') return;

				PhysicianService.remove(physician.physicianId)
                    .then(removeSuccess, removeFailure);

				function removeSuccess() {
					NotificationService.success('delete');
					getPagedPhysicians();
				}

				function removeFailure(){}
			}

			function modalPromptInstanceError() { }
		}
	}
}());