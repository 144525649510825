(function () {
	'use strict';

	angular
		.module('e74intranet', [
            'e74intranet.cafeteria',
			'e74intranet.common',
			'e74intranet.catalogs',
			'e74intranet.dashboard',
			'e74intranet.employee',
			'e74intranet.event',
			'e74intranet.login',
			'e74intranet.main',
			'e74intranet.myAccount',
            'e74intranet.reservation',
			'e74intranet.security',
            'e74intranet.webapp'
		]);	
}());