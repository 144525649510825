(function () {
	'use strict';

	angular
		.module('e74intranet.employee')
		.factory('ContactService', ContactService);

	ContactService.$inject = ['$http'];

	function ContactService($http) {
	    var root = 'api/contacts';
	    var contactService = {
	        getDepartmentContactList: getDepartmentContactList,
	        getFilteredContacts: getFilteredContacts,
	        getJustContacts: getJustContacts,
	        create: create,
	        update: update,
	        remove: remove,
	        swapOrder: swapOrder
	    };

	    function getDepartmentContactList(departmentIds, searchText) {
	        var requestUrl = root + '/departmentContactList';

	        if (departmentIds && searchText) {
	            requestUrl += '?departmentIds=' + departmentIds;
	            requestUrl += '&searchText=' + searchText;
	        }

	        else if (departmentIds) requestUrl += '?departmentIds=' + departmentIds;
	        else if (searchText) requestUrl += '?searchText=' + searchText;

	        return $http.get(requestUrl);
	    }

	    function getFilteredContacts(departmentIds, searchText, isGrouped) {
	        var requestUrl = root + '/departmentContacts';

	        if (departmentIds && searchText) {
	            requestUrl += '?departmentIds=' + departmentIds;
	            requestUrl += '&searchText=' + searchText;
	        }

	        else if (departmentIds) requestUrl += '?departmentIds=' + departmentIds;
	        else if (searchText) requestUrl += '?searchText=' + searchText;

	        return $http.get(requestUrl);
	    }

	    function getJustContacts(departmentIds, searchText) {
	        var requestUrl = root + '/justContacts';

	        if (departmentIds && searchText) {
	            requestUrl += '?departmentIds=' + departmentIds;
	            requestUrl += '&searchText=' + searchText;
	        }

	        else if (departmentIds) requestUrl += '?departmentIds=' + departmentIds;
	        else if (searchText) requestUrl += '?searchText=' + searchText;

	        return $http.get(requestUrl);
	    }

	    function create(model) {
	        return $http.post(root, model);
	    }

	    function update(model) {
	        return $http.put(root, model);
	    }

	    function remove(id) {
	        return $http.delete(root + '/' + id);
	    }

	    function swapOrder(contactRequest) {
	        return $http.put(root + '/swapOrder', contactRequest);
	    }

	    return contactService;
	}
}());


