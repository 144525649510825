(function () {
	'use strict';

	angular
       .module('e74intranet.employee')
        .controller('FindPhysicianViewController', FindPhysicianViewController);

    FindPhysicianViewController.$inject = [		
		'$scope',		
		'$uibModalInstance',				
		'CONSTANTS',		
		'modelResolved',		
		'NotificationService',
		'PositionService',
		'PhysicianService',
		'SpecialityService'
	];

    function FindPhysicianViewController(		
		$scope,		
		$uibModalInstance,				
		CONSTANTS,		
		modelResolved,		
		NotificationService,
		PositionService,
		PhysicianService,
		SpecialityService
		)
	{
		var vm = this;
        vm.physician = angular.copy(modelResolved);        
		vm.isNew = !vm.physician;
		vm.title = 'View Resident';
		vm.cancel = cancel;
		vm.isNewFile = false;
		
		activate();

		function activate() {
			var resourceId;

			if (vm.physician && vm.physician.resource) {
				vm.selectedFile = vm.physician.resource.fileName;
				resourceId = vm.physician.resource.resourceId;
			}
			
			getSpecialities();
			getPositions();			
		}				

		function getSpecialities() {
			SpecialityService.getAll()
				.then(getAllSpecialitiesSuccess, getAllSpecialitiesFailure);

			function getAllSpecialitiesSuccess(response) {
				vm.specialities = response.data;
			}

			function getAllSpecialitiesFailure() { }
		}

		function getPositions() {
			PositionService.getAll()
				.then(getAllPositionsSuccess, getAllPositionsFailure);

			function getAllPositionsSuccess(response) {
				vm.positions = response.data;
			}

			function getAllPositionsFailure() { }
		}	
        
		function cancel() {			
			$uibModalInstance.close('cancel');
		}				
	}
}());