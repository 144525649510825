(function () {
	'use strict';

	angular
       .module('e74intranet.catalogs')
       .controller('SpecialityAddEditController', SpecialityAddEditController);

	SpecialityAddEditController.$inject = ['$scope', '$uibModalInstance', 'modelResolved', 'SpecialityService', 'NotificationService'];

	function SpecialityAddEditController($scope, $uibModalInstance, modelResolved, SpecialityService, NotificationService) {
		var vm = this;		
		vm.speciality = angular.copy(modelResolved);
		vm.isNew = !vm.speciality;
		vm.title = (!vm.isNew ? 'Edit' : 'New') + ' Speciality';
		vm.save = save;
		vm.cancel = cancel;		

		function save() {
			if (vm.speciality.specialityId) {
				SpecialityService.update(vm.speciality)
					.then(updateSuccess)
					.catch(failure);
			} else {
				SpecialityService.create(vm.speciality)
					.then(saveSuccess)
					.catch(failure);
			}

			function saveSuccess() {
				NotificationService.success('save');
				$uibModalInstance.close();
			}
			function updateSuccess() {
				NotificationService.success('update');
				$uibModalInstance.close();
			}
			function failure() {				
			}
		}

		function cancel() {
			$uibModalInstance.close('cancel');
		}
	}
}());