(function () {
    'use strict';

    angular
        .module('e74intranet.employee')
        .controller('AnnouncementCategoryAddEditController', AnnouncementCategoryAddEditController);

    AnnouncementCategoryAddEditController.$inject = ['$scope', '$uibModalInstance', 'modelResolved', 'AnnouncementCategoryService', 'NotificationService', 'ModalService'];

    function AnnouncementCategoryAddEditController($scope, $uibModalInstance, modelResolved, AnnouncementCategoryService, NotificationService, ModalService) {
        var vm = this;
        vm.announcementCategory = angular.copy(modelResolved);
        vm.isNew = !vm.announcementCategory;
        vm.title = (!vm.isNew ? 'Edit' : 'New') + ' Announcement Category';
        vm.save = save;
        vm.cancel = cancel;

        function save() {
            if (vm.announcementCategory.announcementCategoryId) {
                AnnouncementCategoryService.update(vm.announcementCategory)
                    .then(updateSuccess)
                    .catch(failure);
            } else {
                AnnouncementCategoryService.create(vm.announcementCategory)
                    .then(saveSuccess)
                    .catch(failure);
            }

            function saveSuccess() {
                NotificationService.success('save');
                $uibModalInstance.close();
            }
            function updateSuccess() {
                NotificationService.success('update');
                $uibModalInstance.close();
            }
            function failure() {
                ResourceService.delete(response.resourceId);
            }
        }

        function cancel() {
            $uibModalInstance.close('cancel');
        }
    }
}());