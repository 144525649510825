(function () {
    'use strict';

    angular
        .module('e74intranet.cafeteria')
        .factory('CourseService', CourseService);

    CourseService.$inject = ['$http'];

    function CourseService($http) {
        var root = 'api/courses';
        var courseService = {
            getById: getById,
            create: create,
            update: update,
            remove: remove
        };

        function getById(id) {
            return $http.get(root + '/' + id);
        }

        function create(model) {
            return $http.post(root, model);
        }

        function update(model) {
            return $http.put(root, model);
        }

        function remove(id) {
            return $http.delete(root + '/' + id);
        }

        return courseService;
    }
}());