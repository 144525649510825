(function () {
	'use strict';

	angular
		.module('e74intranet')
		.run(Run)
		.config(Config);

	Run.$inject = [
        '$rootScope',
		'$timeout',				
        'blockUI',
        '$state',
        '$location',
		'$uibModalStack',        
        'AccessService',
		'MenuItemService',
        'CONSTANTS'
	];

	function Run(
        $rootScope,
		$timeout,				
        blockUI,
        $state,
        $location,
		$uibModalStack,        
        AccessService,
		MenuItemService,
        CONSTANTS		
    ) {
		$rootScope.showBodyScroll = false;

        if (location.protocol != 'https:' && location.href.indexOf('localhost') < 0) {
            location.replace('https:' + window.location.href.substring(window.location.protocol.length));
        }

		/*if (!AccessService.isAuthenticated()) {			
			reLogin();
		}*/
		if (!AccessService.isAuthenticated()) {			            
            if (!isProtectedUrl) {                
                $location.path('/login');
            }
		}

		$rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams, options) {			
		});

		$rootScope.getMenuItemLabel = function (url, mid) {			
			return MenuItemService.getMenuItemLabel(url, mid);
		};

		$rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {			
			var url = $location.url();
			var mid = $state.params.mid;			

			$uibModalStack.dismissAll('cancel');
			$rootScope.mid = mid;
			$rootScope.url = url;			

			if (isProtectedUrl(url)) {
				if (AccessService.isAuthorized(url, mid)) {
					$timeout(function () {
						$rootScope.$broadcast('permissionsValidated');
					});
				}
				else $state.go('error403');				

				if (url.indexOf('/dashboard') === -1) $rootScope.isDashboard = false;				
			}
		});

		$rootScope.$on('onRevokeToken', function () {
			blockUI.stop();
			reLogin();
		});

		$rootScope.$on('forbidden', function (event, data) {
			blockUI.stop();
			$state.go('error403');			
		});

		$rootScope.$on('notFound', function () {		
			blockUI.stop();
			$state.go('error404');
		});

		$rootScope.$on('fatalException', function (event, data) {	
			blockUI.stop();
			$state.go('error500');
		});		
	
		$rootScope.$on('showBodyScrollBar', function (event, args) {
			if (args.showScroll) {
				angular.element("body").css('overflow', 'auto');
			}
			else {
				angular.element("body").css('overflow', 'hidden');
			}
		});
		function isProtectedUrl(url) {
			return (url !== '/' &&
					url.indexOf('/login') === -1 &&
					url.indexOf('/resetPassword') === -1 &&
					url.indexOf('/forgotPassword') === -1 &&
					url.indexOf('/newAccount') === -1 &&
					url.indexOf('/confirmEmail') === -1 &&
					url.indexOf('/error/403') === -1 &&
					url.indexOf('/error/404') === -1 &&
					url.indexOf('/error/500') === -1);
		}

		function reLogin() {			
			AccessService.login()
				.then(loginSuccess, loginError);

			function loginSuccess() {							
				$state.go('main.dashboard', {}, { reload: true });
			}
			function loginError() {				
				AccessService.logout();
				$state.go('init', {}, { reload: true });
			}			
		}

		// We set the permissions into rootScope variables to be accesible to any controller
		$rootScope.permission = {
			CREATE: CONSTANTS.PERMISSIONTYPE.CREATE,
			READ: CONSTANTS.PERMISSIONTYPE.READ,
			UPDATE: CONSTANTS.PERMISSIONTYPE.UPDATE,
			DELETE: CONSTANTS.PERMISSIONTYPE.DELETE,
			ANNOUNCEMENTS: CONSTANTS.PERMISSIONTYPE.ANNOUNCEMENTS,
			FEATURED_SECTION: CONSTANTS.PERMISSIONTYPE.FEATURED_SECTION,
			ARTICLE: CONSTANTS.PERMISSIONTYPE.ARTICLE,
			BACK_TALK: CONSTANTS.PERMISSIONTYPE.BACK_TALK,
			EVENTS: CONSTANTS.PERMISSIONTYPE.EVENTS,
			SUGGESTION_BOX: CONSTANTS.PERMISSIONTYPE.SUGGESTION_BOX,
			APPROVE_REJECT: CONSTANTS.PERMISSIONTYPE.APPROVE_REJECT,
			SUBMIT_SUGGESTION: CONSTANTS.PERMISSIONTYPE.SUBMIT_SUGGESTION
		};
	}

	Config.$inject = ['$stateProvider', '$urlRouterProvider', 'blockUIConfig'];

	function Config($stateProvider, $urlRouterProvider, blockUIConfig) {
		var initState = {
			name: 'init',
			url: '/',
			controller: 'LoginController',
			controllerAs: 'vm',
			templateUrl: 'wwwroot/app/login/login.html'
        };
        var error401State = {
            name: 'error401',
            url: '/',
            controller: 'LoginController',
            controllerAs: 'vm',
            templateUrl: 'wwwroot/app/login/login.html'
        };
		var error403State = {
			name: 'error403',
			url: '/error/403',
			controller: 'ErrorController',
			controllerAs: 'vm',
			templateUrl: 'wwwroot/app/common/error/403.html'
		};
		var error404State = {
			name: 'error404',
			url: '/error/404',
			controller: 'ErrorController',
			controllerAs: 'vm',
			templateUrl: 'wwwroot/app/common/error/404.html'
		};
		var error500State = {
			name: 'error500',
			url: '/error/500',
			controller: 'ErrorController',
			controllerAs: 'vm',
			templateUrl: 'wwwroot/app/common/error/500.html'
		};

        $stateProvider.state(initState);
        $stateProvider.state(error401State);
		$stateProvider.state(error403State);
		$stateProvider.state(error404State);
		$stateProvider.state(error500State);
		$urlRouterProvider.otherwise('/login');
		blockUIConfig.message = 'Loading...';
		blockUIConfig.autoBlock = false;
	}
}());