(function () {
    'use strict';

    angular
        .module('e74intranet.cafeteria')
        .factory('MenuService', MenuService);

    MenuService.$inject = ['$http'];

    function MenuService($http) {
        var root = 'api/menus';
        var menuService = {
            getById: getById,
            getMenuEvents: getMenuEvents,
            create: create,
            update: update,
            remove: remove
        };

        function getById(id) {
            return $http.get(root + '/' + id);
        }

        function getMenuEvents(ids) {
            ids = (ids && ids.length > 0) ? ids : "0";
            return $http.get(root + '/' + ids + '/events');
        }

        function create(model) {
            return $http.post(root, model);
        }

        function update(model) {
            return $http.put(root, model);
        }

        function remove(id) {
            return $http.delete(root + '/' + id);
        }

        return menuService;
    }
}());