(function () {
	angular
        .module('e74intranet.event')
        .controller('EventResidentController', EventResidentController);

	EventResidentController.$inject = [
		'$filter',
		'$rootScope',
		'$scope',
		'$timeout',
        '$stateParams'		
	];

	function EventResidentController(
		$filter,
		$rootScope,
		$scope,
		$timeout,
        $stateParams) {	
        $(document).ready(function () {
            loaddata();

            setInterval(function () {
                location = location;
            }, 600000);
        });

        function loaddata() {
            var slashes = location.protocol.concat("//");
            var host = slashes.concat(window.location.host);

            $.ajax({
                type: "GET",
                url: host + "/api/events/app/eventdays/" + $stateParams.eventCategoryId,
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: OnSuccess,
                failure: function (response) {
                    console.log(response);
                },

                error: function (response) {
                    console.log(response);
                }
            });
        }

        function OnSuccess(response) {
            console.log('loading data');

            var item = $("#gridContainer .item:first").clone(true);
            var date = '';
            var options = { weekday: 'long', month: 'long', day: 'numeric' };
            var timeOptions = { hour: 'numeric', minute: '2-digit' };

            for (var i = 0; i < response.length; i++) {
                date = '';
                for (var j = 0; j < response[i].events.length; j++) {
                    var startDate = new Date(response[i].events[j].startDate);
                    var endDate = new Date(response[i].events[j].endDate);
                    $("#gridContainer .item:last .activity-title").html(response[i].events[j].title + ' - ' + startDate.toLocaleTimeString("en-US", timeOptions) + ' - ' + endDate.toLocaleTimeString("en-US", timeOptions) + ' - ' + response[i].events[j].location);
                    $("#gridContainer .item:last .activity-content").html(response[i].events[j].description);
                    $("#gridContainer .item:last .chateaublue").css({ "background-color": response[i].events[j].eventCategories[0].color });
                    $("#gridContainer .item:last .date").html('');
                    //$("#gridContainer .mealitem:last .maincourse").html(response[i]["menu"]["details"][0]["item"]["name"]);
                    //$(".CalendarEvent .caleventtext", item).html(response.d[i]["Title"] + " - " + response.d[i]["CalendarDisplayName"]);
                    //$(".CalendarEvent .title", item).attr("href", "<%=System.Configuration.ConfigurationManager.AppSettings("URLPath") & " / EventDetails.aspx ? EventSource = Google & CalendarName="%>" + response.d[i]["CalendarName"] + "<%= " & Template=" & Page.MasterPageFile.ToLower().Replace(".master", "") & " & EventID="%>" + response.d[i]["EventID"]);
                    //$(".CalendarEvent .caldate", item).html(response.d[i]["StartTimeDayOfMonth"]);

                    if (date == '') {
                        date = response[i].fullDate;
                        var parsedDate = new Date(response[i].fullDate);
                        parsedDate.setMinutes(parsedDate.getMinutes() + parsedDate.getTimezoneOffset());

                        $("#gridContainer .item:last .date").html(parsedDate.toLocaleDateString('en-US', options));
                        $("#gridContainer .item:last .date").addClass("non-empty-dates");
                    }

                    $("#gridContainer").append(item);
                    item = $("#gridContainer .item:last").clone(true);
                }
            }

            $("#gridContainer .item:last").remove();

            /*var i = 0;
            $(".container-fluid.w-100.item").each(function () {
                var $this = $(this);
                $this.css("top", i);
                i += 0;
                doScroll($this);
            });*/
        }

            /*function doScroll($ele) {
                var top = parseInt($ele.css("top"));
                var offsetTop = parseInt($ele[0].offsetTop);
                var height = parseInt($ele.height());
                if (offsetTop < (-1 * height)) {
                    var firstTop = parseInt($("#gridContainer .item:first").css("top"));

                    if (firstTop < 0) {
                        top = $("#gridContainer").height();
                    } else {
                        top = firstTop;
                    }
                    $ele.css("top", top);
                }
                $ele.animate({ top: (parseInt(top) - 60) }, 4000, 'linear', function () { doScroll($(this)) });
            }*/
	}	
}());