(function () {
    'use strict';

    angular
        .module('e74intranet.cafeteria')
        .config(Config);

    Config.$inject = ['$stateProvider'];

    function Config($stateProvider) {
        var menuState = {
            name: 'main.menu',
            url: '/menu',
            controller: 'MenuController',
            controllerAs: 'vm',
            templateUrl: 'wwwroot/app/cafeteria/menu/menu.html',
            params: { mid: null },
            ncyBreadcrumb: {
                label: '{{ getMenuItemLabel("main.menu") }}',
                parent: 'main.dashboard'
            }
        };

        var mealState = {
            name: 'main.meal',
            url: '/meal',
            controller: 'MealController',
            controllerAs: 'vm',
            templateUrl: 'wwwroot/app/cafeteria/meal/meal.html',
            params: { mid: null },
            ncyBreadcrumb: {
                label: '{{ getMenuItemLabel("main.meal") }}',
                parent: 'main.dashboard'
            }
        };

        var courseState = {
            name: 'main.course',
            url: '/course',
            controller: 'CourseController',
            controllerAs: 'vm',
            templateUrl: 'wwwroot/app/cafeteria/course/course.html',
            params: { mid: null },
            ncyBreadcrumb: {
                label: '{{ getMenuItemLabel("main.course") }}',
                parent: 'main.dashboard'
            }
        };

        $stateProvider.state(menuState);
        $stateProvider.state(mealState);
        $stateProvider.state(courseState);
    }
}());