(function () {
	'use strict';

	angular
		.module('e74intranet.myAccount')
		.controller('ForgotPasswordController', ForgotPasswordController);

	ForgotPasswordController.$inject = ['UserService', 'ErrorService'];

	function ForgotPasswordController(UserService, ErrorService) {
		var vm = this;
		vm.sendPassword = sendPassword;
		vm.emailSent = false;
		vm.message = '';
		vm.class = '';

		function sendPassword() {
			UserService.sendPassword(vm.email)
				.then(sendPasswordSuccess, sendPasswordFailure);

			function sendPasswordSuccess(response) {
				vm.message = 'We have sent you an email to reset your password.';
				vm.class = 'text-success';
				vm.emailSent = true;
			}
			function sendPasswordFailure(error) {
				vm.message = ErrorService.generateErrorMessageFromArray(error.data.errors);
				vm.class = 'text-danger';
			}
		}
	}
}());