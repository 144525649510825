(function () {
	'use strict';

	angular
		.module('e74intranet.dashboard')
		.component('featuredNews', {
			templateUrl: 'wwwroot/app/dashboard/featured-news/featured-news.html',
			controller: FeaturedNewsController,
			controllerAs: 'vm'
		});

	FeaturedNewsController.$inject = ['ArticleService'];

	function FeaturedNewsController(ArticleService) {
		var vm = this;

		activate();

		function activate() {
			ArticleService.getFeaturedArticle()
                .then(getFeaturedArticleSuccess, getFeaturedArticleError);

			function getFeaturedArticleSuccess(response) {
				vm.featured = response.data;
			}

			function getFeaturedArticleError(){}
		}
	}
}());