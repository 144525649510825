(function () {
	'use strict';

	angular
        .module('e74intranet.employee')
        .controller('TestimonyController', TestimonyController);

	TestimonyController.$inject = ['TestimonyService', 'NotificationService', 'ModalService', 'CONSTANTS'];

	function TestimonyController(TestimonyService, NotificationService, ModalService, CONSTANTS) {
		var vm = this;
		vm.pageNumber = CONSTANTS.PAGINATION.DEFAULT_PAGE_NUMBER;
		vm.pageSize = CONSTANTS.PAGINATION.DEFAULT_PAGE_SIZE;
		vm.testimony = {};
		vm.loadGrid = loadGrid;
		vm.openModal = openModal;
		vm.deleteRow = deleteRow;
		vm.gridOptions = {};

		activate();

		vm.gridOptions = {
			columnDefs: [
                { field: 'title', name: 'Title', displayName: 'Title', width: '20%' },
				{ field: 'description', name: 'Description', displayName: 'Description', width: '60%' },
				{ field: 'isActive', name: 'IsActive', displayName: 'Status', cellTemplate: '<div class="ui-grid-cell-contents" ng-bind-html="grid.getCellValue(row, col) | status"></div>', width: '20%' }
			],
			onRegisterApi: function (gridApi) {
				gridApi.pagination.on.paginationChanged(null, function (newPage, pageSize) {
					vm.pageNumber = newPage;
					vm.pageSize = pageSize;
					loadGrid();
				});
				gridApi.core.on.sortChanged(null, function (grid, sortColumns) {
					if (sortColumns.length > 0) {
						vm.orderBy = sortColumns[0].name;
						vm.ascending = sortColumns[0].sort.direction === 'asc' ? true : false;
					}
					loadGrid();
				});
				gridApi.selection.on.rowSelectionChanged(null, function (row) {
					vm.testimony = [];
					vm.disabled = true;
					if (row.isSelected) {
						vm.testimony = row.entity;
						vm.disabled = false;
					}
				});
			}
		};

		function activate() {
			vm.disabled = true;
			loadGrid();
		}

		function loadGrid() {
			TestimonyService.getPagedTestimonies(vm.pageNumber, vm.pageSize, vm.ascending, vm.orderBy, vm.searchText)
                .then(getPagedTestimoniesSuccess, getPagedTestimoniesError);

			function getPagedTestimoniesSuccess(response) {
				vm.gridOptions.data = response.data.results;
				vm.gridOptions.totalItems = response.data.totalItems;
			}

			function getPagedTestimoniesError() { }
		}

		function openModal(action) {
			var modalInstance = ModalService.createModal({
				templateUrl: 'wwwroot/app/employee/back-talk/testimony-add-edit.html',
				controller: 'TestimonyAddEditController',
				resolve: action === 'edit' ? vm.testimony : null
			});

			modalInstance.then(modalInstanceResponse, modalInstanceError);

			function modalInstanceResponse(response) {
				if (response !== "cancel") {
					activate();
				}
			}

			function modalInstanceError() { }
		}

		function deleteRow() {
			var modalPromptInstance = ModalService.createModalPrompt({
				title: 'Delete testimony',
				content: 'The selected testimony will be deleted. Do you want to continue?'
			});

			modalPromptInstance.then(modalPromptResponse, modalPromptInstanceError);

			function modalPromptResponse(response) {
				if (response === 'cancel') return;

				TestimonyService.remove(vm.testimony.testimonyId)
                    .then(removeSuccess, removeFailure);

				function removeSuccess() {
					NotificationService.success('delete');
					activate();
				}

				function removeFailure(){}
			}

			function modalPromptInstanceError() { }
		}
	}
}());