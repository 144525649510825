(function () {
    'use strict';

    angular
        .module('e74intranet.cafeteria')
        .controller('MealController', MealController);

    MealController.$inject = [
        '$rootScope',
        'MealService',
        'MealCategoryService',
        'NotificationService',
        'ModalService',
        'AccessService'
    ];

    function MealController(
        $rootScope,
        MealService,
        MealCategoryService,
        NotificationService,
        ModalService,
        AccessService,
        CONSTANTS) {
        var vm = this;
        vm.pageNumber = CONSTANTS.PAGINATION.DEFAULT_PAGE_NUMBER;
        vm.pageSize = 15;
        vm.maxSize = 5;
        vm.openModal = openModal;
        vm.deleteRow = deleteRow;
        vm.onPaginationChange = getPagedMeals;
        vm.selectedItems = [];

        activate();

        vm.multiDropdownSettings = {
            idProp: 'mealCategoryId',
            externalIdProp: 'mealCategoryId',
            displayProp: 'name',
            buttonClasses: 'btn btn-default btn-multi-dropdown'
        };

        vm.translation = {
            buttonDefaultText: 'Filter by Category',
            dynamicButtonTextSuffix: 'selected'
        };

        vm.multiDropdownEvents = {
            onSelectionChanged: onSelectionChanged,
        };

        function onSelectionChanged() {
            vm.selectedIds = "";
            angular.forEach(vm.selectedItems, function (item) {
                vm.selectedIds += item.articleCategoryId + ',';
            });

            if (vm.selectedIds.length > 0) vm.selectedIds = vm.selectedIds.substring(0, vm.selectedIds.length - 1);

            getPagedMeals();
        }

        function activate() {
            setPermissions();
            getMeals();
        }

        function setPermissions() {
            if (AccessService.hasPermissions($rootScope.permission.CREATE)) vm.create = true;
            if (AccessService.hasPermissions($rootScope.permission.UPDATE)) vm.update = true;
            if (AccessService.hasPermissions($rootScope.permission.DELETE)) vm.delete = true;
        }

        function getMealCategories() {
            MealCategoryService.getAll()
				.then(getAllMealCategoriesSuccess, getAllMealCategoriesFailure);

            function getAllMealCategoriesSuccess(response) {
                vm.mealCategories = response.data;
            }

            function getAllMealCategoriesFailure() { }
        }

        function getPagedMeals() {
            MealService.getPagedMeals(vm.pageNumber, vm.pageSize, false, "", vm.selectedIds)
                .then(getPagedMealsSuccess, getPagedMealsFailure);

            function getPagedMealsSuccess(response) {
                vm.meals = response.data.results;
                vm.totalItems = response.data.totalItems;
            }

            function getPagedMealsFailure(error) { }
        }

        function openModal(action, meal) {
            var modalInstance = ModalService.createModal({
                templateUrl: 'wwwroot/app/cafeteria/meal/meal-add-edit.html',
                controller: 'MealAddEditController',
                size: 'lg',
                resolve: action === 'edit' ? meal : null
            });

            modalInstance.then(modalInstanceResponse, modalInstanceError);

            function modalInstanceResponse(response) {
                if (response !== "cancel") {
                    activate();
                }
            }

            function modalInstanceError() { }
        }

        function deleteRow(meal) {
            var modalPromptInstance = ModalService.createModalPrompt({
                title: 'Delete Meal',
                content: 'The selected meal will be deleted. Do you want to continue?'
            });

            modalPromptInstance.then(modalPromptInstanceResponse, modalPromptInstanceError);

            function modalPromptInstanceResponse(response) {
                if (response === 'cancel') return;

                MealService.remove(meal.mealId)
                    .then(removeSuccess, removeFailure);

                function removeSuccess() {
                    NotificationService.success('delete');
                    getMeals();
                }

                function removeFailure(){}
            }

            function modalPromptInstanceError() { }
        }
    }
}());