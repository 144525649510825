(function () {
	'use strict';

	angular
       .module('e74intranet.employee')
       .controller('SuggestionBoxAddEditController', SuggestionBoxAddEditController);

	SuggestionBoxAddEditController.$inject = ['$uibModalInstance', 'modelResolved', 'SuggestionService', 'NotificationService'];

	function SuggestionBoxAddEditController($uibModalInstance, modelResolved, SuggestionService, NotificationService) {
		var vm = this;
		vm.suggestion = angular.copy(modelResolved);
		vm.isNew = !vm.suggestion;
		vm.title = (!vm.isNew ? 'Edit' : 'New') + ' Suggestion';
		vm.save = save;
		vm.cancel = cancel;							

		function save() {
			if (vm.suggestionForm.$valid) {
				if (vm.suggestion.suggestionId) {
					SuggestionService.update(vm.suggestion)
                        .then(updateSuccess)
						.catch(failure);
				} else {				
					SuggestionService.create(vm.suggestion)
                        .then(saveSuccess)
						.catch(failure);
				}
			}

			function saveSuccess() {
				NotificationService.success('save');
				$uibModalInstance.close();
			}
			function updateSuccess() {
				NotificationService.success('update');
				$uibModalInstance.close();
			}
			function failure() { }
		}

		function cancel() {
			$uibModalInstance.close('cancel');
		}
	}
}());