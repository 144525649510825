(function () {
	'use strict';

	angular
        .module('e74intranet.employee')
        .factory('ResourceService', ResourceService);

	ResourceService.$inject = ['$http', 'CONSTANTS'];

	function ResourceService($http, CONSTANTS) {
		var root = 'api/resources';
		var resourceService = {
			getById: getById,			
			remove: remove,
			isExtensionValid: isExtensionValid,
            isSizeValid: isSizeValid
		};

		function getById(id) {
			return $http.get(root + '/' + id);
		}
		
		function remove(id) {
			return $http.delete(root + '/' + id);
		}

		function isExtensionValid(fileName, exts) {			
			return (new RegExp('(' + exts.join('|').replace(/\./g, '\\.') + ')$')).test(fileName);
		}

		function isSizeValid(fileSize) {
		    return fileSize <= CONSTANTS.RESOURCE.MAX_FILE_SIZE;
		}

		return resourceService;
	}
}());