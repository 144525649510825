(function () {
	'use strict';

	angular
		.module('e74intranet.common')
		.directive('hideLoadMask', HideLoadMask);

	function HideLoadMask() {
		return function (scope, element, attrs) {
			function hide() {				
				angular.element(".k-loading-mask").css('display', 'none');
			}
			scope.$on('hideLoadMask', hide);
		};
	}
}());