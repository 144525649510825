(function () {
	'use strict';

	angular
       .module('e74intranet.employee')
       .controller('SuggestionBoxApprovalController', SuggestionBoxApprovalController);

	SuggestionBoxApprovalController.$inject = ['$uibModalInstance', 'modelResolved', 'SuggestionService', 'NotificationService'];

	function SuggestionBoxApprovalController($uibModalInstance, modelResolved, SuggestionService, NotificationService) {
		var vm = this;	
		vm.title = 'Approve/Reject Suggestion';
		vm.save = save;
		vm.cancel = cancel;

		activate();

		function activate() {
			getSuggestion();
			getSuggestionStatus();
		}

		function getSuggestion() {
			SuggestionService.getById(modelResolved.suggestionId)
				.then(getByIdSuccess, getByIdFailure);

			function getByIdSuccess(response) {
				vm.suggestion = response.data;
			}

			function getByIdFailure() { }
		}

		function getSuggestionStatus() {
			SuggestionService.getSuggestionStatus()
				.then(getSuggestionStatusSuccess, getSuggestionStatusFailure);

			function getSuggestionStatusSuccess(response) {
				vm.suggestionStatus = response.data;
			}

			function getSuggestionStatusFailure() { }
		}

		function save() {			
			if (vm.suggestionForm.$valid) {				
				SuggestionService.update(vm.suggestion)
                    .then(updateSuccess)
					.catch(failure);
			}
	
			function updateSuccess() {
				NotificationService.success('The suggestion has been updated successfully.');
				$uibModalInstance.close();
			}
			function failure() { }
		}

		function cancel() {
			$uibModalInstance.close('cancel');
		}
	}
}());