(function () {
	'use strict';

	angular
        .module('e74intranet.catalogs')
        .controller('GeneralConfigurationController', GeneralConfigurationController);

	GeneralConfigurationController.$inject = ['$scope', 'GeneralConfigurationService', 'NotificationService', 'ProfileService', 'UserService', 'ResourceService'];

	function GeneralConfigurationController($scope, GeneralConfigurationService, NotificationService, ProfileService, UserService, ResourceService) {
		var vm = this;
		vm.generalConfiguration = {};
		vm.save = save;
		vm.isNewFile = false;	
		var saveUrl = "api/resources/uploadFile";

		activate();		
					
		function activate() {
			getProfiles();
			getUsers();
			getGeneralConfiguration();			
		}

		function getProfiles() {
			ProfileService.getAll()
				.then(getProfilesSuccess, getProfilesFailure);

			function getProfilesSuccess(response) {
				vm.profiles = response.data;
			}

			function getProfilesFailure(){}
		}

		function getUsers() {
			UserService.getAll()
				.then(getUsersSuccess, getUsersFailure);

			function getUsersSuccess(response) {
				vm.users = response.data;
			}

			function getUsersFailure(){}
		}
	
		function getGeneralConfiguration() {
			var resourceId;
			GeneralConfigurationService.getGeneralConfiguration()
				.then(getgetGeneralConfigurationSuccess, getGeneralConfigurationFailure);

			function getgetGeneralConfigurationSuccess(response) {
				vm.generalConfiguration = response.data;						

				if(vm.generalConfiguration && vm.generalConfiguration.menuFile) {
					vm.selectedFile = vm.generalConfiguration.menuFile.fileName;
					resourceId = vm.generalConfiguration.menuFile.resourceId;
				}			

				configureKendoUploader(resourceId);
			}

			function getGeneralConfigurationFailure() { }
		}

		function configureKendoUploader(resourceId) {			
			if (resourceId) saveUrl = saveUrl + "?resourceId=" + resourceId;

			vm.uploadOptions = {
				multiple: false,
				showFileList: false,
				async: {
					autoUpload: true,
					saveUrl: saveUrl,
					removeUrl: ""
				},
				localization: {
					headerStatusUploaded: "The file was saved successfully",
					select: "Select File"
				},
				upload: onUpload,
				error: onError,
				success: onSuccess,
				select: onSelect,
				clear: onClear
			};
		}

		function onUpload() {			
			vm.isUploading = true;			
			$scope.$apply();
		}

		function onError() {
			vm.isUploading = false;
			NotificationService.error('An error has occurred while uploading the file. Please contact your system administrator.');
			$scope.$apply();
		}

		function onSuccess(e) {
			if (!vm.generalConfiguration) {
				vm.generalConfiguration = { menuFile: { resourceId: e.response, fileName: vm.selectedFile } };
			} else {
				vm.generalConfiguration.menuFile = { resourceId: e.response, fileName: vm.selectedFile };
			}
			vm.isNewFile = true;
			vm.isUploading = false;			
			vm.uploader.options.async.saveUrl = "api/resources/uploadFile?resourceId=" + e.response;			
			$scope.$apply();
		}

		function onSelect(e) {			
			var file = e.files[0];

			if (file.extension.toLowerCase() != ".pdf") {
				NotificationService.warning('The selected file is not valid. Supported file is *.pdf');
				e.preventDefault();
				return;
			}

			$scope.$apply(function () {				
				vm.menuFile = file;
				vm.selectedFile = vm.menuFile.name;
			});			
		}

		function onClear() {			
			vm.isNewFile = false;			

			$scope.$apply(function () {
				vm.menuFile = vm.generalConfiguration ? vm.generalConfiguration.menuFile : null;
				vm.selectedFile = vm.menuFile ? vm.menuFile.fileName : "";				
			});			
		}

		function save() {			
			if (vm.generalConfigurationForm.$valid) {				
				if (!vm.generalConfiguration.menuFile) {
					NotificationService.warning('It is mandatory to configure a menu file.');
					return;
				}

				saveGeneralConfiguration();
			}			
		}

		function saveGeneralConfiguration() {			
			if (vm.generalConfiguration.generalConfigurationId) {
				GeneralConfigurationService.update(vm.generalConfiguration)
					.then(updateSuccess)
					.catch(failure)
					.finally(saveFinally);
			} else {
				GeneralConfigurationService.create(vm.generalConfiguration)
					.then(saveSuccess)
					.catch(failure)
					.finally(saveFinally);
			}

			function saveSuccess() {				
				NotificationService.success('save');								
			}

			function updateSuccess() {				
				NotificationService.success('update');
			}

			function failure() {}

			function saveFinally() {				
				getGeneralConfiguration();
			}
		}
	}
}());