(function () {
    'use strict';

    angular
        .module('e74intranet.reservation')
        .controller('ReservationResidentAddEditController', ReservationResidentAddEditController);

    ReservationResidentAddEditController.$inject = ['$scope', '$cookies', '$uibModalInstance', 'modelResolved', 'ReservationResidentService', 'ReservationLocationService', 'ReservationTimeframeService', 'NotificationService', 'ModalService'];

    function ReservationResidentAddEditController($scope, $cookies, $uibModalInstance, modelResolved, ReservationResidentService, ReservationLocationService, ReservationTimeframeService, NotificationService, ModalService) {
        var vm = this;
        vm.reservation = angular.copy(modelResolved);
        vm.isNew = !vm.reservation;
        vm.title = (!vm.isNew ? 'Edit' : 'New') + ' Reservation';
        vm.reservationDateChanged = reservationDateChanged;
        vm.save = save;
        vm.cancel = cancel;
        vm.onTimeframeChange = onTimeframeChange;

        activate();

        vm.timeOptions = {
            interval: 15,
            format: "h:mm tt",
            parseFormats: ["HH:mm"]
        };

        function parseISOLocal(s) {
            var b = s.split(/\D/);
            return new Date(b[0], b[1]-1, b[2]);
        }

        function reservationDateChanged() {
            vm.selectedDate = kendo.parseDate(vm.reservation.date);
            var availableTimeframes = [];

            angular.forEach(vm.reservationTimeframes, function (item) {
                var availableDays = item.daysOfWeekVisible.split(',');
                var selectedDayOfWeek = vm.selectedDate.getDay();

                if (availableDays.indexOf(selectedDayOfWeek.toString()) > -1) {
                    availableTimeframes.push(item);
                }
            });

            vm.reservationTimeframesAvailable = availableTimeframes;
            onTimeframeChange();
        }

        function onTimeframeChange() {
            if (vm.reservation.reservationTimeframe != null && vm.selectedDate != null) {
                var startDate = vm.reservation.reservationTimeframe.startDate.replace(/[TZ]/g, ' ');
                var endDate = vm.reservation.reservationTimeframe.endDate.replace(/[TZ]/g, ' ');

                var startArray = startDate.split(" ");
                var endArray = endDate.split(" ");

                var startTime = startArray[1].split(":");
                var endTime = endArray[1].split(":");

                startDate = new Date(vm.selectedDate.setHours(startTime[0], startTime[1]));
                endDate = new Date(vm.selectedDate.setHours(endTime[0], endTime[1]));

                vm.minDate = new Date(startDate);
                vm.maxDate = new Date(endDate);
            } else {
                vm.maxDate = new Date(8640000000000000);
                vm.minDate = new Date();
                vm.minDate = new Date(vm.minDate.setHours(0, 0, 0));
            }
        }

        function activate() {
            getReservationLocations();
            getReservationTimeframes();

            if (vm.isNew) {
                vm.reservation = {};
                vm.reservation.residentFirstName = $cookies.get('residentFirstName');
                vm.reservation.residentLastName = $cookies.get('residentLastName');
            } else {
                var dateTimeSplit = vm.reservation.reservationDate.split("T");
                var date = dateTimeSplit[0];
                var time = dateTimeSplit[1];

                vm.reservation.date = date;
                vm.reservation.time = time;
            }
        }

        function getReservationLocations() {
            ReservationLocationService.getAll()
                .then(getAllSuccess, getAllFailure);

            function getAllSuccess(response) {
                vm.reservationLocations = response.data;
            }

            function getAllFailure() { }
        }

        function getReservationTimeframes() {
            ReservationTimeframeService.getAll()
                .then(getAllSuccess, getAllFailure);

            function getAllSuccess(response) {
                vm.reservationTimeframes = response.data;
                vm.reservationTimeframesAvailable = vm.reservationTimeframes;

                if (!vm.isNew) {
                    reservationDateChanged();
                    onTimeframeChange();
                } else {
                    vm.maxDate = new Date(8640000000000000);
                    vm.minDate = new Date();
                    vm.minDate = new Date(vm.minDate.setHours(0, 0, 0));
                }
            }

            function getAllFailure() { }
        }

        function save() {
            vm.reservation.reservationDate = vm.reservation.date + " " + vm.reservation.time;

            if (vm.reservation.reservationId) {
                ReservationResidentService.update(vm.reservation)
                    .then(updateSuccess)
                    .catch(failure);
            } else {
                ReservationResidentService.create(vm.reservation)
                    .then(saveSuccess)
                    .catch(failure);
            }

            function saveSuccess() {
                NotificationService.success('save');

                $cookies.put('residentFirstName', vm.reservation.residentFirstName);
                $cookies.put('residentLastName', vm.reservation.residentLastName);

                $uibModalInstance.close();
            }
            function updateSuccess() {
                NotificationService.success('update');

                $cookies.put('residentFirstName', vm.reservation.residentFirstName);
                $cookies.put('residentLastName', vm.reservation.residentLastName);

                $uibModalInstance.close();
            }
            function failure() {
                console.log('failed to insert reservation');
            }
        }

        function cancel() {
            $uibModalInstance.close('cancel');
        }
    }
}());