(function () {
	'use strict';

	angular
        .module('e74intranet.catalogs')
        .controller('EmailConfigurationController', EmailConfigurationController);

	EmailConfigurationController.$inject = ['EmailConfigurationService', 'NotificationService', 'ResourceService'];

	function EmailConfigurationController(EmailConfigurationService, NotificationService, ResourceService) {
		var vm = this;
		vm.emailConfiguration = {};
		vm.save = save;

		activate();

		function activate() {
			EmailConfigurationService.getEmailConfiguration()
				.then(getEmailConfigurationSuccess, getEmailConfigurationFailure);

			function getEmailConfigurationSuccess(response) {
				vm.emailConfiguration = response.data;
			}

			function getEmailConfigurationFailure(){}
		}

		function save() {
			if (vm.emailConfigurationForm.$valid) {
				if (vm.emailConfiguration.emailConfigurationId) {
					EmailConfigurationService.update(vm.emailConfiguration)
						.then(updateSuccess)
						.catch(failure);						
				} else {
					EmailConfigurationService.create(vm.emailConfiguration)
						.then(saveSuccess)
						.catch(failure);
				}
			}

			function saveSuccess() {
				NotificationService.success('save');				
				activate();
			}

			function updateSuccess() {
				NotificationService.success('update');
				vm.emailConfigurationForm.$setPristine();
				vm.emailConfigurationForm.$setUntouched();
			}

			function failure() { }			
		}		
	}
}());