(function () {
	'use strict';

	angular
        .module('e74intranet.common')
        .directive('hasPermission', HasPermission);

	HasPermission.$inject = ['AccessService'];

	function HasPermission(AccessService) {
		return {
			restrict: 'A',
			link: link
		};

		function link(scope, element, attrs) {			
			element.addClass('hide');

			function toggleVisibility() {												
				if (AccessService.hasPermissions(attrs.hasPermission)) element.removeClass('hide');				
				else element.remove();											
			}
			scope.$on('permissionsValidated', toggleVisibility);
		}
	}
}());