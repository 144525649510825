(function () {
	'use strict';

	angular
        .module('e74intranet.event')
        .controller('EventDetailController', EventDetailController);

	EventDetailController.$inject = ['$stateParams', '$state', 'EventService', 'moment'];

	function EventDetailController($stateParams, $state, EventService, moment) {
		var vm = this;
		var eventId = $stateParams.eventId;
		activate();

		function activate() {
			getEvents();												
		}

		function getEvents() {
			if (!eventId) $state.go('error404');

			EventService.getById(eventId)
				.then(getByIdsSuccess, getByIdFailure);

			function getByIdsSuccess(response) {
				vm.event = response.data;
				
				if (!vm.event) $state.go('error404');

				var startDate = moment(vm.event.startDate).format("YYYY-M-D");
				var endDate = moment(vm.event.endDate).format("YYYY-M-D");

				vm.isMultiDay = moment(startDate).isBefore(endDate);				
			}

			function getByIdFailure() { }
		}
	}
}());