(function () {
	'use strict';

	angular
       .module('e74intranet.catalogs')
       .controller('ArticleCategoryAddEditController', ArticleCategoryAddEditController);

	ArticleCategoryAddEditController.$inject = ['$scope', '$uibModalInstance', 'modelResolved', 'ArticleCategoryService', 'NotificationService', 'ResourceService'];

	function ArticleCategoryAddEditController($scope, $uibModalInstance, modelResolved, ArticleCategoryService, NotificationService, ResourceService) {
		var vm = this;		
		vm.articleCategory = angular.copy(modelResolved);
		vm.isNew = !vm.articleCategory;
		vm.title = (!vm.isNew ? 'Edit' : 'New') + ' Article Category';
		vm.save = save;
		vm.cancel = cancel;					
		vm.isNewImage = false;		
		var saveUrl = "api/resources/uploadImage";

		activate();

		function activate() {
			var resourceId;

			if (vm.articleCategory && vm.articleCategory.resource) {
				vm.selectedFile = vm.articleCategory.resource.fileName;
				resourceId = vm.articleCategory.resource.resourceId;
			}

			configureKendoUploader(resourceId);
		}		

		function configureKendoUploader(resourceId) {
			if (resourceId) saveUrl = saveUrl + "?resourceId=" + resourceId;

			vm.uploadOptions = {
				multiple: false,
				showFileList: false,
				async: {
					autoUpload: true,
					saveUrl: saveUrl,
					removeUrl: ""
				},
				localization: {
					headerStatusUploaded: "The file was saved successfully",
					select: "Select File"
				},
				upload: onUpload,
				error: onError,
				success: onSuccess,
				select: onSelect,
				clear: onClear
			};
		}

		function onUpload() {			
			vm.isUploading = true;
			$scope.$apply();
		}

		function onError() {			
			vm.isUploading = false;
			NotificationService.error('An error has occurred while uploading the file. Please contact your system administrator.');
			$scope.$apply();
		}

		function onSuccess(e) {			
			if (!vm.articleCategory) {
				vm.articleCategory = { resource: { resourceId: e.response, fileName: vm.selectedFile } };
			} else {
				vm.articleCategory.resource = { resourceId: e.response, fileName: vm.selectedFile };
			}

			vm.isUploading = false;
			vm.isNewImage = true;			
			vm.uploader.options.async.saveUrl = "api/resources/uploadImage?resourceId=" + e.response;
			$scope.$apply();
		}

		function onSelect(e) {
			var file = e.files[0];
			var extensions = ['.jpg', '.gif', '.png', '.jpeg'];

			if (!ResourceService.isExtensionValid(file.name, extensions)) {
				NotificationService.warning('The selected file is not valid. Supported types are .jpg, .gif, .png, .jpeg');
				e.preventDefault();
				return;
			}			

			$scope.$apply(function () {				
				vm.resource = file;
				vm.selectedFile = vm.resource.name;
			});
		}

		function onClear() {			
			vm.isNewImage = false;			

			$scope.$apply(function () {
				vm.resource = vm.articleCategory ? vm.articleCategory.resource : null;
				vm.selectedFile = vm.resource ? vm.resource.fileName : "";
			});
		}	

		function save() {			
			if (vm.articleCategoryForm.$valid) {
				if (!vm.articleCategory.resource) {
					NotificationService.warning('It is mandatory to upload a category image.');
					return;
				}
				
				saveArticleCategory();
			}
		}		

		function saveArticleCategory() {			
			if (vm.articleCategory.articleCategoryId) {
				ArticleCategoryService.update(vm.articleCategory)
					.then(updateSuccess)
					.catch(failure);
			} else {
				ArticleCategoryService.create(vm.articleCategory)
					.then(saveSuccess)
					.catch(failure);
			}

			function saveSuccess() {				
				NotificationService.success('save');
				$uibModalInstance.close();
			}

			function updateSuccess() {				
				NotificationService.success('update');
				$uibModalInstance.close();
			}

			function failure() {}
		}		

		function cancel() {
			$uibModalInstance.close('cancel');
		}
	}
}());