(function () {
	'use strict';

	angular
        .module('e74intranet.employee')
        .controller('EmployeeNominationController', EmployeeNominationController);

	EmployeeNominationController.$inject = ['EmployeeNominationService', 'GeneralConfigurationService'];

	function EmployeeNominationController(EmployeeNominationService, GeneralConfigurationService) {
		var vm = this;		
		vm.employeeNomination = {};
		vm.save = save;

		activate();

		function activate() {
			getMessages();
		}

		function getMessages() {
			GeneralConfigurationService.getMessages()
				.then(getMessagesSuccess, getMessagesFailure);

			function getMessagesSuccess(response) {
				vm.messages = response.data;
			}

			function getMessagesFailure(){ }
		}

		function save() {
			if (vm.employeeNominationForm.$valid) {				
				EmployeeNominationService.create(vm.employeeNomination)
					.then(saveSuccess)
					.catch(failure);				
			}

			function saveSuccess() {
				vm.showMessage = true;
				reset();
			}

			function failure() { }
		}

		function reset() {
			vm.employeeNomination = {};			
			vm.employeeNominationForm.$setPristine();
			vm.employeeNominationForm.$setUntouched();
		}
	}
}());