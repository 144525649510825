(function () {
	'use strict';

	angular
        .module('e74intranet.employee')
        .config(Config);

	Config.$inject = ['$stateProvider'];

	function Config($stateProvider) {
		var announcementState = {
			name: 'main.announcement',
			url: '/announcement',
			controller: 'AnnouncementController',
			controllerAs: 'vm',
			templateUrl: 'wwwroot/app/employee/announcement/announcement.html',
			ncyBreadcrumb: {
				label: '{{ getMenuItemLabel("main.announcement") }}',
				parent: 'main.dashboard'
			}
        };

        var announcementCategoryState = {
            name: 'main.announcementCategory',
            url: '/announcementCategory',
            controller: 'AnnouncementCategoryController',
            controllerAs: 'vm',
            templateUrl: 'wwwroot/app/employee/announcement-categories/announcement-category.html',
            params: { mid: null },
            ncyBreadcrumb: {
                label: '{{ getMenuItemLabel("main.announcementCategory") }}',
                parent: 'main.dashboard'
            }
        };

		var articleState = {
			name: 'main.article',
			url: '/article',
			controller: 'ArticleController',
			controllerAs: 'vm',
			templateUrl: 'wwwroot/app/employee/article/article/article.html',
			ncyBreadcrumb: {
				label: '{{ getMenuItemLabel("main.article") }}',
				parent: 'main.dashboard'
			}
		};

		var articleDetailState = {
			name: 'main.articleDetail',
			url: '/articleDetail/:articleId',
			controller: 'ArticleDetailController',
			controllerAs: 'vm',
			templateUrl: 'wwwroot/app/employee/article/article-detail/article-detail.html',
			params: { mid: null },
			ncyBreadcrumb: {
				label: 'Good News Detail',
				parent: 'main.article'
			}
		};

		var backTalkState = {
			name: 'main.backTalk',
			url: '/backTalk',
			controller: 'TestimonyController',
			controllerAs: 'vm',
			templateUrl: 'wwwroot/app/employee/back-talk/testimony.html',
			ncyBreadcrumb: {
				label: '{{ getMenuItemLabel("main.backTalk") }}',
				parent: 'main.dashboard'
			}
		};

		var catalogResourceState = {
		    name: 'main.resource',
		    url: '/resource',
		    controller: 'CatalogResourceController',
		    controllerAs: 'vm',
		    templateUrl: 'wwwroot/app/employee/catalog-resources/catalog-resource.html',
		    ncyBreadcrumb: {
		        label: '{{ getMenuItemLabel("main.resource") }}',
		        parent: 'main.dashboard'
		    }
		};

		var contactDirectoryState = {
			name: 'main.contactDirectory',
			url: '/contactDirectory',
			controller: 'ContactDirectoryController',
			controllerAs: 'vm',
			templateUrl: 'wwwroot/app/employee/contacts/contact-directory/contact-directory.html',
			ncyBreadcrumb: {
				label: '{{ getMenuItemLabel("main.contactDirectory") }}',
				parent: 'main.dashboard'
			}
		};

		var employeeNominationState = {
			name: 'main.employeeNomination',
			url: '/employeeNomination',
			controller: 'EmployeeNominationController',
			controllerAs: 'vm',
			templateUrl: 'wwwroot/app/employee/employee-nomination/employee-nomination.html',
			ncyBreadcrumb: {
				label: '{{ getMenuItemLabel("main.employeeNomination") }}',
				parent: 'main.dashboard'
			}
		};

		var findPhysicianState = {
			name: 'main.findPhysician',
			url: '/findPhysician',
			controller: 'FindPhysicianController',
			controllerAs: 'vm',
			templateUrl: 'wwwroot/app/employee/physicians/find-physician/find-physician.html',
			ncyBreadcrumb: {
				label: '{{ getMenuItemLabel("main.findPhysician") }}',
				parent: 'main.dashboard'
			}
		};		

		var policyOverviewState = {
			name: 'main.policyOverview',
			url: '/policyOverview',
			controller: 'PolicyOverviewController',
			controllerAs: 'vm',
			templateUrl: 'wwwroot/app/employee/policies/policy-overview/policy-overview.html',
			ncyBreadcrumb: {
				label: '{{ getMenuItemLabel("main.policyOverview") }}',
				parent: 'main.dashboard'
			}
		};

		var submitSuggestionState = {
			name: 'main.submitSuggestion',
			url: '/submitSuggestion',
			controller: 'SubmitSuggestionController',
			controllerAs: 'vm',
			templateUrl: 'wwwroot/app/employee/suggestion/submit-suggestion/submit-suggestion.html',
			ncyBreadcrumb: {
				label: '{{ getMenuItemLabel("main.submitSuggestion") }}',
				parent: 'main.dashboard'
			}
		};

		var suggestionBoxState = {
			name: 'main.suggestionBox',
			url: '/suggestionBox',
			controller: 'SuggestionBoxController',
			controllerAs: 'vm',
			templateUrl: 'wwwroot/app/employee/suggestion/suggestion-box/suggestion-box.html',
			ncyBreadcrumb: {
				label: '{{ getMenuItemLabel("main.suggestionBox") }}',
				parent: 'main.dashboard'
			}
		};		
		
        $stateProvider.state(announcementState);
        $stateProvider.state(announcementCategoryState);
		$stateProvider.state(articleState);
        $stateProvider.state(articleDetailState);
		$stateProvider.state(backTalkState);		
		$stateProvider.state(catalogResourceState);		
		$stateProvider.state(contactDirectoryState);
		$stateProvider.state(employeeNominationState);
		$stateProvider.state(findPhysicianState);		
		$stateProvider.state(policyOverviewState);					
		$stateProvider.state(suggestionBoxState);
		$stateProvider.state(submitSuggestionState);
	}
}());