(function () {
	'use strict';

	angular
        .module('e74intranet.event')
        .controller('CallSheetController', CallSheetController);

	CallSheetController.$inject = ['$rootScope', 'EventService', 'SpecialityService', 'NotificationService', 'ModalService', 'AccessService', 'moment'];

	function CallSheetController($rootScope, EventService, SpecialityService, NotificationService, ModalService, AccessService, moment) {
		var vm = this;
		vm.generalSelectedItems = [];		
		vm.selectedGeneralIds = "";				
		vm.getGeneralEvents = getGeneralEvents;		
		vm.printGeneral = printGeneral;		
		vm.clearGeneralResults = clearGeneralResults;		

		vm.create = false;
		vm.update = false;
		vm.destroy = false;

		activate();
		
		function activate() {
			if (AccessService.hasPermissions($rootScope.permission.CREATE)) vm.create = true;
			if (AccessService.hasPermissions($rootScope.permission.UPDATE)) vm.update = true;
			if (AccessService.hasPermissions($rootScope.permission.DELETE)) vm.destroy = true;

			SpecialityService.getAll()
				.then(getAllSuccess, getAllFailure);

			function getAllSuccess(response) {
				vm.specialities = response.data;
				vm.specialityResources = [];
				mapToSpecialityResource();
				configureGeneralScheduler();				
			}

			function getAllFailure(){}

			function mapToSpecialityResource() {
				angular.forEach(vm.specialities, function (value) {
					var resource = {
						text: value.name,
						value: value.specialityId,
						name: value.name,
						specialityId: value.specialityId,
						color: value.color
					};

					vm.specialityResources.push(resource);
				});
			}
		}
		
		vm.multiDropdownSettings = {
			idProp: 'specialityId',
			externalIdProp: 'specialityId',
			displayProp: 'name',
			buttonClasses: 'btn btn-default btn-multi-dropdown'
		};

		vm.translation = {
			buttonDefaultText: 'Filter by Speciality',
			dynamicButtonTextSuffix: 'selected'
		};

		vm.multiDropdownGeneralEvents = {
			onSelectionChanged: onGeneralSelectionChanged,
		};		

		function onGeneralSelectionChanged() {
			vm.selectedGeneralIds = "";
			angular.forEach(vm.generalSelectedItems, function (item) {
				vm.selectedGeneralIds += item.specialityId + ',';
			});

			if (vm.selectedGeneralIds.length > 0) vm.selectedGeneralIds = vm.selectedGeneralIds.substring(0, vm.selectedGeneralIds.length - 1);

			vm.generalDataSource.read();
		}				

		vm.generalOptions = {
			placeholder: "Search by Name",
			dataTextField: "title",
			filter: "contains",
			clearButton: false,
			dataSource: new kendo.data.DataSource({				
				serverFiltering: true,
				transport: {										
					read: {
						url: "api/events/generalEvents",
						dataType: "json"
					},
					parameterMap: function (data, type) {
						var queryString = {
					};
					if (vm.generalSearchText) queryString.searchText = vm.generalSearchText;

					queryString.specialityIdList = vm.selectedGeneralIds ? vm.selectedGeneralIds : "0";
					return queryString;
					}
				}
			}),
			select: onGeneralSelect
		};

		function onGeneralSelect(e) {
			vm.generalSearchText = e.item.text();
			getGeneralEvents();
		}	

		function getGeneralEvents() {			
			vm.generalDataSource.read();
		}		
				
		function configureGeneralScheduler() {
			vm.generalDataSource = new kendo.data.SchedulerDataSource({
				transport: {
					create: createGeneral,
					read: readGeneral,
					update: updateCallSheetEvent,
					destroy: destroyCallSheetEvent
				},
				schema: {
					timezone: "CST6CDT",
					model: {
						id: "eventId",
						fields: {
							eventId: { from: "eventId", type: "number" },
							title: { from: "title", defaultValue: "No title", validation: { required: true } },
							start: { type: "date", from: "startDate" },
							end: { type: "date", from: "endDate" },
							specialityId: { from: "specialityId", required: true },
							isAllDay: { type: "boolean", from: "isAllDay" },							
							recurrenceId: { from: "recurrenceId" },
							recurrenceRule: { from: "recurrenceRule" },
							recurrenceException: { from: "recurrenceException" }
						}
					}
				}
			});

			var startDate = moment().startOf('day').toDate();

			vm.generalSchedulerOptions = {
				pdf: {
					fileName: "e74intranet_general_calendar.pdf",
					proxyURL: "api/resources/exportPDF"
				},
				date: startDate,
				startTime: startDate,
				views: [
					"day",
					"workWeek",
					"week",
					"month",
					{ type: "month", selected: true },
				],
				editable: {
					create: vm.create,
					update: vm.update,
					destroy: vm.destroy,
					move: vm.update,
					resize: vm.update,
					template: kendo.template($("#customEditorTemplate").html()),
				},
				timezone: "CST6CDT",
				dataSource: vm.generalDataSource,
				resources: [
					{
						field: "specialityId",
						dataSource: vm.specialityResources,
						title: "specialityId"
					}
				],
				edit: function (e) {													
					$('.k-scheduler-delete').attr('tabindex', 11);
					$('.k-scheduler-cancel').attr('tabindex', 13);
					$('.k-scheduler-update').attr('tabindex', 14);				
				},
				"messages": {
					"allDay": "All Day",
					"recurrenceMessages": {
						"deleteWindowOccurrence": "Delete occurrence"
					}
				}
			};
		}	

		function clearGeneralResults() {
			vm.generalSearchText = '';
			getGeneralEvents();
		}		

		function printGeneral() {
			vm.generalScheduler.saveAsPDF();
		}

		function createGeneral(options) {
			EventService.createGeneral(options.data)
				.then(createGeneralSuccess, createGeneralFailure);

			function createGeneralSuccess(response) {
				vm.generalDataSource.read();
				NotificationService.success('save');
			}
			function createGeneralFailure(error) {
				options.error(error);
			}
		}	

		function readGeneral(options) {
			EventService.getGeneralEvents(vm.selectedGeneralIds, vm.generalSearchText)
				.then(getGeneralEventsSuccess, getGeneralEventsFailure);

			function getGeneralEventsSuccess(response) {
				options.success(response.data);
			}
			function getGeneralEventsFailure(error) {
				options.error(error);
			}
		}	

		function update(options) {
			EventService.update(options.data)
				.then(updateSuccess, updateFailure);

			function updateSuccess(response) {
				NotificationService.success('update');
				vm.generalDataSource.read();				
			}
			function updateFailure(error) {
				options.error(error);
			}
		}

		function updateCallSheetEvent(options) {
			EventService.updateCallSheetEvent(options.data)
				.then(updateCallSheetEventSuccess, updateCallSheetEventFailure);

			function updateCallSheetEventSuccess(response) {
				NotificationService.success('update');
				vm.generalDataSource.read();			
			}
			function updateCallSheetEventFailure(error) {
				options.error(error);
			}
		}			

		function destroyCallSheetEvent(options) {
			EventService.removeCallSheetEvent(options.data.eventId)
				.then(destroyCallSheetEventSuccess, destroyCallSheetEventFailure);

			function destroyCallSheetEventSuccess(response) {
				NotificationService.success('delete');
				vm.generalDataSource.read();				
			}
			function destroyCallSheetEventFailure(error) {				
				options.error(error);
			}
		}
	}
}());