(function () {
	'use strict';

	angular
		.module('e74intranet.dashboard')
		.component('events', {
			templateUrl: 'wwwroot/app/dashboard/events/events.html',
			controller: EventController,
			controllerAs: 'vm'
		});

	EventController.$inject = ['$state', 'EventService', 'EventCategoryService'];

	function EventController($state, EventService, EventCategoryService) {
		var vm = this;		

		activate();

		function activate() {
			EventCategoryService.getAll()
				.then(getAllEventCategoriesSuccess, getAllEventCategoriesFailure);

			function getAllEventCategoriesSuccess(response) {
				vm.eventCategories = response.data;
				vm.eventCategoryResources = [];
				mapToEventCategoryResource();
				configureScheduler();
			}

			function getAllEventCategoriesFailure() { }

			function mapToEventCategoryResource() {
				angular.forEach(vm.eventCategories, function (value) {
					var resource = {
						text: value.name,
						value: value.eventCategoryId,
						name: value.name,
						eventCategoryId: value.eventCategoryId,
						color: value.color
					};

					vm.eventCategoryResources.push(resource);
				});
			}
		}

		function checkEventCategoryIds(eventCategories) {
		    var matchingEventCategoryId = "0";
		    var eventsCategories = eventCategories;

		    for (var j = 0; j < eventsCategories.length; j++) {
		        if (matchingEventCategoryId == "0") {
		            matchingEventCategoryId = eventsCategories[j].eventCategoryId;
		        }
		    }
		    return matchingEventCategoryId;
		}
		
		function configureScheduler() {
			vm.dataSource = new kendo.data.SchedulerDataSource({
				transport: {
					read: read
				},
				schema: {
					timezone: "CST6CDT",
					model: {
						id: "eventId",
						fields: {
							eventId: { from: "eventId", type: "number" },
							title: { from: "title", defaultValue: "No title", validation: { required: true } },
							start: { type: "date", from: "startDate" },
							end: { type: "date", from: "endDate" },
							description: { from: "description", validation: { required: true } },
							email: { from: "email", validation: { required: true } },
							fullName: { from: "fullName", validation: { required: true } },
							eventCategoryId: { type: "number" },
							isAllDay: { type: "boolean", from: "isAllDay" },							
							recurrenceId: { from: "recurrenceId" },
							recurrenceRule: { from: "recurrenceRule" },
							recurrenceException: { from: "recurrenceException" }
						}
					},
					parse: function (data) {
					    $.each(data, function (i, val) {
					        val.eventCategoryId = checkEventCategoryIds(val.eventCategories);
					    });
					    return data;
					}
				}
			});

            var startDate = moment().startOf('day').toDate();
            var sTime = moment().startOf('day').set('hour', 6).toDate();
            var eTime = moment().startOf('day').set('hour', 22).toDate();

            vm.schedulerOptions = {
                toolbar: ["pdf"],
                pdf: {
                    fileName: "scheduler.pdf",
                    proxyURL: "https://demos.telerik.com/kendo-ui/service/export"
                },
				date: startDate,
                startTime: sTime,
                endTime: eTime,
                navigate: scheduler_navigate,
				views: [
					"day",
					"workWeek",
					"week",
					"month",
					{ type: "month", selected: true },
				],
				editable: {
					create: false,
					update: true,
					destroy: false,
					move: false,
					resize: false,
					editRecurringMode: "occurrence"
				},
				timezone: "CST6CDT",
				dataSource: vm.dataSource,
				resources: [
					{
					    field: "eventCategoryId",
						dataSource: vm.eventCategoryResources,
						title: "eventCategoryId"
					}
				],
				edit: function (e) {
					e.preventDefault();
                    if (e.event.eventId > 0) $state.go('main.eventDetail', { eventId: e.event.eventId });
				},
				"messages": {
					"allDay": "All Day"
				}
			};
        }

        function scheduler_navigate(e) {
            console.log(kendo.format("navigate:: action:{0}; view:{1}; date:{2:d};", e.action, e.view, e.date));
        }

		function read(options) {
			EventService.getFullCalendarEvents(vm.selectedIds)
				.then(getFullCalendarEventsSuccess, getFullCalendarEventsFailure);

			function getFullCalendarEventsSuccess(response) {						
				options.success(response.data);
			}
			function getFullCalendarEventsFailure(error) {
				options.error(error);
			}
		}
	}
}());