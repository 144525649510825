(function () {
	'use strict';

	angular
       .module('e74intranet.employee')
       .controller('SuggestionStatusAddEditController', SuggestionStatusAddEditController);

	SuggestionStatusAddEditController.$inject = ['$uibModalInstance', 'modelResolved', 'SuggestionService', 'NotificationService'];

	function SuggestionStatusAddEditController($uibModalInstance, modelResolved, SuggestionService, NotificationService) {
		var vm = this;
		vm.suggestionStatus = angular.copy(modelResolved);		
		vm.title = 'Edit Suggestion Status';
		vm.save = save;
		vm.cancel = cancel;				
	
		function save() {
			if (vm.suggestionStatusForm.$valid) {
				SuggestionService.updateSuggestionStatus(vm.suggestionStatus)
                    .then(updateSuccess)
					.catch(failure);
			}			
			
			function updateSuccess() {
				NotificationService.success('update');
				$uibModalInstance.close();
			}
			function failure() { }
		}

		function cancel() {
			$uibModalInstance.close('cancel');
		}
	}
}());