(function () {
    'use script';
    
    angular
        .module('e74intranet.cafeteria')
        .controller('CourseController', CourseController);

    CourseController.$inject = [
        '$rootScope',
        'CourseService',
        'NotificationService',
        'ModalService',
        'AccessService'
    ];

    function CourseController(
        $rootScope,
        CourseService,
        NotificationService,
        ModalService,
        AccessService) {
        var vm = this;
        vm.getCourses = getCourses;
        vm.openModal = openModal;
        vm.deleteRow = deleteRow;

        activate();

        function activate() {
            setPermissions();
            getCourses();
        }

        function setPermissions() {
            if (AccessService.hasPermissions($rootScope.permission.CREATE)) vm.create = true;
            if (AccessService.hasPermissions($rootScope.permission.UPDATE)) vm.update = true;
            if (AccessService.hasPermissions($rootScope.permission.DELETE)) vm.delete = true;
        }

        function getCourses() {
            CourseService.getAll()
                .then(getAllSuccess, getAllFailure);

            function getAllSuccess(response) {
                vm.courses = response.data;
            }

            function getAllFailure() { }
        }

        function openModal(action, course) {
            var modalInstance = ModalService.createModal({
                templateUrl: 'wwwroot/app/cafeteria/course/course-add-edit.html',
                controller: 'CourseAddEditController',
                size: 'lg',
                resolve: action === 'edit' ? course : null
            });

            modalInstance.then(modalInstanceResponse, modalInstanceError);

            function modalInstanceResponse(response) {
                if (response !== "cancel") {
                    activate();
                }
            }

            function modalInstanceError() { }
        }

        function deleteRow(course) {
            var modalPromptInstance = ModalService.createModalPrompt({
                title: 'Delete Course',
                content: 'The selected course will be deleted. Do you want to continue?'
            });

            modalPromptInstance.then(modalPromptInstanceResponse, modalPromptInstanceError);

            function modalPromptInstanceResponse(response) {
                if (response === 'cancel') return;

                CourseService.remove(course.courseId)
                    .then(removeSuccess, removeFailure);

                function removeSuccess() {
                    NotificationService.success('delete');
                    getCourses();
                }

                function removeFailure(){}
            }

            function modalPromptInstanceError() { }
        }
    }
}());