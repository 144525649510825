(function () {
	'use strict';

	angular
		.module('e74intranet.event')
		.factory('EventService', EventService);

	EventService.$inject = ['$http'];

	function EventService($http) {
		var root = 'api/events';
		var eventService = {
			getById: getById,
			getFullCalendarEvents: getFullCalendarEvents,
			getLandingPageEvents: getLandingPageEvents,
			getGeneralEvents: getGeneralEvents,			
			create: create,
			createGeneral: createGeneral,			
			update: update,
			updateCallSheetEvent: updateCallSheetEvent,			
			remove: remove,
			removeCallSheetEvent: removeCallSheetEvent
		};		

		function getById(id) {
			return $http.get(root + '/' + id);
		}

		function getFullCalendarEvents(ids) {
			ids = (ids && ids.length > 0) ? ids : "0";
			return $http.get(root + '/' + ids + '/events');
		}

		function getGeneralEvents(ids, searchText) {
			ids = (ids && ids.length > 0) ? ids : "0";
			var requestUrl = root + '/' + ids + '/generalEvents';

			if (searchText) requestUrl += '?searchText=' + searchText;

			return $http.get(requestUrl);
		}	

		function getLandingPageEvents() {
			return $http.get(root + '/landingPage/events');
		}

		function create(model) {
			return $http.post(root, model);
		}

		function createGeneral(model) {
			return $http.post(root + '/general', model);
		}		

		function update(model) {
			return $http.put(root, model);
		}

		function updateCallSheetEvent(model) {
			return $http.put(root + '/callSheet', model);
		}

		function remove(id) {
			return $http.delete(root + '/' + id);
		}

		function removeCallSheetEvent(id) {
			return $http.delete(root + '/callSheet/' + id);
		}

		return eventService;
	}
}());


