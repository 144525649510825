(function () {
    'use strict';

    angular
        .module('e74intranet.catalogs')
        .controller('EventCategoryAddEditController', EventCategoryAddEditController);

    EventCategoryAddEditController.$inject = ['$scope', '$uibModalInstance', 'modelResolved', 'EventCategoryService', 'NotificationService', 'ModalService'];

    function EventCategoryAddEditController($scope, $uibModalInstance, modelResolved, EventCategoryService, NotificationService, ModalService) {
        var vm = this;
        vm.eventCategory = angular.copy(modelResolved);
        vm.isNew = !vm.eventCategory;
        vm.title = (!vm.isNew ? 'Edit' : 'New') + ' Event Category';
        vm.save = save;
        vm.cancel = cancel;

        activate();

        function activate() {
            getEventCategories();
        }

        function getEventCategories() {
            if (vm.isNew) {
                EventCategoryService.getAllForParentDropdown(-1)
                    .then(getEventCategoriesSuccess, getEventCategoriesFailure);
            } else {
                EventCategoryService.getAllForParentDropdown(vm.eventCategory.eventCategoryId)
                    .then(getEventCategoriesSuccess, getEventCategoriesFailure);
            }

            function getEventCategoriesSuccess(response) {
                vm.eventCategories = response.data;
            }

            function getEventCategoriesFailure() { }
        }

        function save() {
            if (vm.eventCategory.eventCategoryId) {
                EventCategoryService.update(vm.eventCategory)
                    .then(updateSuccess)
                    .catch(failure);
            } else {
                EventCategoryService.create(vm.eventCategory)
                    .then(saveSuccess)
                    .catch(failure);
            }

            function saveSuccess() {
                NotificationService.success('save');
                $uibModalInstance.close();
            }
            function updateSuccess() {
                NotificationService.success('update');
                $uibModalInstance.close();
            }
            function failure() {
                ResourceService.delete(response.resourceId);
            }
        }

        function cancel() {
            $uibModalInstance.close('cancel');
        }
    }
}());