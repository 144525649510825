(function () {
	'use strict';

	angular
       .module('e74intranet.catalogs')
       .controller('UserAddEditController', UserAddEditController);

	UserAddEditController.$inject = ['$uibModalInstance', 'modelResolved', 'UserService', 'ProfileService', 'DepartmentService', 'NotificationService'];

	function UserAddEditController($uibModalInstance, modelResolved, UserService, ProfileService, DepartmentService, NotificationService) {
		var vm = this;		
		vm.user = angular.copy(modelResolved);		
		vm.isNew = !vm.user;
		vm.title = (!vm.isNew ? 'Edit' : 'New') + ' User';
		vm.save = save;
		vm.cancel = cancel;

		activate();

		function activate() {			
			getProfiles();
			getDepartments();
						
			if (vm.isNew) {
				vm.user = {
					isActive: true,
					isFirstTimeLogin: true
				};
			}

			vm.user.confirmEmail = vm.user.email;
		}
	
		function getProfiles() {
			ProfileService.getAll()
				.then(getProfilesSuccess, getProfilesFailure);

			function getProfilesSuccess(response) {
				vm.profiles = response.data;
			}

			function getProfilesFailure(){}
		}

		function getDepartments() {
			DepartmentService.getAll()
				.then(getDepartmentsSuccess, getDepartmentsFailure);

			function getDepartmentsSuccess(response) {
				vm.departments = response.data;
			}

			function getDepartmentsFailure(){}
		}

		function save() {
			if (vm.userForm.$valid) {
				if (vm.user && vm.user.id) {
					UserService.update(vm.user)
                        .then(updateSuccess)
						.catch(failure);
				} else {
					UserService.create(vm.user)
                        .then(saveSuccess)
						.catch(failure);
				}
			}

			function saveSuccess() {
				NotificationService.success('save');
				$uibModalInstance.close();
			}
			function updateSuccess() {
				NotificationService.success('update');
				$uibModalInstance.close();
			}
			function failure(error) {}			
		}

		function cancel() {
			$uibModalInstance.close('cancel');
		}
	}
}());